import { Injectable } from '@angular/core';
import { HttpRequest,  HttpHandler,  HttpEvent,  HttpInterceptor, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptorInterceptor implements HttpInterceptor 
{

  constructor(private route:Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> 
  {
    const token=localStorage.getItem("token");
    let req=request;

    if(token)
    {

      req=request.clone({
        setHeaders:{
          authorization: `Bearer ${token}`
        }
      })
    }    
    
    return next.handle(req);
  }

}

export const jwtInterceptorProvider={
  provide: HTTP_INTERCEPTORS,
  useClass:JwtInterceptorInterceptor,
  multi:true
};
