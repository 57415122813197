import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClinicHistoryComponent } from '../../sharedch/clinicHistory/clinicHistory.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceTypeComponent } from './invoices/invoice-type.component';
//guard
//import { ClinicGuard } from '../../auth/clinic.guard';


//import { ClinicHistoryComponent } from './clinicHistory/clinicHistory.component';

const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Clinic Admins'
        },
        children: [
            {
                path: '',
                redirectTo: 'clinicHistory', pathMatch:'full'
            },
            {
                path: 'clinicHistory',
 //               canActivate:[ClinicGuard],
                component: ClinicHistoryComponent,
                data: {
                    title: 'Clinic History'
                }
            },
            {
              path: 'invoices',
//               canActivate:[ClinicGuard],
              component: InvoicesComponent,
              data: {
                  title: 'Invoices'
              }
            },
            {
              path: 'invoiceTypes',
//               canActivate:[ClinicGuard],
              component: InvoiceTypeComponent,
              data: {
                  title: 'tipo de Facturas'
              }
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ClinicAdminsRouterModule { }
