import { Component, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription, forkJoin } from "rxjs";
import { BOClinicHistory } from "../models/BOClinicHistory.model";
import { ClinicHistoryService } from "../services/clinicHistory.service";
import { CreateOrEditClinicHistoryComponent } from "./clinicHistory/createOrEditClinicHistory.component";
import { ServiceTypeService } from "../services/service-type.service";
import { BOItem } from "../models/BOItem.model";

@Component({
    selector: 'clinicHistoriesPatientModal',
    templateUrl: './clinicHistoriesPatient.component.html',
    styleUrls: ['./clinicHistoriesPatient.component.css'],
})

export class ClinicHistoriesPatientComponent implements OnDestroy {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('createOrEditClinicHistoryModal', {static:true}) createOrEditClinicHistoryModal: CreateOrEditClinicHistoryComponent;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    busy: Subscription | undefined;
    submitted = false;

    clinicHistoryList: BOClinicHistory[];
    serviceTypeList: BOItem[] = [];

    constructor(public toastr: ToastrService,
                private _clinicHistoryService: ClinicHistoryService,
                private _serviceTypeService:ServiceTypeService) { }

    openModal(patientId: number):void{
        this.modal.config = { backdrop: "static", keyboard: false };
        this.modal.show();

        // this.busy = this._clinicHistoryService.getClinicHistoriesByPatient(patientId).subscribe(data =>
        // {
        //     this.clinicHistoryList = data;
        // //
        // if(this.clinicHistoryList.length>0){

        //   for(let i=0; i<this.clinicHistoryList.length; i++)
        //   {
        //     let serviceType=this.clinicHistoryList[i].serviceType;
        //     this.clinicHistoryList[i].serviceTypeName= this.serviceTypeList.find(x=>x.code==serviceType)?.name;
        //   }
        // }

        // });

        this.busy=forkJoin(
          {
           resultClinicHistory:this._clinicHistoryService.getClinicHistoriesByPatient(patientId),
           resultServiceTypeService:this._serviceTypeService.getAll(),
          }).subscribe(({resultClinicHistory, resultServiceTypeService})=>{
           this.clinicHistoryList=resultClinicHistory,
           this.serviceTypeList=resultServiceTypeService

           if(this.clinicHistoryList.length>0){

             for(let i=0; i<this.clinicHistoryList.length; i++)
             {
               let serviceType=this.clinicHistoryList[i].serviceType;
               this.clinicHistoryList[i].serviceTypeName= this.serviceTypeList.find(x=>x.code==serviceType)?.name;
             }
           }


          })

    }

    close(): void {
        this.clinicHistoryList = [];
        this.modal.hide();
    }

    ngOnDestroy(): void {
        this.busy?.unsubscribe();
    }
}
