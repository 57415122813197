import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOItem } from "../models/BOItem.model";
import { BOSubServiceType } from "../models/BOSubServiceType.model";


@Injectable({
  providedIn: 'root'
})
export class SubServiceTypeService {

  myAppUrl = GlobalConstants.apiURL;
  myApiUrl = 'api/SubServiceType';

  constructor(private http:HttpClient){}

  getAll():Observable<BOSubServiceType[]>{
      return this.http.get<BOSubServiceType[]>(this.myAppUrl + this.myApiUrl);
  }

  getSubServicesByService(service:string ):Observable<BOSubServiceType[]>{
    return this.http.get<BOSubServiceType[]>(this.myAppUrl + this.myApiUrl + '/'+service);
  }


}
