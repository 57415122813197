
import { forkJoin, Subscription } from "rxjs";
import { BOPerson } from "../models/BOPerson.model";
import { BOItem } from "../models/BOItem.model";
import { BOPayType } from "../models/BOPayType.model";
import { BOInvoiceType } from "../models/BOInvoiceType.model";
import { BOSubServiceType } from "../models/BOSubServiceType.model";


export class Utilities {
  busy: Subscription;
 // profesionals: BOPerson[];
  static servicesTypeList: BOItem[];
  static servicesGroupList: BOItem[];
  static profesionals: BOPerson[];


  constructor(){ }


  static getProfesionalName(idProfessional:number, profesionals:BOPerson[]):string
  {
    let name:string="";

    profesionals.forEach(p=>{
      if(p.id==idProfessional)
      {
        name=p.firstName +' '+p.secondName +' '+ p.firtsSurName;
      }
    });
    return name;
  }

  static getPayTypeName(idPayType: number, payTypeList: BOPayType[])
  {
    let name:string="";

    payTypeList.forEach(p=>{
      if(p.id==idPayType)
      {
        name=p.name;
      }
    });
    return name;

  }

  static getInvoiceTypeName(idInvoiceType: number, invoiceTypeList: BOInvoiceType[])
  {
    let name:string="";

    invoiceTypeList.forEach(p=>{
      if(p.id==idInvoiceType)
      {
        name=p.name;
      }
    });
    return name;

  }

  static convertDateToString(date:Date):string{

    const dateTemp=date;
    const dateTempReal=new Date(dateTemp);
    const day=dateTempReal.getDate();
    const month=dateTempReal.getMonth() +1;
    const year= dateTempReal.getFullYear();
    const resultDate=day +'/'+month+ '/'+ year;

    return resultDate;
   }

   static convertStringToInt(target:string):number{

    const intTemp=parseInt(target);

    return intTemp;
   }

   static getServicesxGroupId(idGroup: string, serviceList: BOItem[]):BOItem[]
   {
     let svcList: BOItem[]=[];

     serviceList.forEach(s=>{
       if(s.serviceGroup==idGroup)
       {
         svcList.push(s);
       }
     });
     return svcList ;
   }

   static getSubServicesxServiceId(idService: number, subServiceTypeList: BOSubServiceType[]):BOSubServiceType[]
   {
    console.log('Entra a getSubServicesxServiceId');
    console.log('this.subServiceTypeList es:', subServiceTypeList);

     let svcList: BOSubServiceType[]=[];

     subServiceTypeList.forEach(sb=>{
       if(sb.serviceTypeId==idService)
       {
         svcList.push(sb);
       }
     });
     return svcList ;
   }
}
