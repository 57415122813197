import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SellComponent } from '../../../sharedch/sell/sell.component';
import { BOSell } from '../../../models/BOSell.model';
import { SellService } from '../../../services/sell.service';
import { forkJoin, Subscription } from 'rxjs';
import { BOFilter } from '../../../models/BOFilter';
import { PayTypeService } from '../../../services/payType.service';
import { BOPayType } from '../../../models/BOPayType.model';
import { BOInvoiceType } from '../../../models/BOInvoiceType.model';
import { InvoiceTypeService } from '../../../services/invoiceType.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit, OnDestroy
{
  @ViewChild('sellModal') public sellModal: SellComponent;

  busy: Subscription | undefined;
  sellFilter:string="";

  sellList:BOSell[]=[];

  invoiceTypeList:BOInvoiceType[]=[];

  constructor(private _sellService: SellService,private _invoiceTypeService: InvoiceTypeService) { }

  ngOnDestroy(): void {
  this.busy.unsubscribe();

  }

  ngOnInit(): void
  {
    const filterInput:BOFilter={
      filter:this.sellFilter
    }

    // this.busy=this._sellService.getSells(filterInput).subscribe
    //   ({next: data=>{this.sellList=data; console.log('this.sellList es:', this.sellList)},
    //     error:()=>{console.log('error')}
    // })

    this.busy = forkJoin({
      resultSells:this._sellService.getSells(filterInput),
      resultInvoiceType:this._invoiceTypeService.getAll()
    })
    .subscribe(({resultSells, resultInvoiceType})=>{
      this.sellList=resultSells;
      this.sellList.sort((a,b)=>{return new Date(b.dateSell).getTime() -new Date(a.dateSell).getTime()});
      this.invoiceTypeList=resultInvoiceType;
      console.log('En invoiceComponent.ts this.sellList es:', this.sellList, ' y invoiceTypeLise es:', this.invoiceTypeList);
    })

  }


  openInvoicesList()
  {
    let bsell:BOSell= new BOSell();

  this.sellModal.openSellModal(bsell, 2);
  }

  getAllSells():void
  {
      this.sellList=[];
      const filterInput: BOFilter = {
          filter: this.sellFilter.toUpperCase().trim()
      }
      console.log("filterInput.filter sellFilter es:",filterInput.filter);
      this.busy = this._sellService.getSells(filterInput)
      .subscribe(data=>{this.sellList = data;
           //Ric
           console.log('Entra al subscribe y data es:',this.sellList);
          if(!data.length) this.sellFilter= "";

          if(this.sellList.length>0)
          {

            for(let i=0; i<this.sellList.length; i++)
            {
              let invoiceTypeIdTemp=this.sellList[i].invoiceTypeId;
              this.sellList[i].invoiceTypeName= this.invoiceTypeList.find(x=>x.id==invoiceTypeIdTemp)?.name;
            }
          }

      });

      console.log('En getAllSells sellList es:', this.sellList);

  }

  openData():void{
    console.log('open Data');
  }

  testMe():void{
    console.log('Entra a testMe');
  }
}
