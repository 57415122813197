import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOItem } from "../models/BOItem.model";
import { BOPayType } from "../models/BOPayType.model";

@Injectable({
  providedIn: 'root'
})
export class PayTypeService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/PayType';

    constructor(private http:HttpClient){}

    getAll():Observable<BOPayType[]>{
        return this.http.get<BOPayType[]>(this.myAppUrl + this.myApiUrl);
    }
}
