import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOCity } from "../models/BOCity.model";

@Injectable()
export class CityService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/City';

    constructor(private http:HttpClient){}

    getCitiesbyState(stateCode:string):Observable<BOCity[]>{
        return this.http.get<BOCity[]>(this.myAppUrl + this.myApiUrl + '/' + stateCode);
    }
}