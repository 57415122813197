import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription, forkJoin } from "rxjs";
import { BOItem } from "../../models/BOItem.model";
import { SellService } from '../../services/sell.service';
import { BOSell } from "../../models/BOSell.model";
import { BOSellDetail } from "../../models/BOSellDtail.model";
import { BOSellDataSended } from '../../models/BOSellDataSended.model';
import { BOPerson } from "../../models/BOPerson.model";
import { PersonService } from "../../services/person.service";
import { ServiceTypeService } from "../../services/service-type.service";
import { PayTypeService } from '../../services/payType.service';
import { BOPayType } from "../../models/BOPayType.model";
import { BOPayTypeSellDetail } from "../../models/BOPayTypeSellDetail.model";
import { BOInvoiceType } from "../../models/BOInvoiceType.model";
import { InvoiceTypeService } from '../../services/invoiceType.service';
import { DialogConfirmService } from "../../services/dialog-confirm.service";
import { DialogConfirmComponent } from "../dialog-confirm/dialog-confirm.component";
import { MatTable, MatTableModule } from "@angular/material/table";
import * as fileSaver from 'file-saver';
import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy } from "@angular/core";  // import
import { CsvConverterService } from '../../services/csv-converter.service';
import { Utilities } from "../../common/utilities";
import { BOClinicHistoryObservations } from "../../models/BOClinicHistoryObservations.model";
import { BOSubServiceDetail } from "../../models/BOSubServiceDetail.model";
import { SubServiceDetailService } from "../../services/subServiceDetail.service";
import { SubServiceTypeService } from "../../services/subServiceType.service";
import { BOSubServiceType } from "../../models/BOSubServiceType.model";
import { ServiceGroupsService } from "../../services/serviceGroups.service";
import { isNumber } from "@ng-bootstrap/ng-bootstrap/util/util";
import { BOSubServiceDetailFilter } from "../../models/BOSubServiceDetailFilter.model";


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'sellModal',
    templateUrl: './sell.component.html',
    styleUrls: ['./sell.component.css'],

})

export class SellComponent implements OnDestroy, OnInit
{

    @ViewChild('sellModal', { static: true }) modal: ModalDirective;
    @ViewChild('sellDetailTable') sellDetailTable:MatTable<BOSellDetail>;
    @ViewChild('payTypeSellDetailTable') payTypeSellDetailTable:MatTable<BOPayTypeSellDetail>;
    @ViewChild('createSubServiceModal') public createSubServiceModal: ModalDirective;
    @ViewChild('subServiceDetailTable') subServiceDetailTable: MatTable<BOSubServiceDetail>;


    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    busy: Subscription | undefined;
   // sellDataSendedSubscription$:Subscription;
    busyData$:Subscription;
    sellDataReceived:BOSellDataSended= new BOSellDataSended();
    profesionalSelected:number;
    dataSource:BOSellDetail[]=[];
    dataSourcePayTypeSellDetailTable:BOPayTypeSellDetail[]=[];
    dataSourceSubServiceDetailTable:BOSubServiceDetail[]=[];
    displayedColumns: string[] = ['profesionalId', 'serviceTypeId', 'price', 'acciones'];
    displayedColumnsPayTypeSellDetailTable: string[] = ['payTypeId', 'invoiceTypeName','numberPayTypeSellDetail', 'datePayTypeSellDetail','price', 'acciones'];
    displayedColumnsSubServiceDetailTable:string[]=['profesionalId', 'subServiceGroupId','serviceTypeId', 'subServiceTypeId'];

    sellForm = this._formBuilder.group({

      documentNumber:         [{value:'', disabled:true}],
      dateSell:               ['', Validators.required],
      userName:               ['', Validators.required],
      userEmail:              ['', Validators.required],
      userId:                 ['', Validators.required],
      invoiceTypeId:          [{value:'', disabled:true}],
      total:                  [''],
      observations:           [''],

    });

    sellDetailsForm= this._formBuilder.group({
      serviceTypeId:          ['', Validators.required],
      profesionalId:          ['', Validators.required],
      counterSellDetail:      [{value:'', disabled:true}],

      // amount:                 ['', Validators.required],
      price:                  ['', Validators.required],

    })

    payTypeSellDetailForm= this._formBuilder.group({
        payTypeId:                    ['', Validators.required],
        invoiceTypeId:                ['', Validators.required],
        numberPayTypeSellDetail:      [''],
        datePayTypeSellDetail:        [''],
        totalPayTypeSellDetail:       [''],
        payTypeSellDetailBank:        [''],
        payTypeSellDetailObservations:[''],
        totalPayTypeSellDetailList:   [{ value: '', disabled: true }],
        counterPayTypeSellDetail:     [{ value: '', disabled: true }]
    })

    subServiceDetailForm= this._formBuilder.group({
      subServiceGroupId:              ['', Validators.required],
      subServiceTypeId:               ['', Validators.required],
      serviceTypeId:                  ['', Validators.required],
      profesionalId:                  ['', Validators.required],
      dateSubService:                 [''],
      price:                          ['', Validators.required],
      observations:                   [''],
      counterSubServiceDetail:        [{ value: '', disabled: true }],
      totalSubServiceDetailList:      [{ value: '', disabled: true }],


    })

    serviceTypeList                     :BOItem             [] = [];
    profesionals                        :BOPerson           [] = [];
    payTypeList                         :BOPayType          [] = [];
    serviceGroupList                    :BOItem             [] = [];

    subServiceTypeList                  :BOSubServiceType   [] = [];
    subServiceDetailList                :BOSubServiceDetail [] = [];

    //serviceTypeListCopy                 :BOItem             [] = [];
    subServiceTypeListCopy              :BOSubServiceDetail [] = [];

    serviceTypeListShort                :BOItem             [] = [];
    subServiceTypeListShort            :BOSubServiceType   [] = [];



    invoiceTypeList                     :BOInvoiceType      [] = [];
    invoiceTypeListR                    :BOInvoiceType      [] = [];

    sellb                               :BOSell=new BOSell();
    sells                               :BOSell             [] = [];
    sellDetailsList                     :BOSellDetail       [] = [];
    payTypeSellDetailsList              :BOPayTypeSellDetail[] = [];
    sellTotal                           :number;

    currentSell                         :BOSell;
    currentSellDetail                   :BOSellDetail;
    currentPayTypeSellDetail            :BOPayTypeSellDetail;
    currentSubServiceDetail             :BOSubServiceDetail;


    positionCurrentSell                 :number=0;
    positionCurrentSellDetail           :number=0;
    positionCurrentPayTypeSellDetail    :number=0
    positionCurrentSubServiceDetail     :number=0


    sellIdTemp                          :number=0;
    sellDetailsIdTemp                   :number=0;
    payTypeSellDetailsIdTemp            :number=0;
    subServiceDetailIdTemp              :number=0;
    subServiceTypeDetailsIdTemp         :number=0;
    subServiceTypeIdTemp                :number=0;

    showSellNav                        :boolean=false;
    showSellDetailNav                  :boolean=false;
    showLastSellDetail                 :boolean=false;
    showPayTypeSellDetailNav            :boolean=false;
    showLastPayTypeSellDetail           :boolean=false;

    showSubServiceDetailNav            :boolean=false;
    showLastSubServiceDetail           :boolean=false;

    showSaveSellDetailButton       :boolean=true;
    showSaveSellButton             :boolean=true;
    showSavePayTypeSellDetailButton:boolean=true;
    showSaveSubServiceDetailButton :boolean=true;


    origen                        :number=0;
    resultRemoveSellDetail        :boolean=false;
    resultRemovePayTypeSellDetail :boolean=false;
    resultRemoveSubServiceDetail  :boolean=false;

    sellDetailId                  :number=-1;
    sellDetailIdTemp              :number=-1;
//filter
    filterSbSvcDetail             :BOSubServiceDetailFilter=new BOSubServiceDetailFilter();


    utilities: Utilities= new Utilities();

    constructor(public   toastr            : ToastrService,
                private _formBuilder       : FormBuilder,
                private _sellService       : SellService,
                private _personService     : PersonService,
                private _serviceTypeService: ServiceTypeService,
                private _servicePayType    : PayTypeService,
                private _serviceInvoiceType: InvoiceTypeService,
                private _dialogConfirm     : DialogConfirmService,
                private _subServiceType    : SubServiceTypeService,
                private _subServiceDetail  : SubServiceDetailService,
                private _serviceGroupService: ServiceGroupsService,
                private _changeDetectorRef : ChangeDetectorRef,
              ) { }

    ngOnInit(): void
    {

   }

    dataLoad():void
    {
      this.busyData$ = forkJoin
      (
        {
        resultProfesionals: this._personService.getProfesionals(),
        resultServiceType: this._serviceTypeService.getAll(),
        resultServicePayType: this._servicePayType.getAll(),
        resultServiceInvoiceType:this._serviceInvoiceType.getAll(),
        resultServiceSubServiceTypeList: this._subServiceType.getAll(),
        resultSubServiceDetailList:this._subServiceDetail.getAll(),
        resultServiceGroupList:this._serviceGroupService.getAll(),
        }
      ).subscribe
        ((
            {
              resultProfesionals,
              resultServiceType,
              resultServicePayType,
              resultServiceInvoiceType,
              resultServiceSubServiceTypeList,
              resultServiceGroupList,

            }
          )=>
             {
                this.profesionals = resultProfesionals;
                this.serviceTypeList = resultServiceType;
                this.payTypeList=resultServicePayType;
                this.invoiceTypeList=resultServiceInvoiceType;
                this.subServiceTypeList= resultServiceSubServiceTypeList;
                this.serviceGroupList=resultServiceGroupList;

                this.invoiceTypeList.forEach(i=>{
                  if(i.id !=3 && i.id !=4)
                    {
                      this.invoiceTypeListR.push(i);
                    }
                })

                this.serviceGroupList.forEach(sg=>{
                  sg.serviceGroupId=Utilities.convertStringToInt(sg.serviceGroup);
                  console.log('sg.serviceGroup es:',sg.serviceGroup );
                  console.log('sg.serviceGroupId es:',sg.serviceGroupId );
                })

                  console.log('profesionals es:', this.profesionals, '  serviceList es:',
                  this.serviceTypeList, ' payTypeList es:', this.payTypeList,' e invoiceTypeListR es:',
                  this.invoiceTypeListR, 'this.serviceGroupList es:', this.serviceGroupList,
                  ' this.subServiceTypeList es:', this.subServiceTypeList);
             }
        )
    }

    getProfesionalName(idProfessional:number):string
    {
      let name:string="";

      this.profesionals.forEach(p=>{
        if(p.id==idProfessional)
        {
          if(p.secondName.length<=0) p.secondName='';
          else p.secondName=p.secondName+' ';
          name=p.firstName +' '+p.secondName+ p.firtsSurName;
        }
      });
      return name;
    }

    getServiceName(idService:number):string
    {
      let name:string="";

      this.serviceTypeList.forEach(s=>{
        if(s.id==idService)
        {
          name=s.name;
        }
      });
      return name;
    }

    getPayTypeName(idPayType:number):string
    {
      let name:string="";

      this.payTypeList.forEach(p=>{
        if(p.id==idPayType)
        {
          name=p.name;
        }
      });
      return name;
    }

    getInvoicePayTypeName(idInvoicePayType:number):string
    {
      let name:string="";

      this.invoiceTypeList.forEach(p=>{
        if(p.id==idInvoicePayType)
        {
          name=p.name;
        }
      });
      return name;
    }

    getSubServiceTypeName(idSubServiceType:number):string
    {
      let name:string="";

      this.subServiceTypeList.forEach(p=>{
        if(p.id==idSubServiceType)
        {
          name=p.name;
        }
      });
      return name;
    }

    getGroupName(idGroup:number):string
    {
      let name:string="";

      this.serviceGroupList.forEach(p=>{
        if(p.id==idGroup)
        {
          name=p.name;
        }
      });
      return name;
    }

    //viene de Invoices (Facturas)Crear Factura -origen 2
    //openSellModal(boSell:BOSell,  origen: number, documentNumber?:string): void
    openSellModal(boSell:BOSell,  origen: number): void
    {
      this.dataLoad();
      this.positionCurrentSell =0;
      this.positionCurrentSellDetail=0;
      this.positionCurrentPayTypeSellDetail =0;
      this.sells=[];
      this.sellDetailsList=boSell.sellDetails;
      this.sellb=new BOSell();
      this.origen=origen;
      this.sellDetailsIdTemp         =0;
      this.payTypeSellDetailsIdTemp  =0;
      this.subServiceDetailIdTemp    =0;



      if(origen==1) //viene de ch
      {
        console.log('origen 1 bosell es:', boSell,);
        this.sellb=boSell;
        this.sellb.documentNumber='';
        this.sellb.observations='';
        this.sellb.payTypeSellDetails=[];
        //this.sellb.sellIdTemp=1;
        this.currentSell=this.sellb;
        this.fillSell();
        this.currentSellDetail=this.sellDetailsList[0];
        this.currentSellDetail.sellDetailIdTemp=0;
        this.sellDetailsIdTemp=1;

        console.log('En openSellModal this.currentSellDetail es:', this.currentSellDetail);
        this.fillSellDetails();

        if(this.sellb.payTypeSellDetails.length>0){
          console.log('this.sellb.payTypeSellDetails es:', this.sellb.payTypeSellDetails);
          this.currentPayTypeSellDetail=this.payTypeSellDetailsList[0];
        }
        else this.currentPayTypeSellDetail=null;

        console.log('En origen==1, this.currentPayTypeSellDetail es:', this.currentPayTypeSellDetail);
        this.fillPayTypeSellDetails();

        this.dataSource=this.sellDetailsList;
        this.dataSourcePayTypeSellDetailTable=this.payTypeSellDetailsList;
        this.dataSourceSubServiceDetailTable= this.subServiceDetailList;


        this.showSellDetailNav=true;
        this.showPayTypeSellDetailNav=true;
      }
      if(origen==2) //viene de dashboard o de crearFactura en invoices
      {
        this.sellb=boSell;
        this.positionCurrentSell =0;
        this.positionCurrentSellDetail=0;
        this.positionCurrentPayTypeSellDetail =0;
        this.sellb.total=0;
        this.sellb.payTypeSellDetails=[];
        this.sellb.sellDetails=[];
        this.sellb.observations='';
        this.currentSell=this.sellb;
      }

      this.modal.config = { backdrop: "static", keyboard: false };
      this.modal.show();
    }

    //viene de Invoices (Facturas)Editar-origen 3
   // openSellModalEdit(origen:number,documentNumber?:string): void
    openSellModalEdit(sellId?:number): void
    {
      console.log('Entra a openSellModalEdit y sellId es', sellId);
      this.sellDetailsIdTemp         =800;
      this.payTypeSellDetailsIdTemp  =800;
      this.subServiceDetailIdTemp    =800;
      this.dataLoad();

        //prepare data
       // this.origen=origen;
        this.sellb=new BOSell();
        //documentNumber= documentNumber.trim();

        //this._sellService.getSell(documentNumber).subscribe
        this._sellService.getSell(sellId).subscribe

         ({next: data=>{this.sellb=data; console.log('this.sellb que asigna data es:', this.sellb);

          if(this.sellb)
          {
             console.log('En el if de openModalEdit, this.sellb es:', this.sellb);
               this.positionCurrentSell =0;
               this.positionCurrentSellDetail=0;
               this.positionCurrentPayTypeSellDetail=0;
               this.currentSell=this.sellb;
              //  this.sellDetailsList= this.sellb.sellDetails;
              //  this.currentSellDetail=this.sellDetailsList[0];
              //  this.payTypeSellDetailsList=this.sellb.payTypeSellDetails;
              //  this.currentPayTypeSellDetail=this.payTypeSellDetailsList[0];


               this.sellDetailsList=this.currentSell.sellDetails;
               this.currentSellDetail=this.sellDetailsList[0];
               this.payTypeSellDetailsList=this.currentSell.payTypeSellDetails;
               this.currentPayTypeSellDetail=this.payTypeSellDetailsList[0];
               console.log('this.currentPayTypeSellDetail es:', this.currentPayTypeSellDetail);

               console.log('En el if de openModalEdit, this.currentSell.invoiceTypeId es:', this.currentSell.invoiceTypeId);

               this.sellForm.patchValue({
                 documentNumber:this.currentSell.documentNumber,
                 invoiceTypeId:this.currentSell.invoiceTypeId,
                 total:this.currentSell.total,
                 dateSell:this.currentSell.dateSell,
                 userId:this.currentSell.userId,
                 userName:this.currentSell.userName,
                 userEmail:this.currentSell.userEmail,
                 observations:this.currentSell.observations,
               });

               //elimina el id y les asigna un idTemp nuevo
              // let tempIdDetail:number=900;
               this.sellDetailsList.forEach(x=>{
              //  x.sellDetailIdTemp=tempIdDetail;
                x.sellDetailIdTemp=this.sellDetailsIdTemp;
                this.sellDetailsIdTemp+=1;
               // tempIdDetail+=1;
               })





               //let tempIdPayType:number=900;

               this.payTypeSellDetailsList.forEach(p=>{
               // p.payTypeIdTemp=tempIdPayType;
               p.payTypeIdTemp=this.payTypeSellDetailsIdTemp;
                //tempIdPayType+=1;
                this.payTypeSellDetailsIdTemp+=1;
               })
//prepare subService
                this.sellDetailsList.forEach(slld=>{
                  slld.subServicesDetails.forEach(subsvc=>{
                    subsvc.sellDetailIdTemp=slld.sellDetailIdTemp;
                  })
                })

               this.sellDetailsForm.patchValue({
                serviceTypeId:this.currentSellDetail.serviceTypeId,
                profesionalId:this.currentSellDetail.profesionalId,
                price:this.currentSellDetail.price,
                counterSellDetail:1
               });


               this.payTypeSellDetailForm.patchValue({
                 payTypeId:this.currentPayTypeSellDetail.payTypeId,
                 invoiceTypeId:this.currentPayTypeSellDetail.invoiceTypeId,
                 numberPayTypeSellDetail:this.currentPayTypeSellDetail.numberPayTypeSellDetail,
                 datePayTypeSellDetail:this.currentPayTypeSellDetail.datePayTypeSellDetail,
                 totalPayTypeSellDetail:this.currentPayTypeSellDetail.price,
                 counterPayTypeSellDetail:1,
                 totalPayTypeSellDetailList:this.payTypeSellDetailsList.length,

                 payTypeSellDetailBank:this.currentPayTypeSellDetail.payTypeSellDetailBank,
                 payTypeSellDetailObservations:this.currentPayTypeSellDetail.payTypeSellDetailObservations,
               });

               this.showSellDetailNav=true;
               this.showPayTypeSellDetailNav=true;
               this.showSaveSellButton=true;
               this.showSaveSellDetailButton=true;
               this.showSaveSellButton=true;
               this.showSavePayTypeSellDetailButton=true;
               this.showSaveSubServiceDetailButton=true;

               //this.showSaveSellButton=false;
              //  this.showSellDetailNav=false;
              //  this.showPayTypeSellDetailNav=false;
              //  this.showSaveSellDetailButton=false;
              //  this.showSaveSellButton=false;
              // this.showSavePayTypeSellDetailButton=false;
               //this.showSaveSubServiceDetailButton=false;

               //patchValue sellDetailsForm

               console.log('Al lfinal de openModalEdit, this.sellb con idTemps es:', this.sellb);

               this.modal.config = { backdrop: "static", keyboard: false };
               this.modal.show();

             }
             else
             {
               this.toastr.error('Error', 'Se ha producido error en la búsqueda del registro')
             }
         },
         error:()=>{console.log('error')}
         })

    }// End openSellModalEdit

    navNextSell(){
      console.log('sells es:', this.sells);
      console.log('positionCurrentSell entra con:',this.positionCurrentSell);

      if(this.positionCurrentSell >=0 && this.positionCurrentSell <= this.sells.length-2)
      {
        this.currentSell=this.sells[this.positionCurrentSell+1];
        this.positionCurrentSell+=1;
        console.log('next currentSell es:', this.currentSell);
        console.log('next Después de incrementar positionCurrentSell es:',this.positionCurrentSell);

      }

      this.fillSell();

    }

    navBackSell(){

      if(this.positionCurrentSell > 0)
        {
          this.positionCurrentSell-=1;
          this.currentSell=this.sells[this.positionCurrentSell];

          console.log('back currentSell es:', this.currentSell);
          console.log('back Después de incrementar positionCurrentSell es:',this.positionCurrentSell);
        }
        this.fillSell();

    }

    ///sellDetail region
    saveSellDetail():void
    {
      console.log('Entra a saveSellDetail');
      console.log('this.sellDetailsList es:',this.sellDetailsList);
      console.log('origen es:', this.origen);
      try
      {
       // if(this.sellDetailsList.length===0)//origen is 1 ch
        if(this.origen==1)//origen is 1 ch
        {
          console.log('En saveSellDetail entra a origen==1')
          if(this.sellDetailsList.length>0)
          {
            this.sellDetailGreaterThanZero();
          }
          else
          {
            this.sellDetailSmallerOrEqualZero();
          }

        }

        if(this.origen==2)
        {
          console.log('En saveSellDetail entra a origen==2')
          if(this.sellDetailsList.length>0)
          {
              this. sellDetailGreaterThanZero();

          }// end  if origen==2 y this.sellDetailsList.length>0
          else
          {
            this.sellDetailSmallerOrEqualZero();
          }

        }

        if(this.origen==3) //edita
        {

          if(this.sellDetailsList.length>0)
          {

                this. sellDetailGreaterThanZero();

          }// end  if origen== y this.sellDetailsList.length>0
            else
          {

              this.sellDetailSmallerOrEqualZero();
          }
        }

        this.toastr.success('Éxito en guardar','Se ha guardado el servicio');
      }
      catch (error)
      {
        console.log ('Error en guardar servicio. Error:', error);
        this.toastr.error('Fallo en guardar','No se ha guardado el servicio')
      }

    }

    launchRemoveSellDetailById(id:number):void{
      console.log('En launchRemoveSellDetail id es:', id);
      this.removeSellDetailById(id);
    }

    removeSellDetailById(id:number):void
    {
      this.resultRemoveSellDetail=false;
      let confirm:boolean=false;

      this._dialogConfirm.openConfirmDialog('¿Está seguro que quiere borrar este elemento?')
      .afterClosed().subscribe(res=>{
        confirm=res;
        console.log('confirm es:',res);

        if(confirm)
        {
            const index=this.sellDetailsList.findIndex(item=>item.sellDetailIdTemp==id);
            let priceTemp:number=this.sellDetailsList[index].price;

            if(index==0 && this.sellDetailsList.length==1)
            {
                  let priceTemp:number=this.sellDetailsList[index].price;
                  this.sellDetailsList.splice(index,1);
                  this.currentSell.total-=priceTemp;

                  this.sellForm.patchValue({
                    total:this.currentSell.total});

                   this.resultRemoveSellDetail=true;
                   this.currentSellDetail=null;
                   this.sellDetailsForm.reset();
                   this.positionCurrentSellDetail=null;
            }

            if(index==0 && this.sellDetailsList.length>1)
            {
                    this.sellDetailsList.splice(index,1);
                    this.currentSell.total-=priceTemp;
                    this.sellForm.patchValue({
                      total:this.currentSell.total});

                    this.resultRemoveSellDetail=true;
                     this.currentSellDetail= this.sellDetailsList[0];
                     this.positionCurrentSellDetail=0;

                     this.sellDetailsForm.patchValue({
                      profesionalId:this.currentSellDetail.profesionalId,
                      serviceTypeId: this.currentSellDetail.serviceTypeId,
                      price:this.currentSellDetail.price,
                      counterSellDetail:this.positionCurrentSellDetail+1
                    })

              }
              if(index>0 && this.sellDetailsList.length>1)
              {
                  this.sellDetailsList.splice(index,1);
                  this.currentSell.total-=priceTemp;
                  this.sellForm.patchValue({
                    total:this.currentSell.total});

                  this.resultRemoveSellDetail=true;
                  this.currentSellDetail= this.sellDetailsList[index-1];
                  this.positionCurrentSellDetail=index-1;

                  this.sellDetailsForm.patchValue({
                  profesionalId:this.currentSellDetail.profesionalId,
                  serviceTypeId: this.currentSellDetail.serviceTypeId,
                  price:this.currentSellDetail.price,
                  counterSellDetail:this.positionCurrentSellDetail+1
                  })

              }
          this.sellDetailTable.renderRows();

            console.log('Al salir del confirm, result es:', this.resultRemoveSellDetail);
            return this.resultRemoveSellDetail;
          }
          else
          {
            console.log('Entra al else confirm es false, result es false');
            this.resultRemoveSellDetail=false;
            return this.resultRemoveSellDetail;
          }
      });

      console.log('Llega al final de remove, return es:', this.resultRemoveSellDetail);
    }

    sellDetailEdit():void
    {
      console.log('Entra a Edit this.positionCurrentSellDetail no es null,  positionCurrentSellDetail es:', this.positionCurrentSellDetail)
      let idTemp= this.sellDetailsList[this.positionCurrentSellDetail].sellDetailIdTemp;


      let priceTemp=this.sellDetailsList[this.positionCurrentSellDetail].price;
      let bosd: BOSellDetail= new BOSellDetail();
      bosd.profesionalId=this.sellDetailsForm.get('profesionalId').value;
      bosd.serviceTypeId=this.sellDetailsForm.get('serviceTypeId').value;
      bosd.price=parseInt(this.sellDetailsForm.get('price').value);
      bosd.sellDetailIdTemp=idTemp;

      this.sellDetailsList.splice(this.positionCurrentSellDetail,1,bosd);
      //1. Elimina el valor del sellCurrent.total
      console.log('priceTemp es:', priceTemp);
      this.currentSell.total-=priceTemp;

      //2.Adiciona el valor de price
      this.currentSell.total+=bosd.price;

      this.sellForm.patchValue({
      total:this.currentSell.total});

      this.currentSellDetail=this.sellDetailsList[this.positionCurrentSellDetail];
      this.fillSellDetails();
      this.sellDetailTable.renderRows();

     //TODO llenar sellDetailsIdTemp
      this.showSellDetailNav=true;
      this.showLastSellDetail=false;
    }

    sellDetailGreaterThanZero():void
    {
        console.log('Entra a sellDetailGreaterThanZero')
        let pos=this.positionCurrentSellDetail;
        console.log('pos es:', pos);
        console.log('this.sellDetailsList es', this.sellDetailsList);

        let verifyTemp:number=null;

        if(this.sellDetailsList[pos] !==null &&  this.sellDetailsList[pos] !== undefined)
        {
          console.log('this.sellDetailsList[pos] tiene un valor, es:', this.sellDetailsList[pos]);
        }
        else{
          verifyTemp=-1;
          console.log('verifyTemp es:', verifyTemp);
        }

        if(verifyTemp==-1) //position no tiene elementos
        {
          console.log('Entra a origen 2 this.sellDetailsList.length>0 y sellDetailIdTemp es -1');
          let bosd: BOSellDetail= new BOSellDetail();
          bosd.profesionalId=this.sellDetailsForm.get('profesionalId').value;
          bosd.serviceTypeId=this.sellDetailsForm.get('serviceTypeId').value;
          bosd.price=parseInt(this.sellDetailsForm.get('price').value);
          bosd.sellDetailIdTemp=this.sellDetailsIdTemp;

          this.sellDetailsList.push(bosd);
          let position=this.sellDetailsList.length-1;
          this.currentSellDetail=this.sellDetailsList[position];
          this.positionCurrentSellDetail=position;

          this.fillSellDetails();
           this.sellDetailTable.renderRows();

          //Actualiza valor total de la factura:
          console.log('antes de actualizar this.currentSell.total es:',this.currentSell.total )
          this.currentSell.total+=bosd.price;

          console.log('depués de actualizar this.currentSell.total es:',this.currentSell.total )

          this.sellForm.patchValue({
            total:this.currentSell.total});

          this.showSellDetailNav=true;
          this.showLastSellDetail=false;
          console.log('this.sell es:', this.currentSell);
          console.log('this.sellDetailsList es:', this.sellDetailsList);
          this.sellDetailsIdTemp+=1;

        }
        else
        {
         // console.log('Entra a origen 2 this.sellDetailsList.length>0 y sellDetailIdTemp es <> -1');

          this.sellDetailEdit();

        }

    }

    sellDetailSmallerOrEqualZero():void
    {

      console.log('Entra this.sellDetailsList.length es 0');
      let bosd: BOSellDetail= new BOSellDetail();
      bosd.profesionalId=this.sellDetailsForm.get('profesionalId').value;
      bosd.serviceTypeId=this.sellDetailsForm.get('serviceTypeId').value;
      bosd.price=parseInt(this.sellDetailsForm.get('price').value);
      bosd.sellDetailIdTemp=this.sellDetailsIdTemp;

      this.sellDetailsList.push(bosd);
      let position=this.sellDetailsList.length-1;
      this.currentSellDetail=this.sellDetailsList[position];
      this.positionCurrentSellDetail=position;

      this.fillSellDetails();

      //Actualiza valor total de la factura:
      console.log('antes de actualizar this.currentSell.total es:',this.currentSell.total )
      this.currentSell.total+=bosd.price;

      console.log('depués de actualizar this.currentSell.total es:',this.currentSell.total )

      this.sellForm.patchValue({
        total:this.currentSell.total});

      this.showSellDetailNav=true;
      console.log('this.sell es:', this.currentSell);
      console.log('this.sellDetailsList es:', this.sellDetailsList);
      this.sellDetailsIdTemp+=1;
    }
    navNextSellDetail():void
    {
      console.log('Entra al metodo navNextSellDetail');
      console.log('this.positionCurrentSellDetail es:',this.positionCurrentSellDetail, ' y this.sellDetailsList.length es:', this.sellDetailsList.length );

      if(this.positionCurrentSellDetail >=0 && this.positionCurrentSellDetail<= this.sellDetailsList.length-2)
      {
          this.currentSellDetail=this.sellDetailsList[this.positionCurrentSellDetail+1];
          this.positionCurrentSellDetail+=1;
          this.fillSellDetails();
          console.log('next currentSellDetail es:', this.currentSellDetail);
          console.log('next Después de incrementar positionCurrentSellDetail es:',this.positionCurrentSellDetail);
      }
    }

    navBackSellDetail():void
    {
      if(this.positionCurrentSellDetail > 0)
      {
          if(this.positionCurrentSellDetail== this.sellDetailsList.length)
          {
              console.log('En navBackSellDetail this.positionCurrentSellDetail== this.sellDetailsList.length');
              this.positionCurrentSellDetail-=1;
          }
            this.positionCurrentSellDetail-=1;
            this.currentSellDetail=this.sellDetailsList[this.positionCurrentSellDetail];

          console.log('back currentSellDetail es:', this.currentSellDetail);
          console.log('back Después de incrementar positionCurrentSellDetail es:',this.positionCurrentSellDetail);
      }
        this.fillSellDetails();
    }

    navFirstSellDetail():void{
      this.currentSellDetail=this.sellDetailsList[0];
      this.positionCurrentSellDetail=0;
      this.fillSellDetails();
    }

    navLastSellDetail():void{
      this.currentSellDetail=this.sellDetailsList[this.sellDetailsList.length-1];
      this.positionCurrentSellDetail=this.sellDetailsList.length-1;
      this.fillSellDetails();

    }
//#region payTypeSellDetail

    savePayTypeSellDetail():void
    {
      try
      {
          console.log('En savePayTypeSellDetail() entra al try ')
          if(this.payTypeSellDetailsList.length>0)
          {
            this.payTypeGreaterThanZero();
          }
          else
          {
            this.payTypeSmallerOrEqualZero();
          }
      }
      catch (error)
      {
        console.log ('Error en guardar servicio. Error:', error);
        this.toastr.error('Fallo en guardar','No se ha guardado el pago')
      }
    }

    payTypeSmallerOrEqualZero():void
    {
      console.log('Entra a payTypeSmallerOrEqualZero this.payTypeSellDetailsList.length es 0');
      let bopt: BOPayTypeSellDetail= new BOPayTypeSellDetail();
      bopt.payTypeId=this.payTypeSellDetailForm.get('payTypeId').value;
      bopt.invoiceTypeId=this.payTypeSellDetailForm.get('invoiceTypeId').value;
      bopt.price=this.payTypeSellDetailForm.get('totalPayTypeSellDetail').value;
      bopt.numberPayTypeSellDetail=this.payTypeSellDetailForm.get('numberPayTypeSellDetail').value;
      bopt.datePayTypeSellDetail=new Date(this.payTypeSellDetailForm.get('datePayTypeSellDetail').value);

      bopt.payTypeSellDetailBank=this.payTypeSellDetailForm.get('payTypeSellDetailBank').value;
      bopt.payTypeSellDetailObservations=this.payTypeSellDetailForm.get('payTypeSellDetailObservations').value;


      bopt.payTypeIdTemp=this.payTypeSellDetailsIdTemp;


      this.payTypeSellDetailsList.push(bopt);
      let position=this.payTypeSellDetailsList.length-1;
      this.currentPayTypeSellDetail=this.payTypeSellDetailsList[position];
      this.positionCurrentPayTypeSellDetail=position;

      this.fillPayTypeSellDetails();

      console.log('En payTypeSmallerOrEqualZero pasa después de this.payTypeSellDetailTable.renderRows()');
      console.log('En payTypeSmallerOrEqualZero después de renderRows, this.payTypeSellDetailsList es:',this.payTypeSellDetailsList );

      this.showPayTypeSellDetailNav=true;
      this.showLastPayTypeSellDetail=false;
     console.log(' this.payTypeSellDetailsList es:',  this.payTypeSellDetailsList);

      this.payTypeSellDetailsIdTemp+=1;
    }

    payTypeGreaterThanZero():void
    {
        console.log('Entra a payTypesellDetailGreaterThanZero')
        let pos=this.positionCurrentPayTypeSellDetail;
        let verifyTemp:number=null;

        if(this.payTypeSellDetailsList[pos] !==null &&  this.payTypeSellDetailsList[pos] !== undefined)
        {
          console.log('idTemp tiene un valor, es:', this.payTypeSellDetailsList[pos]);
        }
        else{
          verifyTemp=-1;
          console.log('verifyTemp es:', verifyTemp);
        }

        if(verifyTemp==-1) //no tiene elementos
        {
          console.log('Entra a this.payTypeSellDetailsList.length>0 y sellDetailIdTemp es -1');
          let bopt: BOPayTypeSellDetail= new BOPayTypeSellDetail();
          bopt.payTypeId=this.payTypeSellDetailForm.get('payTypeId').value;
          bopt.invoiceTypeId=this.payTypeSellDetailForm.get('invoiceTypeId').value;
          bopt.price=this.payTypeSellDetailForm.get('totalPayTypeSellDetail').value;
          bopt.numberPayTypeSellDetail=this.payTypeSellDetailForm.get('numberPayTypeSellDetail').value;
          bopt.datePayTypeSellDetail=new Date(this.payTypeSellDetailForm.get('datePayTypeSellDetail').value);

          bopt.payTypeSellDetailBank=this.payTypeSellDetailForm.get('payTypeSellDetailBank').value;
          bopt.payTypeSellDetailObservations=this.payTypeSellDetailForm.get('payTypeSellDetailObservations').value;
          bopt.payTypeIdTemp=this.payTypeSellDetailsIdTemp;

          this.payTypeSellDetailsList.push(bopt);
          let position=this.payTypeSellDetailsList.length-1;
          this.currentPayTypeSellDetail=this.payTypeSellDetailsList[position];
          this.positionCurrentPayTypeSellDetail=position;

          this.fillPayTypeSellDetails();
           this.payTypeSellDetailTable.renderRows();
          // this.dataSourcePayTypeSellDetailTable=this.payTypeSellDetailsList;
          // this._changeDetectorRef.detectChanges();
          console.log('En verifyTemp==-1 pasa después de this.payTypeSellDetailTable.renderRows()');
          console.log('En verifyTemp==-1 después de renderRows, this.payTypeSellDetailsList es:',this.payTypeSellDetailsList );


          this.showPayTypeSellDetailNav=true;
          this.showLastPayTypeSellDetail=false;

          this.payTypeSellDetailsIdTemp+=1;
        }
        else
        {
          this.payTypeEdit();
        }
    }
//
    removePayTypeSellDetailById(id:number):void
    {
      //this.resultRemoveSellDetail=false;
      this.resultRemovePayTypeSellDetail=false;
      let confirm:boolean=false;

      this._dialogConfirm.openConfirmDialog('¿Está seguro que quiere borrar este elemento?')
      .afterClosed().subscribe(res=>{
        confirm=res;
        console.log('confirm es:',res);

        if(confirm)
        {
            const index=this.payTypeSellDetailsList.findIndex(item=>item.payTypeIdTemp==id);

            if(index==0 && this.payTypeSellDetailsList.length==1)
            {
                  this.payTypeSellDetailsList.splice(index,1);
                  this.resultRemovePayTypeSellDetail=true;
                  this.currentPayTypeSellDetail=null;
                  this.payTypeSellDetailForm.reset();
                  this.positionCurrentPayTypeSellDetail=null;
            }

            if(index==0 && this.payTypeSellDetailsList.length>1)
            {
                    this.payTypeSellDetailsList.splice(index,1);
                    this.resultRemovePayTypeSellDetail=true;
                    this.currentPayTypeSellDetail= this.payTypeSellDetailsList[0];

                    this.positionCurrentPayTypeSellDetail=0;

                    this.payTypeSellDetailForm.patchValue({
                      payTypeId:this.currentPayTypeSellDetail.payTypeId,
                      numberPayTypeSellDetail: this.currentPayTypeSellDetail.numberPayTypeSellDetail,
                      datePayTypeSellDetail:this.currentPayTypeSellDetail.datePayTypeSellDetail,
                      totalPayTypeSellDetail:this.currentPayTypeSellDetail.price,
                      counterPayTypeSellDetail:this.positionCurrentPayTypeSellDetail+1,
                      totalPayTypeSellDetailList:this.payTypeSellDetailsList.length,

                      payTypeSellDetailBank:this.currentPayTypeSellDetail.payTypeSellDetailBank,
                      payTypeSellDetailObservations:this.currentPayTypeSellDetail.payTypeSellDetailObservations,
                    })

              }
              if(index>0 && this.payTypeSellDetailsList.length>1)
              {
                this.payTypeSellDetailsList.splice(index,1);
                  this.resultRemovePayTypeSellDetail=true;
                  this.currentPayTypeSellDetail= this.payTypeSellDetailsList[index-1];
                  this.positionCurrentPayTypeSellDetail=index-1;

                  this.payTypeSellDetailForm.patchValue({
                    payTypeId:this.currentPayTypeSellDetail.payTypeId,
                    numberPayTypeSellDetail: this.currentPayTypeSellDetail.numberPayTypeSellDetail,
                    datePayTypeSellDetail:this.currentPayTypeSellDetail.datePayTypeSellDetail,
                    totalPayTypeSellDetail:this.currentPayTypeSellDetail.price,
                    counterPayTypeSellDetail:this.positionCurrentPayTypeSellDetail+1,
                    totalPayTypeSellDetailList:this.payTypeSellDetailsList.length,

                    payTypeSellDetailBank:this.currentPayTypeSellDetail.payTypeSellDetailBank,
                    payTypeSellDetailObservations:this.currentPayTypeSellDetail.payTypeSellDetailObservations,

                  })

              }
          this.payTypeSellDetailTable.renderRows();

            console.log('Al salir del confirm, result es:', this.resultRemoveSellDetail);
            return this.resultRemovePayTypeSellDetail;
          }
          else
          {
            console.log('Entra al else confirm es false, result es false');
            this.resultRemovePayTypeSellDetail=false;
            return this.resultRemovePayTypeSellDetail;
          }
      });

      console.log('Llega al final de remove, return es:', this.resultRemovePayTypeSellDetail);
    }
//#endregion

//#region SubServiceDetail

    saveSubServiceDetail():void
    {
      try
      {
          console.log('En saveSubServiceDetail() entra al try ')
          if(this.subServiceDetailList.length>0)
          {
            this.subServiceDetailGreaterThanZero();
          }
          else
          {
            this.subServiceDetailSmallerOrEqualZero()
          }
      }
      catch (error)
      {
        console.log ('Error en guardar subService. Error:', error);
        this.toastr.error('Fallo en guardar','No se ha guardado el subService')
      }
    }

    subServiceDetailSmallerOrEqualZero():void
    {
      console.log('Entra a subServiceDetailSmallerOrEqualZero this.subServiceDetailsList.length es 0');
      console.log('sellDetails:', this.sellb.sellDetails);
      console.log('sellDetailsList:', this.sellDetailsList);
      console.log('sellDetailsIdTemp es:', this.sellDetailsIdTemp);
      let bopt: BOSubServiceDetail= new BOSubServiceDetail();
      bopt.subServiceTypeId=parseInt(this.subServiceDetailForm.get('subServiceTypeId').value);
      bopt.subServiceName=this.subServiceTypeList.find(x=>x.id==bopt.subServiceTypeId)?.name;

      bopt.subServiceGroupId=parseInt(this.subServiceDetailForm.get('subServiceGroupId').value);
      bopt.subServiceGroupName=this.serviceGroupList.find(x=>x.id== bopt.subServiceGroupId)?.name;

      bopt.sellDetailId=this.sellDetailId;
      bopt.serviceTypeId=parseInt(this.subServiceDetailForm.get('serviceTypeId').value);
      bopt.serviceName=this.serviceTypeList.find(x=>x.id==bopt.serviceTypeId)?.name;
      bopt.profesionalId=parseInt(this.subServiceDetailForm.get('profesionalId').value);
      bopt.profesionalName=this.getProfesionalName(bopt.profesionalId);
      bopt.date=new Date(this.subServiceDetailForm.get('dateSubService').value);
      bopt.observations=this.subServiceDetailForm.get('observations').value;
      bopt.price=this.subServiceDetailForm.get('price').value;
      //bopt.sellDetailIdTemp=this.sellDetailsIdTemp;
      bopt.sellDetailIdTemp=this.currentSellDetail.sellDetailIdTemp;
      bopt.subServiceTypeIdTemp=this.subServiceTypeIdTemp;
     console.log('En subServiceDetaiSmallerThanZero bopt para push es  es:', bopt);


     // this.subServiceDetailList.push(bopt);
     this.subServiceDetailList=this.addDataToArray(bopt, this.subServiceDetailList);

      let position=this.subServiceDetailList.length-1;
      this.currentSubServiceDetail=this.subServiceDetailList[position];
      this.positionCurrentSubServiceDetail=position;

      this.fillSubServiceDetails();
      //this.refresh();
      //this.subServiceDetailTable.renderRows();


      console.log('En subServiceDetailSmallerOrEqualZero después de renderRows,  this.subServiceDetailList:', this.subServiceDetailList);

      this.showSubServiceDetailNav=true;
      this.showLastSubServiceDetail=false;
      //console.log(' this.payTypeSellDetailsList es:',  this.payTypeSellDetailsList);

      //this.subServiceTypeDetailsIdTemp+=1;
      this.subServiceTypeIdTemp +=1;
      //this.subServiceDetailIdTemp+=1;
      console.log('Ha llegado al final de subServiceDetailSmallerOrEqualZero');
    }


    subServiceDetailGreaterThanZero():void
    {
        console.log('Entra a subServiceDetailGreaterThanZero');
        console.log('sellDetails:', this.sellb.sellDetails);
        console.log('sellDetailsList:', this.sellDetailsList);
        console.log('this.subServiceDetailList:', this.subServiceDetailList);

        console.log('sellDetailsIdTemp es:', this.sellDetailsIdTemp);


        let pos=this.positionCurrentSubServiceDetail;
        let verifyTemp:number=null;

        if(this.subServiceDetailList[pos] !==null &&  this.subServiceDetailList[pos] !== undefined)
        {
          console.log('(this.subServiceDetailList[pos] tiene un valor, es:', this.subServiceDetailList[pos]);
        }
        else{
          verifyTemp=-1;
          console.log('verifyTemp es:', verifyTemp);
          //La posicion actual No tiene elemento
        }

        if(verifyTemp==-1) //pos no tiene elementos
        {
          console.log('Entra a this.subServiceDetailList.length>0 y verifyTemp es -1');
          let bopt: BOSubServiceDetail= new BOSubServiceDetail();
          bopt.subServiceTypeId=parseInt(this.subServiceDetailForm.get('subServiceTypeId').value);
          bopt.subServiceName=this.subServiceTypeList.find(x=>x.id==bopt.subServiceTypeId)?.name;
          bopt.subServiceGroupId=parseInt(this.subServiceDetailForm.get('subServiceGroupId').value);
          bopt.subServiceGroupName=this.serviceGroupList.find(x=>x.id== bopt.subServiceGroupId)?.name;

          bopt.sellDetailId=this.sellDetailId;
          bopt.serviceTypeId=this.subServiceDetailForm.get('serviceTypeId').value;
          bopt.serviceName=this.serviceTypeList.find(x=>x.id==bopt.serviceTypeId)?.name;
          bopt.profesionalId=parseInt(this.subServiceDetailForm.get('profesionalId').value);
          bopt.profesionalName=this.getProfesionalName(bopt.profesionalId);
          bopt.date=new Date(this.subServiceDetailForm.get('dateSubService').value);
          bopt.observations=this.subServiceDetailForm.get('observations').value;
          bopt.price=this.subServiceDetailForm.get('price').value;
          bopt.subServiceTypeIdTemp=this.subServiceTypeIdTemp;
          bopt.sellDetailIdTemp=this.currentSellDetail.sellDetailIdTemp;
          bopt.sellDetailId=this.currentSellDetail.id;
          console.log('En subServiceDetaiGreaterThanZero bopt para push es  es:', bopt);

         // this.subServiceDetailList.push(bopt);
         this.subServiceDetailList=this.addDataToArray(bopt, this.subServiceDetailList);

          console.log('En subServiceDetailGreatetThenZero, this.subServiceDetailList de salida es:', this.subServiceDetailList);

          let position=this.subServiceDetailList.length-1;
          this.currentSubServiceDetail=this.subServiceDetailList[position];
          this.positionCurrentSubServiceDetail=position;
          //this.subServiceDetailIdTemp+=1;
          //this.subServiceTypeDetailsIdTemp+=1;
          this.subServiceTypeIdTemp +=1;
          //this.sellDetailIdTemp+=1;
          //this.fillPayTypeSellDetails();
          this.fillSubServiceDetails();
          //this.refresh();
         // this.subServiceDetailTable.renderRows();

          // TODO
          //this.payTypeSellDetailTable.renderRows();
          //  console.log('En verifyTemp==-1 pasa después de this.payTypeSellDetailTable.renderRows()');
          // console.log('En verifyTemp==-1 después de renderRows, this.payTypeSellDetailsList es:',this.payTypeSellDetailsList );
          //

          this.showSubServiceDetailNav=true;
          this.showLastSubServiceDetail=false;

         // this.subServiceTypeDetailsIdTemp+=1;

         //TODO ¿es esto correcto?
         //this.sellDetailsIdTemp+=1;

        }
        else
        {
          //TODO
          //this.payTypeEdit();
          this.subServiceTypeEdit();
        }
    }
//#endregion

    payTypeEdit():void
    {
      console.log('Entra a Edit this.positionCurrentSellDetail no es null,  positionCurrentPayTypeSellDetail es:', this.positionCurrentPayTypeSellDetail)
      let idTemp= this.payTypeSellDetailsList[this.positionCurrentPayTypeSellDetail].payTypeIdTemp;
      // let priceTemp=this.sellDetailsList[this.positionCurrentSellDetail].price;
      let bopt: BOPayTypeSellDetail= new BOPayTypeSellDetail();

      bopt.payTypeId=this.payTypeSellDetailForm.get('payTypeId').value;
      bopt.invoiceTypeId=this.payTypeSellDetailForm.get('invoiceTypeId').value;
      bopt.price=this.payTypeSellDetailForm.get('totalPayTypeSellDetail').value;
      bopt.numberPayTypeSellDetail=this.payTypeSellDetailForm.get('numberPayTypeSellDetail').value;
      bopt.datePayTypeSellDetail=new Date(this.payTypeSellDetailForm.get('datePayTypeSellDetail').value);
      bopt.payTypeIdTemp=idTemp;

      bopt.payTypeSellDetailBank=this.payTypeSellDetailForm.get('payTypeSellDetailBank').value;
      bopt.payTypeSellDetailObservations=this.payTypeSellDetailForm.get('payTypeSellDetailObservations').value;
      console.log('después de construir bopt esta es:', bopt);
      console.log('antes del splice, this.payTypeSellDetailsList es:',this.payTypeSellDetailsList)

      this.payTypeSellDetailsList.splice(this.positionCurrentPayTypeSellDetail,1,bopt);
      console.log('después del splice, this.payTypeSellDetailsList es:',this.payTypeSellDetailsList);
      console.log('this.positionCurrentPayTypeSellDetail es:',this.positionCurrentPayTypeSellDetail);
      this.currentPayTypeSellDetail=this.payTypeSellDetailsList[this.positionCurrentPayTypeSellDetail];
      console.log('En payTypeEdit this.positionCurrentPayTypeSellDetail es:', this.positionCurrentPayTypeSellDetail,
                  ' y  this.currentPayTypeSellDetail es:',  this.currentPayTypeSellDetail)


      this.fillPayTypeSellDetails();
      this.payTypeSellDetailTable.renderRows();

      console.log('En payTpeEdit pasa después de this.payTypeSellDetailTable.renderRows()');
      console.log('En payTpeEdit después de renderRows, this.payTypeSellDetailsList es:',this.payTypeSellDetailsList );


      this.payTypeSellDetailsIdTemp+=1;
      this.showPayTypeSellDetailNav=true;
      this.showLastPayTypeSellDetail=false;

    }

    subServiceTypeEdit():void
    {
      console.log('Entra a subServiceTypeEdit this.positionCurrentSubServiceDetail no es null,  positionCurrentSubServiceDetail es:', this.positionCurrentSubServiceDetail)
      console.log('sellDetails:', this.sellb.sellDetails);
      console.log('sellDetailsList:', this.sellDetailsList);
      console.log('subServiceDetailList:', this.subServiceDetailList);

      //console.log('sellDetailIdTemp es:', this.sellDetailIdTemp);

      // let idTemp= this.subServiceDetailList[this.positionCurrentSubServiceDetail].subServiceTypeIdTemp;
      let idTemp= this.subServiceDetailList[this.positionCurrentSubServiceDetail].sellDetailIdTemp;

      let bopt: BOSubServiceDetail= new BOSubServiceDetail();

      // bopt.payTypeId=this.payTypeSellDetailForm.get('payTypeId').value;
      // bopt.invoiceTypeId=this.payTypeSellDetailForm.get('invoiceTypeId').value;
      // bopt.price=this.payTypeSellDetailForm.get('totalPayTypeSellDetail').value;
      // bopt.numberPayTypeSellDetail=this.payTypeSellDetailForm.get('numberPayTypeSellDetail').value;
      // bopt.datePayTypeSellDetail=new Date(this.payTypeSellDetailForm.get('datePayTypeSellDetail').value);
      // bopt.payTypeIdTemp=idTemp;

      // bopt.payTypeSellDetailBank=this.payTypeSellDetailForm.get('payTypeSellDetailBank').value;
      // bopt.payTypeSellDetailObservations=this.payTypeSellDetailForm.get('payTypeSellDetailObservations').value;
      bopt.subServiceTypeId=this.subServiceDetailForm.get('subServiceTypeId').value;
      bopt.subServiceName=this.subServiceTypeList.find(x=>x.id==bopt.subServiceTypeId)?.name;
      bopt.subServiceGroupId=this.subServiceDetailForm.get('subServiceGroupId').value;
      bopt.subServiceGroupName=this.serviceGroupList.find(x=>x.id== bopt.subServiceGroupId)?.name;
      bopt.sellDetailId=this.sellDetailId;
      bopt.serviceTypeId=this.subServiceDetailForm.get('serviceTypeId').value;
      bopt.serviceName=this.serviceTypeList.find(x=>x.id==bopt.serviceTypeId)?.name;
      bopt.profesionalId=this.subServiceDetailForm.get('profesionalId').value;
      bopt.profesionalName=this.getProfesionalName(bopt.profesionalId);
      bopt.date=new Date(this.subServiceDetailForm.get('dateSubService').value);
      bopt.observations=this.subServiceDetailForm.get('observations').value;
      bopt.price=this.subServiceDetailForm.get('price').value;
      //bopt.subServiceDetailIdTemp=idTemp;
      bopt.sellDetailIdTemp=idTemp;


      // bopt.subServiceTypeIdTemp=idTemp;

      console.log('después de construir bopt esta es:', bopt);
      console.log('antes del splice, this.subServiceDetailList es:',this.subServiceDetailList);

     // this.payTypeSellDetailsList.splice(this.positionCurrentPayTypeSellDetail,1,bopt);
     this.subServiceDetailList.splice(this.positionCurrentSubServiceDetail,1,bopt);

      console.log('después del splice, this.subServiceDetailList es:',this.subServiceDetailList);
      console.log('this.positionCurrentSubServiceDetail es:',this.positionCurrentSubServiceDetail);
      this.currentSubServiceDetail=this.subServiceDetailList[this.positionCurrentSubServiceDetail];
      console.log('En payTypeEdit this.positionCurrentSubServiceDetail es:', this.positionCurrentSubServiceDetail,
                  ' y  this.currentSubServiceDetail es:',  this.currentSubServiceDetail)

      this.fillSubServiceDetails();
      //this.subServiceDetailTable.renderRows();


      // TODO
      // this.payTypeSellDetailTable.renderRows();


      console.log('En payTpeEdit pasa después de this.payTypeSellDetailTable.renderRows()');
      console.log('En payTpeEdit después de renderRows, this.payTypeSellDetailsList es:',this.payTypeSellDetailsList );

     // this.subServiceTypeDetailsIdTemp+=1;
      this.showSubServiceDetailNav=true;
      this.showLastSubServiceDetail=false;
     // this.subServiceDetailIdTemp+=1;

    }

   //#region navigation


    navNextPayTypeSellDetail():void
    {
      console.log('Entra al metodo navNextPayTypeSellDetail');

      if(this.positionCurrentPayTypeSellDetail >=0 && this.positionCurrentPayTypeSellDetail<= this.payTypeSellDetailsList.length-2)
      {
          this.currentPayTypeSellDetail=this.payTypeSellDetailsList[this.positionCurrentPayTypeSellDetail+1];
          this.positionCurrentPayTypeSellDetail+=1;
          this.fillPayTypeSellDetails();
          console.log('next currentSellDetail es:', this.currentSellDetail);
          console.log('next Después de incrementar positionCurrentSellDetail es:',this.positionCurrentSellDetail);
      }
     // this.fillPayTypeSellDetails();

    }

    navBackPayTypeSellDetail():void{
      if(this.positionCurrentPayTypeSellDetail > 0)
        {
          if(this.positionCurrentPayTypeSellDetail== this.payTypeSellDetailsList.length)
          {
              console.log('En navBackSellDetail this.positionCurrentPayTypeSellDetail== this.payTypeSellDetailsList.length');
              this.positionCurrentPayTypeSellDetail-=1;
          }
          this.positionCurrentPayTypeSellDetail-=1;
          this.currentPayTypeSellDetail=this.payTypeSellDetailsList[this.positionCurrentPayTypeSellDetail];

          console.log('back currentPayTypeSellDetail es:', this.currentPayTypeSellDetail);
          console.log('back Después de incrementar positionCurrentPayTypeSellDetail es:',this.positionCurrentPayTypeSellDetail);
        }
        this.fillPayTypeSellDetails();
    }

    navFirstPayTypeSellDetail():void{
      this.currentPayTypeSellDetail=this.payTypeSellDetailsList[0];
      this.positionCurrentPayTypeSellDetail=0;
      this.fillPayTypeSellDetails();
    }

    navLastPayTypeSellDetail():void{
      this.currentPayTypeSellDetail=this.payTypeSellDetailsList[this.payTypeSellDetailsList.length-1];
      this.positionCurrentPayTypeSellDetail=this.payTypeSellDetailsList.length-1;
      this.fillPayTypeSellDetails();
    }

    async navNextSubServiceDetail():Promise<void>
    {
      console.log('Entra al metodo  navNextSubServiceDetail');
      // console.log('this.subServiceDetailList:', this.subServiceDetailList);
      // console.log('this.positionCurrentSubServiceDetail:', this.positionCurrentSubServiceDetail);


      if(this.positionCurrentSubServiceDetail >=0 && this.positionCurrentSubServiceDetail<= this.subServiceDetailList.length-2)
      {
          this.currentSubServiceDetail=this.subServiceDetailList[this.positionCurrentSubServiceDetail+1];
          this.positionCurrentSubServiceDetail+=1;

          //
          // let sbSvcGroupId=this.currentSubServiceDetail.subServiceGroupId;
          // let sbSvcGroupIdString=this.convertNumberToString(sbSvcGroupId);

        //   this._serviceTypeService.getServicesByGroupService(sbSvcGroupIdString).subscribe(data=>{
        //     this.serviceTypeList=[];
        //     this.serviceTypeList=data;
        //     console.log('Al hacer next el nuevo this.serviceTypeList es:', this.serviceTypeList);
        //     this.subServiceDetailForm.patchValue({
        //       subServiceGroupId:this.currentSubServiceDetail.subServiceGroupId,
        //       serviceTypeId:this.currentSubServiceDetail.serviceTypeId,

        //     })

        //   });

        //   let svcId=this.currentSubServiceDetail.serviceTypeId;
        //   let svcIdString=this.convertNumberToString(svcId);
        //   this._subServiceType.getSubServicesByService(svcIdString).subscribe(data => {
        //   this.subServiceTypeList = [];
        //   this.subServiceTypeList = data;
        //   console.log('Al hacer next el nuevo this.subServiceTypeList es:', this.subServiceTypeList);
        //   this.subServiceDetailForm.patchValue({
        //     subServiceTypeId:this.currentSubServiceDetail.subServiceTypeId
        //   })

        // });

        let sbSvcGroupId=this.currentSubServiceDetail.subServiceGroupId;
        let sbSvcGroupIdString=this.convertNumberToString(sbSvcGroupId);
        this.serviceTypeListShort=Utilities.getServicesxGroupId(sbSvcGroupIdString, this.serviceTypeList);
       //  console.log('this.serviceTypeListShort es:', this.serviceTypeListShort);

       //  console.log('this.currentSubServiceDetail es:', this.currentSubServiceDetail);

        let svcId=this.currentSubServiceDetail.serviceTypeId;
         this.subServiceTypeListShort=Utilities.getSubServicesxServiceId(svcId, this.subServiceTypeList);

         this.subServiceDetailForm.patchValue({
           subServiceGroupId:this.currentSubServiceDetail.subServiceGroupId,
           serviceTypeId:this.currentSubServiceDetail.serviceTypeId,
           subServiceTypeId:this.currentSubServiceDetail.subServiceTypeId,
           counterSubServiceDetail:this.positionCurrentSubServiceDetail+1
         })

          //
       // this.fillSubServiceDetails();

          console.log('Al salir de navNextSubServiceDetail, this.serviceGroupList es:', this.serviceGroupList);
          console.log('Al salir de navNextSubServiceDetail, this.serviceTypeList es:', this.serviceTypeList);
          console.log('Al salir de navNextSubServiceDetail,  this.subServiceTypeList es:',  this.subServiceTypeList);
          console.log('Al salir de navNextSubServiceDetail, this.subServiceDetailList es:', this.subServiceDetailList);

          //this.refresh();

      }
     // this.fillPayTypeSellDetails();

    }

    navBackSubServiceDetail():void{
      console.log('En   navBackSubServiceDetail this.subServiceDetailList:', this.subServiceDetailList);

      if(this.positionCurrentSubServiceDetail > 0)
        {
          if(this.positionCurrentSubServiceDetail== this.subServiceDetailList.length)
          {
              console.log('En navBackSellDetail this.positionCurrentPayTypeSellDetail== this.payTypeSellDetailsList.length');
              this.positionCurrentSubServiceDetail-=1;
          }
          this.positionCurrentSubServiceDetail-=1;


          this.currentSubServiceDetail=this.subServiceDetailList[this.positionCurrentSubServiceDetail];
          console.log('this.positionCurrentSubServiceDetail backed:', this.positionCurrentSubServiceDetail);
          console.log('this.currentSubServiceDetail:', this.currentSubServiceDetail);


          // let sbSvcGroupId=this.currentSubServiceDetail.subServiceGroupId;
          // let sbSvcGroupIdString=this.convertNumberToString(sbSvcGroupId);

          // this._serviceTypeService.getServicesByGroupService(sbSvcGroupIdString).subscribe(data=>{
          //   this.serviceTypeList=[];
          //   this.serviceTypeList=data;
          //   console.log('Al hacer back el nuevo this.serviceTypeList es:', this.serviceTypeList);


            // this.subServiceDetailForm.patchValue({
            //   subServiceGroupId:this.currentSubServiceDetail.subServiceGroupId,
            //   serviceTypeId:this.currentSubServiceDetail.serviceTypeId
            // })

         // });

         let sbSvcGroupId=this.currentSubServiceDetail.subServiceGroupId;
         let sbSvcGroupIdString=this.convertNumberToString(sbSvcGroupId);
         this.serviceTypeListShort=Utilities.getServicesxGroupId(sbSvcGroupIdString, this.serviceTypeList);
        //  console.log('this.serviceTypeListShort es:', this.serviceTypeListShort);

        //  console.log('this.currentSubServiceDetail es:', this.currentSubServiceDetail);

         let svcId=this.currentSubServiceDetail.serviceTypeId;
          this.subServiceTypeListShort=Utilities.getSubServicesxServiceId(svcId, this.subServiceTypeList);

          this.subServiceDetailForm.patchValue({
            subServiceGroupId:this.currentSubServiceDetail.subServiceGroupId,
            serviceTypeId:this.currentSubServiceDetail.serviceTypeId,
            subServiceTypeId:this.currentSubServiceDetail.subServiceTypeId,
            counterSubServiceDetail:this.positionCurrentSubServiceDetail+1

          })
         // this.serviceTypeListShort=Utilities.getServicesxGroupId(sbSvcGroupIdString, this.serviceTypeList);

          // let svcId=this.currentSubServiceDetail.serviceTypeId;
          // let svcIdString=this.convertNumberToString(svcId);
          // this._subServiceType.getSubServicesByService(svcIdString).subscribe(data=>{
          //   this.subServiceTypeList=[];
          //   this.subServiceTypeList=data;
          //   console.log('Al hacer back el nuevo this.subServiceTypeList es:', this.subServiceTypeList);
          //   this.subServiceDetailForm.patchValue({
          //     subServiceTypeId:this.currentSubServiceDetail.subServiceTypeId
          //   })

          // });
        }
        console.log('Al salir de navBackSubServiceDetail, this.serviceGroupList es:', this.serviceGroupList);
        console.log('Al salir de navBackSubServiceDetail, this.serviceTypeList es:', this.serviceTypeList);
        console.log('Al salir de navBackSubServiceDetail,  this.subServiceTypeList es:',  this.subServiceTypeList);
        console.log('Al salir de navBackSubServiceDetail, this.subServiceDetailList es:', this.subServiceDetailList);


       // this.fillSubServiceDetails();

    }

    navFirstSubServicesDetail():void{
      console.log('En  navFirstSubServicesDetail this.subServiceDetailList:', this.subServiceDetailList);
      console.log('this.positionCurrentSubServiceDetail:', this.positionCurrentSubServiceDetail);

      this.currentSubServiceDetail=this.subServiceDetailList[0];
      console.log('this.currentSubServiceDetail:', this.currentSubServiceDetail);
      this.positionCurrentSubServiceDetail=0;
      this.fillSubServiceDetails();
     // this.refresh();

    }

    navLastSubServicesDetail():void{
      console.log('En navLastSubServicesDetail this.subServiceDetailList:', this.subServiceDetailList);
      this.currentSubServiceDetail=this.subServiceDetailList[0];

      this.currentSubServiceDetail=this.subServiceDetailList[this.subServiceDetailList.length-1];
      this.positionCurrentSubServiceDetail=this.subServiceDetailList.length-1;
      console.log('this.currentSubServiceDetail:', this.currentSubServiceDetail);

      this.fillSubServiceDetails();
      //this.refresh();

    }

    //#endregion navigation

    close(): void {
        this.clearGeneralShows();
        this.sellForm.reset();
        this.sellDetailsForm.reset();
        this.payTypeSellDetailForm.reset();
        this.subServiceDetailForm.reset();
        this.sellb=null;
        this.sellDetailsList=[];
        this.payTypeSellDetailsList=[];
        this.subServiceDetailList=[];
        this.modal.hide();
    }


    saveSell()
    {
      //prepare data
      let userIdTemp:string=this.sellForm.get('userId').value;
      let userNameTemp:string= this.sellForm.get('userName').value;
      let totalPayTypeSellDetails:number=0;

      console.log('Entra a saveSell. this.subServiceDetailList es:', this.subServiceDetailList);

      userNameTemp=userNameTemp.toUpperCase();

      if(this.sellDetailsList.length <=0 || this.payTypeSellDetailsList.length<=0)
      {
        this.toastr.error('Faltan Datos', 'Tipo de Servicio o Tipo de Pago necesitan llenar datos');
      }
      else
      {

        //elimina los id si existen
        this.sellDetailsList.forEach(x=>{
          x.id=undefined;
        });

        let totalSiigo:number=0;
        let totalCore:number=0;

        this.payTypeSellDetailsList.forEach(p=>{
          p.id=undefined;
          //console.log('p.price es:', p.price, ' y su tipo es :',typeof p.price);
          totalPayTypeSellDetails+=parseInt(p.price.toString());
          //identify numbers of siigo and core
          p.invoiceTypeId==1?totalSiigo+=1:totalCore+=1;
        });
        //identify invoiceType
        let invoiceTypeIdTemp:number=0;

        if(this.payTypeSellDetailsList.length==1)
        {
          if(this.payTypeSellDetailsList[0].invoiceTypeId==1)invoiceTypeIdTemp=1;
          else invoiceTypeIdTemp=2;
        }
        else
        {
          if(totalCore==0)invoiceTypeIdTemp=1
          if(totalSiigo==0)invoiceTypeIdTemp=2;
          if(totalSiigo>=1 && totalCore >=1)invoiceTypeIdTemp=3;
        }
        console.log('antes de llenar sellDetailsList con this.subServiceDetailList, este tiene:', this.subServiceDetailList.length);
        console.log('ANTES this.subServiceDetailList, este tiene:', this.subServiceDetailList);

        //procesa los subservices para incorporarlos a sellDetails
        let count =0;
        this.sellDetailsList.forEach(sd=>
          {
            sd.subServicesDetails=[];

            console.log('this.sellDetailsList sd es:', sd);

            this.subServiceDetailList.forEach(sb=>
              {
                console.log('this.subSellDetailsList sbservice es:', sb);

                if(sb.sellDetailIdTemp==sd.sellDetailIdTemp)
                  {
                    sb.id=undefined;
                    sd.subServicesDetails.push(sb);
                    count+=1;
                  }
              })

          })


        console.log('En saveSell this.sellDetailsList es:', this.sellDetailsList);
        console.log('En saveSell this.subServiceDetailList es:', this.subServiceDetailList);


        //
        this.sellb.userId=this.sellForm.get('userId').value;
        this.sellb.userName=userNameTemp;
        this.sellb.userEmail=this.sellForm.get('userEmail').value;
        this.sellb.dateSell=new Date(this.sellForm.get('dateSell').value);
        this.sellb.sellDetails=this.sellDetailsList;
        this.sellb.payTypeSellDetails=this.payTypeSellDetailsList;
        this.sellb.total=parseInt(this.sellForm.get('total').value);


        this.sellb.observations=this.sellForm.get('observations').value;
        this.sellb.invoiceTypeId= invoiceTypeIdTemp;

        console.log('En saveSell, sellb enviada es:', this.sellb);
        console.log('this.sellDetail es:', this.sellb.sellDetails);

        console.log('this.sell.total es:', this.sellb.total);
        console.log('this.totalPayTypeSellDeatils:', totalPayTypeSellDetails);


        if(totalPayTypeSellDetails==this.sellb.total)
        {
          console.log('En saveSell de sellService, sellb es:', this.sellb);
          this._sellService.saveSell(this.sellb).subscribe(x=>{
            console.log('El proceso ha dado por resultado:', x);
            this.toastr.success('Éxito en guardar','Se ha guardado la factura');
            this.close();
          })

        }
        else{
          this.toastr.error('Totales diferentes','La suma de los métodos de pago debe ser igual al total del servicio');
        }
      }

    }

    fillSell():void{
      this.sellForm.patchValue({
        documentNumber:this.currentSell.documentNumber,
        invoiceTypeList:this.currentSell.invoiceTypeId,
        total:this.currentSell.total,
        dateSell:this.currentSell.dateSell,
        userId:this.currentSell.userId,
        userName:this.currentSell.userName,
        userEmail:this.currentSell.userEmail,
        observations:this.currentSell.observations,
        sellDetails:this.currentSell.sellDetails,
        payTypeSellDetails:this.currentSell.payTypeSellDetails

      })
    }

    fillSellDetails():void
    {
      console.log('Entra a fillSellDetail. currentSellDetail es:',this.currentSellDetail, ' y this.positionCurrentSellDetail es:', this.positionCurrentSellDetail);
      if(!this.currentSellDetail)
      {
        this.sellDetailsForm.patchValue({
          profesionalId:null,
          serviceTypeId: null,
          price:null,
          counterSellDetail:null

        });
      }
      else{
        console.log('Entra al else para this.sellDetailsForm.patchValue');
        console.log(' currentSellDetail es:',this.currentSellDetail, );


        this.sellDetailsForm.patchValue({
          profesionalId:this.currentSellDetail.profesionalId,
          serviceTypeId: this.currentSellDetail.serviceTypeId,
          // profesionalId:2932,
          // serviceTypeId: '01',
          price:this.currentSellDetail.price,
          counterSellDetail:this.positionCurrentSellDetail+1
        });

        // this.sellDetailsForm.patchValue({
        //   profesionalId:this.currentSellDetail.profesionalId,
        //   serviceTypeId: this.currentSellDetail.serviceTypeId,
        //   price:this.currentSellDetail.price,
        //   counterSellDetail:this.positionCurrentSellDetail+1
        //   })
      }

    }

    fillSubServiceDetails():void
    {
      console.log('Entra a fillSubServiceDetail. currentSubServiceDetail es:',
      this.currentSubServiceDetail, ' y this.positionCurrentSubServiceDetail es:', this.positionCurrentSubServiceDetail);

      if(!this.currentSubServiceDetail)
      {
        this.subServiceDetailForm.patchValue({
          subServiceGroupId: null,
          subServiceTypeId:  null,
          serviceTypeId:     null,
          profesionalId:     null,
          dateSubService:    null,
          price:             null,
          counterSubServiceDetail:  null,
          totalSubServiceDetailList:null,
          observations:      null,
        })
      }
      else
      {
        console.log('verifica this.currentSubServiceDetail.subServiceGroup es:', this.currentSubServiceDetail.subServiceGroupId);
        console.log(' y El tipo recibido es: ', typeof this.currentSubServiceDetail.subServiceGroupId);
        console.log(' serviceGroupList es: ', this.serviceGroupList);
        let convertedGroupIdToString= this.convertNumberToString(this.currentSubServiceDetail.subServiceGroupId);
        console.log('convertedGroupIdToString es:', convertedGroupIdToString, ' y El tipo recibido es: ', typeof convertedGroupIdToString);
        let convertedServiceTypeId=this.convertNumberToString(this.currentSubServiceDetail.serviceTypeId);

        this.subServiceDetailForm.patchValue({
          subServiceGroupId:        this.currentSubServiceDetail.subServiceGroupId,
          subServiceTypeId:         this.currentSubServiceDetail.subServiceTypeId,
          serviceTypeId:            this.currentSubServiceDetail.serviceTypeId,
          profesionalId:            this.currentSubServiceDetail.profesionalId,
          dateSubService:           this.currentSubServiceDetail.date,
          price:                    this.currentSubServiceDetail.price,
          counterSubServiceDetail:  this.positionCurrentSubServiceDetail+1,
          totalSubServiceDetailList:this.subServiceDetailList.length,
          observations:             this.currentSubServiceDetail.observations,
        });

      }

    }

    fillPayTypeSellDetails():void
    {
      console.log('Entra a fillpayTypeSellDetail. currentPayTypeSellDetail es:',this.currentPayTypeSellDetail, ' y this.positionCurrentPayTypeSellDetail es:', this.positionCurrentPayTypeSellDetail);

      if(!this.currentPayTypeSellDetail)
      {
        this.payTypeSellDetailForm.patchValue({
          payTypeId:null,
          numberPayTypeSellDetail: null,
          datePayTypeSellDetail:null,
          totalPayTypeSellDetail:null,
          counterPayTypeSellDetail:null,
          totalPayTypeSellDetailList:null,
          payTypeSellDetailBank:null,
          payTypeSellDetailObservations:null
        })
      }
      else
      {
        this.payTypeSellDetailForm.patchValue({
          payTypeId:this.currentPayTypeSellDetail.payTypeId,
          invoiceTypeId:this.currentPayTypeSellDetail.invoiceTypeId,
          numberPayTypeSellDetail: this.currentPayTypeSellDetail.numberPayTypeSellDetail,
          datePayTypeSellDetail:this.currentPayTypeSellDetail.datePayTypeSellDetail,
          totalPayTypeSellDetail:this.currentPayTypeSellDetail.price,
          counterPayTypeSellDetail:this.positionCurrentPayTypeSellDetail+1,
          totalPayTypeSellDetailList:this.payTypeSellDetailsList.length,

          payTypeSellDetailBank:this.currentPayTypeSellDetail.payTypeSellDetailBank,
          payTypeSellDetailObservations:this.currentPayTypeSellDetail.payTypeSellDetailObservations,
        })

      }

    }


    addNewSellDetail():void{
      console.log('this.showSellDetailNav es:',this.showSellDetailNav,' y his.showLastSellDetail es:', this.showLastSellDetail)

        if(this.showSellDetailNav && !this.showLastSellDetail)
        {
          console.log('Entra a this.showSellDetailNav && !this.showLastSellDetail');
          if(this.sellDetailsList.length>0)
          {
            console.log('en addNewwSellDetail entra a this.sellDetailsList.length>0');
            this.positionCurrentSellDetail=this.sellDetailsList.length;
            this.showSellDetailNav=false;
            this.showLastSellDetail=true;
            this.clearSellDetails();

          }
          else{
            console.log('en addNewwSellDetail entra  al else y activa this.clearSellDetails');

            this.clearSellDetails();
            this.positionCurrentSellDetail=0;

            this.showSellDetailNav=true;
            this.showLastSellDetail=false;
          }
        }
        else if(!this.showSellDetailNav && this.showLastSellDetail)
        {
            console.log('Entra a !this.showSellDetailNav && this.showLastSellDetail');

            if(this.sellDetailsList.length>0){
              console.log('Entra a this.sellDetailsList.length>0 ');
              this.positionCurrentSellDetail=this.sellDetailsList.length-1;
              this.currentSellDetail=this.sellDetailsList[this.positionCurrentSellDetail];

              this.sellDetailsForm.patchValue({
                profesionalId:this.currentSellDetail.profesionalId,
                serviceTypeId: this.currentSellDetail.serviceTypeId,
                price:this.currentSellDetail.price,
                counterSellDetail:this.positionCurrentSellDetail+1
                })


              this.showSellDetailNav=true;
              this.showLastSellDetail=false;
            }
            else{
              console.log('Entra a this.sellDetailsList.length<=0 ');

              this.positionCurrentSellDetail=0;
              this.showSellDetailNav=false;
              this.showLastSellDetail=false;
              this.clearSellDetails();
            }
          }
          else{
            this.clearSellDetails();
          }

    }

    addNewPayTypeSellDetail():void
    {

      if(this.showPayTypeSellDetailNav && !this.showLastPayTypeSellDetail)
      {
          if(this.payTypeSellDetailsList.length>0)
          {
            this.positionCurrentPayTypeSellDetail=this.payTypeSellDetailsList.length;
            this.showPayTypeSellDetailNav=false;
            this.showLastPayTypeSellDetail=true;
            this.clearPayTypeSellDetails();
          }
          else{
            this.clearPayTypeSellDetails();
            this.positionCurrentPayTypeSellDetail=0;
            this.showPayTypeSellDetailNav=true;
            this.showLastPayTypeSellDetail=false;
          }

      }
      else if(!this.showPayTypeSellDetailNav && this.showLastPayTypeSellDetail)
      {
        if(this.payTypeSellDetailsList.length>0)
        {
          console.log('Entra a !this.showPayTypeSellDetailNav && this.showLastPayTypeSellDetail length>0');
          //Formatea fecha
         const opcions={weekday:'long', year:'numeric', month:'short'};

          this.positionCurrentPayTypeSellDetail=this.payTypeSellDetailsList.length-1;
          this.currentPayTypeSellDetail=this.payTypeSellDetailsList[this.positionCurrentPayTypeSellDetail];

          // let fmDate=(this.currentPayTypeSellDetail.datePayTypeSellDetail).toLocaleDateString();
          // console.log('datePayTypeSellDetail:fmDate es:', fmDate);
          //
          this.payTypeSellDetailForm.patchValue({
            payTypeId:this.currentPayTypeSellDetail.payTypeId,
            numberPayTypeSellDetail:this.currentPayTypeSellDetail.numberPayTypeSellDetail,
            datePayTypeSellDetail:this.currentPayTypeSellDetail.datePayTypeSellDetail,
            totalPayTypeSellDetail:this.currentPayTypeSellDetail.price,

            payTypeSellDetailBank:this.currentPayTypeSellDetail.payTypeSellDetailBank,
            payTypeSellDetailObservations:this.currentPayTypeSellDetail.payTypeSellDetailObservations,

          });

          this.showPayTypeSellDetailNav=true;
          this.showLastPayTypeSellDetail=false;

          console.log('this.showPayTypeSellDetailNav es:', this.showPayTypeSellDetailNav,
                      'this.showLastPayTypeSellDetail es:', this.showLastPayTypeSellDetail);
        }
        else{
          this.positionCurrentPayTypeSellDetail=0;
          this.showPayTypeSellDetailNav=true;
          this.showLastPayTypeSellDetail=false;
          this.clearPayTypeSellDetails();
        }
      }
      else
      {
        this.clearPayTypeSellDetails();
      }

    }

    addNewSubServiceDetail():void
    {

      if(this.showSubServiceDetailNav && !this.showLastSubServiceDetail)
      {
          if(this.subServiceDetailList.length>0)
          {
            this.positionCurrentSubServiceDetail=this.subServiceDetailList.length;
            this.showSubServiceDetailNav=false;
            this.showLastSubServiceDetail=true;
            this.clearSubServiceDetail();
          }
          else{
            this.clearSubServiceDetail();
            this.positionCurrentSubServiceDetail=0;
            this.showSubServiceDetailNav=false;
            this.showLastSubServiceDetail=true;
          }

      }
      else  if(!this.showSubServiceDetailNav && this.showLastSubServiceDetail)
      {
        if(this.subServiceDetailList.length>0)
          {
          console.log('Entra a !this.showPayTypeSellDetailNav && this.showLastPayTypeSellDetail length>0');
          //Formatea fecha
         const opcions={weekday:'long', year:'numeric', month:'short'};

         this.positionCurrentSubServiceDetail=this.subServiceDetailList.length-1;
          this.currentSubServiceDetail=this.subServiceDetailList[this.positionCurrentSubServiceDetail];

          this.showSubServiceDetailNav=true;
          this.showLastSubServiceDetail=false;
          //
          this.subServiceDetailForm.patchValue({
            subServiceGroupId:   this.currentSubServiceDetail.subServiceGroupId,
            subServiceTypeId:    this.currentSubServiceDetail.subServiceTypeId,
            serviceTypeId:       this.currentSubServiceDetail.serviceTypeId,
            profesionalId:       this.currentSubServiceDetail.profesionalId,
            dateSubService:      this.currentSubServiceDetail.date,
            observations:        this.currentSubServiceDetail.observations,
           // totalSubServiceDetailList:
          })
        }
        else{
          this.positionCurrentSubServiceDetail=0;
          this.showSubServiceDetailNav=true;
          this.showLastSubServiceDetail=false;
          this.clearSubServiceDetail();
        }
      }
      else
      {
        this.clearSubServiceDetail();
      }

    }

    clearSellDetails():void
    {
      //this.sellDetailsList=[];
      this.sellDetailsForm.patchValue({
        profesionalId:null,
        serviceTypeId: null,
        price:null,
      })

    }

    clearPayTypeSellDetails():void
    {
      this.payTypeSellDetailForm.patchValue({
        payTypeId:null,
        invoiceTypeId:null,
        numberPayTypeSellDetail: null,
        datePayTypeSellDetail: null,
        totalPayTypeSellDetail:null,
        counterPayTypeSellDetail:null,
        totalPayTypeSellDetailList:null,
        payTypeSellDetailBank:null,
        payTypeSellDetailObservations:null
      })

    }

    clearSubServiceDetail():void{

      this.subServiceDetailForm.patchValue({
        subServiceGroupId:   null,
        subServiceTypeId:    null,
        serviceTypeId:       null,
        profesionalId:       null,
        dateSubService:      null,
        price:                null,
        observations:        null,
        //counterSubServiceDetail:null,
       // totalSubServiceDetailList: null,
      })
    }

    clearGeneralShows():void{
      this.showSellNav                 =false;
      this.showSellDetailNav           =false;
      this.showLastSellDetail          =false;
      this.showPayTypeSellDetailNav    =false;
      this.showLastPayTypeSellDetail   =false;
    }

    ngOnDestroy(): void {
      this.busy?.unsubscribe();
      this.busyData$?.unsubscribe();
  }

    openSubServiceModalOriginal(sellDetailIdTemp: number):void
    {

      console.log('Entra a openSubServiceModal y sellDetailId es:', sellDetailIdTemp);

      //TODO verificar
      this.sellDetailIdTemp=sellDetailIdTemp;
     // this.sellDetailId=sellDetailId; //sellDetailIdTemp

     // this.dataLoad();
      this.showSubServiceDetailNav=true;
      this.createSubServiceModal.config = { backdrop: "static", keyboard: false };
      this.createSubServiceModal.show();
    }


    openSubServiceModal(sellDetailIdX: number, origen: number):void
    {
              //
              this.serviceTypeListShort=[];
              this.subServiceTypeListShort=[];
              //
      console.log('sellDetailIdX es_', sellDetailIdX, 'y sellb es:', this.sellb);
      if(origen==2) //Es editar, envía el número id del sellDetail
      {
        console.log('Entra a editar subService, sellDetailIdX es_', sellDetailIdX, 'y sellb es:', this.sellb);

        this.subServiceDetailList=[];

        let sdetails= this.sellb.sellDetails;

        sdetails.forEach(sd=>{
          let subService= sd.subServicesDetails;
          subService.forEach(sub=>{
            if(sd.id==sub.sellDetailId)
            {
              this.subServiceDetailList.push(sub);
            }
          })

        });

        let sdChoosed= sdetails.find(x=>x.id==sellDetailIdX);
        let subServiceSdChoosed= sdChoosed.subServicesDetails[0];

        // this.currentSubServiceDetail=this.subServiceDetailList[0];
        this.currentSubServiceDetail=subServiceSdChoosed;

        this.positionCurrentSubServiceDetail=0;

        // console.log('this.subServiceDetailList es:', this.subServiceDetailList);
        // console.log('this.currentSubServiceDetail es:', this.currentSubServiceDetail);
        // console.log('subSdChoosed es:', subServiceSdChoosed);

        // console.log('sdChoosed es:', sdChoosed);

        //
      // this.serviceTypeListCopy=[... this.serviceTypeList]; //copia this.serviceTypeList
       let sbSvcGroupId=this.currentSubServiceDetail.subServiceGroupId;
       let sbSvcGroupIdString=this.convertNumberToString(sbSvcGroupId);
       this.serviceTypeListShort=Utilities.getServicesxGroupId(sbSvcGroupIdString, this.serviceTypeList);
      //  console.log('this.serviceTypeListShort es:', this.serviceTypeListShort);

      //  console.log('this.currentSubServiceDetail es:', this.currentSubServiceDetail);

       let svcId=this.currentSubServiceDetail.serviceTypeId;
        this.subServiceTypeListShort=Utilities.getSubServicesxServiceId(svcId, this.subServiceTypeList);
       // console.log('this.subServiceDetailListShort es:', this.subServiceTypeListShort);

       //

       // let convertedGroupIdToString= this.convertNumberToString(this.currentSubServiceDetail.subServiceGroupId);

      this.subServiceDetailForm.patchValue({
        subServiceGroupId:        this.currentSubServiceDetail.subServiceGroupId,
        // subServiceGroupId:        convertedGroupIdToString,
        subServiceTypeId:         this.currentSubServiceDetail.subServiceTypeId,
        serviceTypeId:            this.currentSubServiceDetail.serviceTypeId,
        profesionalId:            this.currentSubServiceDetail.profesionalId,
        dateSubService:           this.currentSubServiceDetail.date,
        price:                    this.currentSubServiceDetail.price,
        counterSubServiceDetail:  this.subServiceDetailList.length> 0? 1: this.positionCurrentSubServiceDetail,
        totalSubServiceDetailList:this.subServiceDetailList.length,

        observations:             this.currentSubServiceDetail.observations,
      })

        this.showSubServiceDetailNav=true;
        this.createSubServiceModal.config = { backdrop: "static", keyboard: false };
        this.createSubServiceModal.show();


      }
      else{ //origen es una factura nueva sin número envia el sellDetailIdTemp


       //TODO Verificar si this.sellDetailIdTemp es this.sellDetailsIdTemp
        this.sellDetailIdTemp=sellDetailIdX;
         this.showSubServiceDetailNav=true;
         this.createSubServiceModal.config = { backdrop: "static", keyboard: false };
         this.createSubServiceModal.show();
      }

      console.log('Sale de openSubServiceModal y sellDetailId es:', sellDetailIdX);
    }


    closeSubServiceModal():void{
      this.createSubServiceModal.hide();
    }

    getNameFromProfesional(person:BOPerson):string
    {
     let result='';
      let sname=person.secondName;
      let sn='';
      sname.length>0?sn=sname:sn='';
      let name=person.firstName +' '+sn;

      let ssname= person.secondSurName;
      let secondsn='';
      ssname.length>0?secondsn=ssname: secondsn='';
      let lastname=person.firtsSurName +' '+secondsn;
      result= name+' '+lastname;
      return result;
    }
    // onOptionGroupSelected1(service: any)
    // {

    //  if (service != null)
    //   {
    //       this.serviceTypeList=[];
    //       this.busy = this._serviceTypeService.getServicesByGroupService(service)
    //       .subscribe(data => {
    //         this.serviceTypeList = data;
    //       });
    //   }
    // console.log('service enviado es:', service);
    //  let tempDat:BOItem[]=[];


    //
    // onOptionGroupSelected2(sended: any)
    // {
    //   console.log('service from onOptionGroupSelect es :', sended, ' y su tipo es:', typeof sended);

    //   let service='';

    //   if(typeof sended==='number')
    //     {
    //       console.log('Entra al if of typof number');
    //       service=this.convertNumberToString(sended);
    //     }
    //   else{
    //     console.log('Entra al else  of typof number');
    //     service=sended;
    //   }

    //   console.log('service envidado en onOptionGroupSelect es :', service);

    //  if (service != null)
    //   {
    //       this.serviceTypeList=[];
    //       this.busy = this._serviceTypeService.getServicesByGroupService(service)
    //       .subscribe(data => {
    //         this.serviceTypeList = data;
    //       });
    //   }
    // console.log('service enviado es:', service);
    //  let tempDat:BOItem[]=[];

    //  for(let i=0;i<this.serviceTypeList.length; i++)
    //   {
    //     if (this.serviceTypeList[i].serviceGroup==service)
    //       {
    //         tempDat.push(this.serviceTypeList[i]);
    //       }
    //   }
    //   this.serviceTypeList=tempDat;
    // }


    onOptionGroupSelected(sended: number)
    {
      console.log('service from onOptionGroupSelect es :', sended, ' y su tipo es:', typeof sended);

     let service='';
     service=this.convertNumberToString(sended);
     let tempDat:BOItem[]=[];

     for(let i=0;i<this.serviceTypeList.length; i++)
      {
        if (this.serviceTypeList[i].serviceGroup==service)
          {
            tempDat.push(this.serviceTypeList[i]);
          }
      }
      this.serviceTypeListShort=tempDat;
    }

  //   onOptionServiceTypeSelected(service: any)
  //   {
  //     console.log('service es:',service, ' y El tipo recibido es: ', typeof service);
  //     let converted= parseInt(service);
  //     console.log('converted es:', converted);
  //    if (service != null)
  //     {
  //         this.subServiceTypeList=[];
  //         this.busy = this._subServiceType.getSubServicesByService(service)
  //         .subscribe(data => {
  //           this.subServiceTypeList = data;
  //         });
  //     }
  //   console.log('service enviado es:', service);
  //    let tempDat:BOSubServiceType[]=[];
  //    for(let i=0;i<this.subServiceTypeList.length; i++)
  //     {
  //       if (this.subServiceTypeList[i].serviceTypeId==service)
  //         {
  //           tempDat.push(this.subServiceTypeList[i]);
  //         }
  //     }
  //     this.subServiceTypeList=tempDat;
  //     console.log('this.serviceTypeList es:', this.subServiceTypeList);
  // }

  onOptionServiceTypeSelected(sended: number)
  {
    console.log('service es :', sended, ' y su tipo es:', typeof sended);

   let tempDat:BOSubServiceType[]=[];
     for(let i=0;i<this.subServiceTypeList.length; i++)
      {
        if (this.subServiceTypeList[i].serviceTypeId==sended)
          {
            tempDat.push(this.subServiceTypeList[i]);
          }
      }
      this.subServiceTypeListShort=tempDat;
      console.log('this.serviceTypeListShort es:', this.subServiceTypeListShort);
  }

  convertNumberToString(target:number):string
  {
    console.log('Inicia convertNumberToString target es:', target);

    let converted='';

    if(target>=10)
    {
      converted=target.toString().trim();
    }
    else{
      console.log('Entra al else de convertNumberToString');
      converted='0'+target.toString().trim();
    }
    console.log('Sale d convertNumberToString converted es:', converted);

    return converted;
  }


  addDataToArray(item:any, array:any[] ):any
  {
    array=[...array, item];
    return array;
  }

  // refresh() {
  //   this._changeDetectorRef.detectChanges();
  // }


}
