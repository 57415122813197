import { BOPerson } from './BOPerson.model';
import { BOItem } from './BOItem.model';
export class BOSellDataSended  {
    userId? :number;
    userName?:string;
    userEmail?:string;
    profesionalId?:number;
    serviceTypelId?:number;
    dateSell?:Date;
    total?:number
    clinicHistoryId?:number;
    // profesionalList?:BOPerson[];
    // serviceTypeList?:BOItem[]
}
