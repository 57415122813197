import { BOUsers } from "./BOUsers.model";

export class BOGeneralValues{
    isActive?:boolean = true;
    isDeleted?:boolean = false;
    creationDate?:Date | undefined;
    deletionDate?:Date | undefined;
    modificationDate?:Date | undefined;
    creationUser?:BOUsers | undefined;
    deletionUser?:BOUsers | undefined;
    creationUserId?:number | undefined;
}