import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOLogin } from "../models/BOLogin.model";
import { BOUsers } from "../models/BOUsers.model";

@Injectable()
export class LoginService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/Login/';

    constructor(private http:HttpClient){}

    loginUser(loginData:BOLogin):Observable<BOUsers>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(loginData);
          return this.http.post<BOUsers>(this.myAppUrl + this.myApiUrl + 'loginUser',body,httpOptions);
    }
}