import { BOGeneralValues } from "./BOGeneralValues.model";

export class BOMedicalFormula extends BOGeneralValues{
    id:number;
    medicationName:string;
    presentation:string;
    concentration:string;
    dose:number;
    doseDescription:string;
    doseType:string;
    doseFrecuencyQuantity:number;
    doseFrecuencyQuantityDescription:string;
    doseFrecuency:string;
    doseDurationQuantity:number;
    doseDurationDescription:string;
    doseDuration:string;
    indications:string;
}