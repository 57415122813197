import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { BOInvoiceType } from '../../../models/BOInvoiceType.model';
import { InvoiceTypeService } from '../../../services/invoiceType.service';
import { CreateOrEditInvoiceTypeComponent } from './create-or-edit-invoice-type.component';

@Component({
  selector: 'invoiceType',
  templateUrl: './invoice-type.component.html',
  styleUrls: ['./invoice-type.component.css']
})
export class InvoiceTypeComponent implements OnInit, OnDestroy
{
  @ViewChild('createOrEditInvoiceTypeModal', {static:true}) createOrEditInvoiceTypeModal: CreateOrEditInvoiceTypeComponent;

  busy: Subscription | undefined;
 // invoiceTypeFilter:string="";

  invoiceTypeList:BOInvoiceType[]=[];

  //invoiceTypeList:BOInvoiceType[]=[];

  constructor(private _invoiceTypeService: InvoiceTypeService) { }

  ngOnInit(): void
  {

    this.busy = this._invoiceTypeService.getAll().subscribe(data => {
                this.invoiceTypeList = data; });  }

     openInvoicesList(){}

  openData():void{
    console.log('open Data');
  }

  testMe():void{
    console.log('Entra a testMe');
  }

  ngOnDestroy(): void {
    this.busy.unsubscribe();

    }
}
