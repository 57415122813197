import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOPersonOcupation } from "../models/BOPersonOcupation.model";

@Injectable()
export class PersonOcupationService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/PersonOcupation';

    constructor(private http:HttpClient){}

    getAll():Observable<BOPersonOcupation[]>{        
        return this.http.get<BOPersonOcupation[]>(this.myAppUrl + this.myApiUrl);
    }   
}