import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { BOInvoiceType } from "../../../models/BOInvoiceType.model";
import { InvoiceTypeService } from "../../../services/invoiceType.service";

@Component({
  selector: 'createOrEditInvoiceTypeModal',
  templateUrl: './create-or-edit-invoice-type.component.html',
  styleUrls: ['./create-or-edit-invoice-type.component.css']
})
export class CreateOrEditInvoiceTypeComponent implements  OnDestroy
{
//  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  @ViewChild('createOrEditInvoiceTypeModal', { static: true }) modal: ModalDirective;

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  busy: Subscription | undefined;
  origen:number=0;
  invoiceType: BOInvoiceType= new BOInvoiceType();

 //=
 createOrEditInvoiceTypeForm = this._formBuilder.group({
  nameInvoiceType: ['', Validators.required],
  active: ['', Validators.required],

  });

  constructor(public _toast:ToastrService,
              private _formBuilder:FormBuilder,
              private _invoiceTypeService: InvoiceTypeService
  ) { }

  openModal(origen:number, invoiceType?: BOInvoiceType): void
  {
    this.invoiceType=null;
    this.origen=origen;
    this.modal.config = { backdrop: "static", keyboard: false };
    this.modal.show();

    if (this.origen==2)
    {
      this.invoiceType=invoiceType;
      this.createOrEditInvoiceTypeForm.patchValue({
        nameInvoiceType:this.invoiceType.name,
        active:this.invoiceType.isActive
      })
    }

  }

  saveInvoiceType():void
  {
    console.log('Entra en saveInvoiceType');
    let invoiceTypeInput= new BOInvoiceType();

    if(this.origen==1){

      invoiceTypeInput={
        name:this.createOrEditInvoiceTypeForm.get('nameInvoiceType').value.toUpperCase().trim(),
        isActive:this.createOrEditInvoiceTypeForm.get('active').value=='01'?true:false
      };
    }

    else{
      invoiceTypeInput=this.invoiceType;
      invoiceTypeInput.isActive=this.createOrEditInvoiceTypeForm.get('active').value=='01'?true:false;
    }

    this.busy=this._invoiceTypeService.addOrUpdateInvoiceType(invoiceTypeInput).subscribe(()=>{
      this._toast.success('Éxito', 'Tipo Factura guardado');
      this.close();
      this.modal.hide();
    })

  }

  // editInvoiceType(origen:number, invoiceTypeInput:BOInvoiceType):void{
  //   console.log('Entra en editInvoiceType');
  //   this.origen=origen;

  //    this.busy=this._invoiceTypeService.addOrUpdateInvoiceType(invoiceTypeInput).subscribe(()=>{
  //      this._toast.success('Éxito', 'Tipo Factura guardado');
  //      this.close();
  //      this.modal.hide();
  //    })

  // }


  close(){
    this. createOrEditInvoiceTypeForm.reset();
    this.modal.hide();

  }
  ngOnDestroy(): void {
    this.busy?.unsubscribe();

  }





}
