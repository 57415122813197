import { BOClinicHistoryObservations } from "./BOClinicHistoryObservations.model";
import { BOGeneralValues } from "./BOGeneralValues.model";
import { BOHealthTechnologies } from "./BOHealthTechnologies.model";
import { BOHealthTechnologiesResult } from "./BOHealthTechnologiesResult.model";
import { BOMedicalFormula } from "./BOMedicalFormula.model";
import { BOMedicDiagnostic } from "./BOMedicDiagnostic.model";
import { BOPerson } from "./BOPerson.model";

export class BOClinicHistory extends BOGeneralValues{
    id:number;
    patient?:BOPerson;
    patientId:number;
    profesional?:BOPerson;
    profesionalId?:number | undefined;
    consultationDate?:Date | undefined;
    ipsId:number;
    healthTechnologyModality:string;
    serviceGroups:string;
    placeOfAttention:string;
    routeOfEntry:string;
    consultationCause:string;
    triageDate:Date;
    triageType:string;
    serviceType:string;
    servicePrice:number;
    procedureDescription:string;
    pathologicalRecord:string;
    consultationMotive:string;
    anamnesis:string;
    arterialPressure:string;
    temperature:string;
    pulse:string;
    weight:string;
    size:string;
    breathing:string;
    head:string;
    neck:string;
    chest:string;
    belly:string;
    pelvis:string;
    extremities:string;
    skin:string;
    centralNervousSystem:string;
    diagnosticImpression:string;
    laboratoryControl:string;
    treatmentPlan:string;
    serviceTypeName?:string;
    medicDiagnostic?:BOMedicDiagnostic;
    healthTechnologies?:BOHealthTechnologies;
    healthTechnologiesResult?:BOHealthTechnologiesResult;
    clinicHistoryObservations:BOClinicHistoryObservations[];
    medicalFormula:BOMedicalFormula[];
    controlConsultation?:number;
    area?:number;
}
