import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
//directives
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgBusyModule, BusyConfig } from 'ng-busy';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RouterModule } from '@angular/router';

import { MatButtonModule} from '@angular/material/button'



//Components
import { CreateOrEditMedicalFormulaComponent } from './clinicHistory/createOrEditMedicalFormula.component';
import { ClinicHistoryComponent } from './clinicHistory/clinicHistory.component';
import { CreateOrEditClinicHistoryComponent } from './clinicHistory/createOrEditClinicHistory.component';
import { ClinicHistoriesPatientComponent } from './clinicHistoriesPatient.component';
import { PatientComponent } from './patients.component';
import { CreateOrEditPatientComponent } from './createOrEditPatient.component';
import { ServiceTypeService } from '../services/service-type.service';
import { SellComponent } from './sell/sell.component';
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { MatTableModule} from '@angular/material/table'




@NgModule({
  declarations: [
    CreateOrEditClinicHistoryComponent,
    CreateOrEditMedicalFormulaComponent,
    ClinicHistoryComponent,
    ClinicHistoriesPatientComponent,
    PatientComponent,
    CreateOrEditPatientComponent,
    SellComponent,
    DialogConfirmComponent

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    NgBusyModule,
    MatStepperModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatDialogModule,
    NgSelectModule,
    NgxMatSelectSearchModule,
    NgbModule,
    MatExpansionModule,
    RouterModule,
    MatButtonModule,
    MatTableModule


  ],
  exports:[
    CreateOrEditClinicHistoryComponent,
    CreateOrEditMedicalFormulaComponent,
    ClinicHistoryComponent,
    SellComponent
  ],
  providers:[
    ServiceTypeService,
  ],
  //new 31-07-2024
 entryComponents:[DialogConfirmComponent]

})
export class SharedchModule { }
