import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOHeadQuarters } from "../models/BOHeadQuarters.model";

@Injectable()
export class HeadQuarterService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/HeadQuarters/';

    constructor(private http:HttpClient){}

    getHeadQuarters():Observable<BOHeadQuarters[]>{
        return this.http.get<BOHeadQuarters[]>(this.myAppUrl + this.myApiUrl + 'getAll');
    }

    getHeadQuartersActive():Observable<BOHeadQuarters[]>{
        return this.http.get<BOHeadQuarters[]>(this.myAppUrl + this.myApiUrl + 'getAllActives');
    }

    getHeadQuarter(headQuarterId:number):Observable<BOHeadQuarters>{
        return this.http.get<BOHeadQuarters>(this.myAppUrl + this.myApiUrl + 'getHeadQuarter/' + headQuarterId);
    }

    addOrUpdateHeadquarter(headQuarter:BOHeadQuarters):Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(headQuarter);
          return this.http.post<any>(this.myAppUrl + this.myApiUrl + 'addOrUpdateHeadquarter',body,httpOptions);
    }

    deleteHeadQuarter(headQuarterId:number):Observable<any>{
        return this.http.delete<any>(this.myAppUrl + this.myApiUrl + 'delete/' + headQuarterId);
    }
}