
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BOSell } from '../models/BOSell.model';
import { BOSellDetail } from '../models/BOSellDtail.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalConstants } from '../common/global-constants';
import { BOSellDataSended } from '../models/BOSellDataSended.model';
import { BOFilter } from '../models/BOFilter';
import { BODateRangeFilter } from '../models/BODateRangeFilter.model';
import { BOPayTypeSellDetail } from '../models/BOPayTypeSellDetail.model';

@Injectable({
  providedIn: 'root'
})
export class SellService
{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/sell/';
    dataSell:BOSellDataSended= new BOSellDataSended();


    dataSellBhSubject$= new BehaviorSubject<BOSellDataSended>(this.dataSell);

    constructor(private http:HttpClient){}



    setDataSellBhSubject(data:BOSellDataSended){
      this.dataSellBhSubject$.next(data);
    }

    getDataSellBhSubject()
    {
      return this.dataSellBhSubject$.asObservable();
    }

    saveSell(sell:BOSell):Observable<number>{
      const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        }
        const body=JSON.stringify(sell);
      //Ric
      console.log('body send to api is:', sell);
      console.log('body stringify  api is:', body);

      return this.http.post<number>(this.myAppUrl + this.myApiUrl+'saveSell',body,httpOptions);
      //end Ric
    }

    // getSell(sellDocumentNumber:string):Observable<BOSell>
    // {
    //   return this.http.get<BOSell>(this.myAppUrl + this.myApiUrl + 'getSell/'+sellDocumentNumber);
    // }

    getSell(sellId:number):Observable<BOSell>
    {
      //return this.http.get<BOSell>(this.myAppUrl + this.myApiUrl + 'getSell/'+sellDocumentNumber);
      return this.http.get<BOSell>(this.myAppUrl + this.myApiUrl + 'getSell/'+sellId);

    }

    getSells(filter:BOFilter):Observable<BOSell[]>
    {
      const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        }
        const body=JSON.stringify(filter);
      return this.http.post<BOSell[]>(this.myAppUrl + this.myApiUrl + 'getAll',body,httpOptions);
    }

    getSellByInvoiceTypeAndDates(filter:BODateRangeFilter):Observable<Array<Array<BOSell>>>
    {
      const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        }
        const body=JSON.stringify(filter);
      return this.http.post<Array<Array<BOSell>>>(this.myAppUrl + this.myApiUrl + 'getSellByInvoiceTypeAndDates',body,httpOptions);
    }

   // getPayTypeByDates(filter:BODateRangeFilter):Observable<Array<Array<BOPayTypeSellDetail>>>
    getPayTypeByDates(filter:BODateRangeFilter):Observable<BOPayTypeSellDetail[][]>
    {
      const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        }
        const body=JSON.stringify(filter);
        return this.http.post<BOPayTypeSellDetail[][]>(this.myAppUrl + this.myApiUrl + 'getPayTypeByDates',body,httpOptions);
    }

    getPayTypesSellDetailsByIdAndDates(datesFilter:BODateRangeFilter, payTypeId:number):Observable<BOPayTypeSellDetail[][]>{
      const httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'})
      }
      const body= JSON.stringify(datesFilter);
      return this.http.post<BOPayTypeSellDetail[][]>(this.myAppUrl + this.myApiUrl + 'getPayTypesSellDetailsByIdAndDates/'+payTypeId,body,httpOptions);
    }

}
