import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOItem } from "../models/BOItem.model";

@Injectable()
export class TriageTypeService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/TriageType';

    constructor(private http:HttpClient){}

    getAll():Observable<BOItem[]>{
        return this.http.get<BOItem[]>(this.myAppUrl + this.myApiUrl);
    }
}