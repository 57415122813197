import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOPatientInformation } from "../models/BOPatientInformation.model";

@Injectable()
export class PatientInformationService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/PatientInformation/';

    constructor(private http:HttpClient){}

    addOrUpdatePatientInformation(patientInformation:BOPatientInformation):Observable<number>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(patientInformation);
        return this.http.post<number>(this.myAppUrl + this.myApiUrl + 'addOrUpdatePatientInformation',body,httpOptions);
    }

    getPatientInformation(patientInformationId:number):Observable<BOPatientInformation>{
        return this.http.get<BOPatientInformation>(this.myAppUrl + this.myApiUrl + 'getPatientInformation/' + patientInformationId);
    }
}