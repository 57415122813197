import { BOSubServiceDetail } from './../models/BOSubServiceDetail.model';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";



@Injectable({
  providedIn: 'root'
})
export class SubServiceDetailService {

  myAppUrl = GlobalConstants.apiURL;
  myApiUrl = 'api/SubServiceDetail';

  constructor(private http:HttpClient){}

  getAll():Observable<BOSubServiceDetail[]>{
      return this.http.get<BOSubServiceDetail[]>(this.myAppUrl + this.myApiUrl);
  }

  getSubServicesDetailsBySellDetailId(subService:number ):Observable<BOSubServiceDetail[]>{
    return this.http.get<BOSubServiceDetail[]>(this.myAppUrl + this.myApiUrl + '/'+subService);
  }


}
