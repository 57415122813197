import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOItem } from "../models/BOItem.model";
import { BOInvoiceType } from "../models/BOInvoiceType.model";

@Injectable({
  providedIn: 'root'
})
export class InvoiceTypeService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/InvoiceType/';

    constructor(private http:HttpClient){}

    getAll():Observable<BOInvoiceType[]>{
        return this.http.get<BOInvoiceType[]>(this.myAppUrl + this.myApiUrl);
    }

    addOrUpdateInvoiceType(invoiceType:BOInvoiceType):Observable<number>{
      const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        }
        const body=JSON.stringify(invoiceType);
        return this.http.post<any>(this.myAppUrl + this.myApiUrl + 'addOrUpdateInvoiceType',body,httpOptions);
  }
}


// addOrUpdatePerson(person:BOPerson):Observable<any>{
//   const httpOptions = {
//       headers: new HttpHeaders({'Content-Type': 'application/json'})
//     }
//     const body=JSON.stringify(person);
//     return this.http.post<any>(this.myAppUrl + this.myApiUrl + 'addOrUpdatePerson',body,httpOptions);
// }
