import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoService {
    private key = CryptoJS.enc.Utf8.parse('ytj6YytqrmdCfVTE');
    private iv = CryptoJS.enc.Utf8.parse('7ZJVvkOb3m5u9Ggz');

    encryptUsingAES256(encString:string) {
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(encString)), this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        //console.log('Encrypted :' + encrypted);
        //this.decryptUsingAES256(encrypted);
        return encrypted;
    }
    
    decryptUsingAES256(decString:string) {
        var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        //console.log('Decrypted : ' + decrypted);
        //console.log('utf8 = ' + decrypted.toString(CryptoJS.enc.Utf8));
        return decrypted;   
    }
}