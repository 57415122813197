import { Injectable } from '@angular/core';
import { HttpRequest,  HttpHandler,  HttpEvent,  HttpInterceptor, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { finalize, tap } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class SpinnerInterceptor implements HttpInterceptor
{

  constructor(private route:Router, private _spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {

    this._spinnerService.show();
    // const token=localStorage.getItem("token");
    // let req=request;

    // if(token)
    // {

    //   req=request.clone({
    //     setHeaders:{
    //       authorization: `Bearer ${token}`
    //     }
    //   })
    // }

     return next.handle(req).pipe(
      finalize(()=>this._spinnerService.hide()));
  }

}

export const spinnerInterceptorProvider={
  provide: HTTP_INTERCEPTORS,
  useClass:SpinnerInterceptor,
  multi:true
};
