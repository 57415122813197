import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClinicGuard implements CanActivate {


  constructor(private route:Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token= localStorage.getItem('token');
      if(!token){
        this.route.navigate(['']);
        return false;
        //( commands:['./','clinicHistory']);
      }
      else{
        return true;
      }    
  }
  
}
