import { BOGeneralValues } from "./BOGeneralValues.model";
import { BOPayTypeSellDetail } from "./BOPayTypeSellDetail.model";
import { BOSellDetail } from "./BOSellDtail.model";

export class BOSell extends BOGeneralValues{


    id?:number;
    documentNumber?:string;
    invoiceTypeId?:number;
    invoiceTypeName?:string;
    total?:number;
    dateSell?:Date | undefined;
    userId? :number;
    userName?:string;
    userEmail?:string;
    observations?:string;
    clinicHistoryId?:number;
    sellDetails?:BOSellDetail[]=[];
    payTypeSellDetails?:BOPayTypeSellDetail[]=[];
    sellIdTemp?:number;
}
