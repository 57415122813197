import { Component, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { BOFilter } from "../models/BOFilter";
import { BOPerson } from "../models/BOPerson.model";
import { PersonService } from "../services/person.service";
import { ClinicHistoriesPatientComponent } from "./clinicHistoriesPatient.component";
import { CreateOrEditPatientComponent } from "./createOrEditPatient.component";

@Component({
    templateUrl: './patients.component.html'
})
export class PatientComponent implements OnDestroy {
    @ViewChild('createOrEditPatientModal', { static: true }) createOrEditPatientModal: CreateOrEditPatientComponent;
    @ViewChild('clinicHistoriesPatientModal', { static: true }) clinicHistoriesPatientModal: ClinicHistoriesPatientComponent;
    patientList: BOPerson[];
    busy: Subscription;
    isClients: boolean;
    patientfilter:string = "";

    constructor(private _personService: PersonService, public toastr: ToastrService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            console.log(params["isClient"]);
            const filterInput: BOFilter = {
                filter: this.patientfilter
            }
            if (params["isClient"]) {
                this.isClients = params["isClient"] == 1 ? true : false;
                console.log(this.isClients);
                if (this.isClients) {
                    this.busy = this._personService.getPatients(filterInput).subscribe(data => { this.patientList = data });
                }
                else {
                    this.busy = this._personService.getFuturePatients().subscribe(data => { this.patientList = data });
                }
            }
            else {
                this.busy = this._personService.getPatients(filterInput).subscribe(data => { this.patientList = data });
            }
        });
    }

    deletePatient(patientId: number) {
        if (confirm('Are you sure you want to delete this Patient?')) {
            this.busy = this._personService.deletePerson(patientId).subscribe(() => {
                this.toastr.warning('Patient Deleted');
                if (this.isClients) {
                    const filterInput: BOFilter = {
                        filter: this.patientfilter
                    }
                    this.busy = this._personService.getPatients(filterInput).subscribe(data => { this.patientList = data });
                }
                else {
                    this.busy = this._personService.getFuturePatients().subscribe(data => { this.patientList = data });
                }
            });
        }
    }

    getAllPatients(): void {
        if (this.isClients) {
            const filterInput: BOFilter = {
                filter: this.patientfilter.toUpperCase().trim()
            }
            this.busy = this._personService.getPatients(filterInput).subscribe(data => { this.patientList = data });
        }
        else {
            this.busy = this._personService.getFuturePatients().subscribe(data => { this.patientList = data });
        }
    }

    ngOnDestroy(): void {
        this.busy?.unsubscribe();
    }
}