import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//components
//import { ClinicHistoriesPatientComponent } from './patients/clinicHistoriesPatient.component';
//import { CreateOrEditPatientComponent } from './patients/createOrEditPatient.component';
//import { PatientComponent } from './patients/patients.component';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgBusyModule } from 'ng-busy';
//import { SharedchModule } from '../sharedch/sharedch.module';

@NgModule({
  declarations: [
    //ClinicHistoriesPatientComponent, 
   // CreateOrEditPatientComponent,
    //PatientComponent
  ],
  imports: [
    CommonModule, 
    RouterModule, 
    ModalModule.forRoot(),
    NgBusyModule,
    //SharedchModule,
    FormsModule,
    ReactiveFormsModule
  ], 
  exports:[
    // ClinicHistoriesPatientComponent, 
    // CreateOrEditPatientComponent,
    // PatientComponent
  ]
  
})
export class SharedModule { }
