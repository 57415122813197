import { BOAdvancedDirectiveDocument } from "./BOAdvancedDirectiveDocument.model";
import { BOGeneralValues } from "./BOGeneralValues.model";
import { BOOrganDonor } from "./BOOrganDonor.model";

export class BOPatientInformation extends BOGeneralValues{
    id:number;
    sexType?:string;
    genderIdentity?:string;
    disabilityCategory?:string;
    officeNumber?:string;
    epsId?:number;
    personOcupationId?:number;
    birthCountryId?:number;
    residenceCountryId?:number;
    residenceCityId?:number;
    ethnicity?:string;
    ethnicCommunity?:string;
    residenceZone?:string;
    organDonor?:BOOrganDonor;
    advancedDirectiveDocument?:BOAdvancedDirectiveDocument;
}