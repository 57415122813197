import { BOComplicationMedicDiagnostic } from "./BOComplicationMedicDiagnostic.model";
import { BODeathMedicDiagnostic } from "./BODeathMedicDiagnostic.model";
import { BOGeneralValues } from "./BOGeneralValues.model";
import { BOHealthTechnologyResult } from "./BOHealthTechnologyResult.model";
import { BOIps } from "./BOIps.model";
import { BOPrincipalMedicDiagnostic } from "./BOPrincipalMedicDiagnostic.model";
import { BORelatedMedicDiagnostic } from "./BORelatedMedicDiagnostic.model";

export class BOHealthTechnologiesResult extends BOGeneralValues{
    id:number;   
    principalMedicDiagnostic:BOPrincipalMedicDiagnostic;
    relatedMedicDiagnostic:BORelatedMedicDiagnostic;
    healthTechnologyResult:BOHealthTechnologyResult;
    complicationMedicDiagnostic:BOComplicationMedicDiagnostic;
    deathMedicDiagnostic:BODeathMedicDiagnostic;
    patientCondition:string;
    medicalAttentionFinalizationDate:Date | undefined;
    referedIps:BOIps;
    medicIncapacityType:string;
    medicIncapacityDays:number;
    medicMaternityLicense:number;   
}