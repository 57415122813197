//Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//npm
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import {NgBusyModule, BusyConfig} from 'ng-busy';
import {MatStepperModule} from '@angular/material/stepper';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import {MatDialogModule} from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatExpansionModule} from '@angular/material/expansion';

//Components
//import {ClinicHistoryComponent} from '../clinicAdmins/clinicHistory/clinicHistory.component'
//import {CreateOrEditClinicHistoryComponent} from '../clinicAdmins/clinicHistory/createOrEditClinicHistory.component'
//import { CreateOrEditMedicalFormulaComponent } from './clinicHistory/createOrEditMedicalFormula.component';
//import { ClinicHistoriesPatientComponent } from '../../sharedch/clinicHistoriesPatient.component';

//import { ClinicHistoriesPatientComponent } from '../admins/patients/clinicHistoriesPatient.component' ;

// Admins Routing
import { ClinicAdminsRouterModule } from './clinicAdmins-router.module';

//Services
import {BiologicalSexService} from '../../services/biologicalSex.service';
import {CityService} from '../../services/city.service';
import {ConsultationCauseService} from '../../services/consultationCause.service';
import {CountryService} from '../../services/country.service';
import {DiagnosticTypeService} from '../../services/diagnosticType.service';
import {DisabilityCategoryService} from '../../services/disabilityCategory.service';
import {EpsService} from '../../services/eps.service';
import {EthnicCommunityService} from '../../services/ethnicCommunity.service';
import {EthnicityService} from '../../services/ethnicity.service';
import {GenderIdentityService} from '../../services/genderIdentity.service';
import {IdTypeService} from '../../services/idType.service';
import {IpsService} from '../../services/ips.service';
import {PersonOcupationService} from '../../services/personOcupation.service';
import {ResidenceZoneService} from '../../services/residenceZone.service';
import {StateService} from '../../services/state.service';
import {PersonService} from '../../services/person.service';
import {ClinicHistoryService} from '../../services/clinicHistory.service';
import { HealthTecnologyModalityService } from '../../services/healthTecnologyModality.service';
import { ServiceGroupsService } from '../../services/serviceGroups.service';
import { RouteOfEntryService } from '../../services/routeOfEntry.service';
import { PlaceOfAttentionService } from '../../services/placeOfAttention.service';
import { TriageTypeService } from '../../services/triageType.service';
import { PatientInformationService } from '../../services/patientInformation.service';
import { HealthTechnologyFinalityService } from '../../services/healthTechnologyFinality.service';
import { HealthTechnologyTimeUnitService } from '../../services/HealthTechnologyTimeUnit.service';
import { HealthTechnologyService } from '../../services/healthTechnology.service';
import { PatientConditionService } from '../../services/patientCondition.service';
import { DiseaseService } from '../../services/disease.service';
import { OrphanDiseaseService } from '../../services/orphanDisease.service';
import { CompanyService } from '../../services/company.service';
import { HeadQuarterService } from '../../services/headQuarter.service';
//Shared
import { SharedchModule } from '../../sharedch/sharedch.module';
import { SharedModule } from '../../shared/shared.module';
import { InvoicesComponent } from './invoices/invoices.component';
import { CreateOrEditInvoiceTypeComponent } from './invoices/create-or-edit-invoice-type.component';
import { InvoiceTypeComponent } from './invoices/invoice-type.component';

@NgModule({
    imports: [
      CommonModule,
      ClinicAdminsRouterModule,
      ModalModule.forRoot(),
      ReactiveFormsModule,
      FormsModule,
      ToastrModule.forRoot(),
      NgBusyModule,
      MatStepperModule,
      MatInputModule,
      MatFormFieldModule,
      MatSelectModule,
      MatIconModule,
      MatDatepickerModule,
      MatNativeDateModule,
      NgxMatDatetimePickerModule,
      NgxMatNativeDateModule,
      MatDialogModule,
      NgSelectModule,
      NgxMatSelectSearchModule,
      NgbModule,
      MatExpansionModule,
      SharedModule,
      SharedchModule

    ],
    declarations: [
    InvoicesComponent,
    CreateOrEditInvoiceTypeComponent,
    InvoiceTypeComponent
  ],
    providers:[
        ToastrService,
        BiologicalSexService,
        CityService,
        ConsultationCauseService,
        CountryService,
        DiagnosticTypeService,
        DisabilityCategoryService,
        EpsService,
        EthnicCommunityService,
        EthnicityService,
        GenderIdentityService,
        IdTypeService,
        IpsService,
        PersonOcupationService,
        ResidenceZoneService,
        StateService,
        PersonService,
        ClinicHistoryService,
        HealthTecnologyModalityService,
        ServiceGroupsService,
        PlaceOfAttentionService,
        RouteOfEntryService,
        TriageTypeService,
        PatientInformationService,
        HealthTechnologyFinalityService,
        HealthTechnologyTimeUnitService,
        HealthTechnologyService,
        PatientConditionService,
        DiseaseService,
        OrphanDiseaseService,
        CompanyService,
        HeadQuarterService,
        { provide: MAT_DATE_LOCALE, useValue: 'es-Co' }
    ],
    exports:[
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatSelectSearchModule
    ]
})


export class ClinicAdminsModule { }
