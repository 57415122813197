import { BOGeneralValues } from "./BOGeneralValues.model";
import { BOHealthTechnologyFrecuency } from "./BOHealthTechnologyFrecuency.model";
import { BOHealthTechnologyMedicamentDoseDelivered } from "./BOHealthTechnologyMedicamentDoseDelivered.model";
import { BOHealthTechnologyMedicamentDoseTaken } from "./BOHealthTechnologyMedicamentDoseTaken.model";
import { BOHealthTechnologyTerm } from "./BOHealthTechnologyTerm.model";
import { BOHealthTecnologyDeliveredUnits } from "./BOHealthTecnologyDeliveredUnits.model";
import { BOPrescriptionMedicationDose } from "./BOPrescriptionMedicationDose.model";

export class BOHealthTechnologies extends BOGeneralValues{
    id:number;
    healthTechnology:string;
    healthTecnologyFinality:string;
    healthTecnologyMedicamentDescription:string;
    prescriptionDate?:Date | undefined;
    prescriptionMedicationDose:BOPrescriptionMedicationDose;
    healthTechnologyVad:string;
    healthTechnologyTerm:BOHealthTechnologyTerm;
    healthTechnologyFrecuency:BOHealthTechnologyFrecuency;
    healthTechnologyMedicamentDoseTaken:BOHealthTechnologyMedicamentDoseTaken;
    healthTecnologyDeliveredDate?:Date | undefined;
    healthTechnologyMedicamentDoseDelivered:BOHealthTechnologyMedicamentDoseDelivered;
    healthTecnologyDeliveredUnits:BOHealthTecnologyDeliveredUnits;    
}