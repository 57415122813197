import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOEps } from "../models/BOEps.model";

@Injectable()
export class EpsService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/Eps';

    constructor(private http:HttpClient){}

    getAll():Observable<BOEps[]>{        
        return this.http.get<BOEps[]>(this.myAppUrl + this.myApiUrl);
    }   
}