import { Component, OnDestroy, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription, forkJoin } from "rxjs";
import { BOClinicHistory } from "../../models/BOClinicHistory.model";
import { BOFilter } from "../../models/BOFilter";
import { ClinicHistoryService } from "../../services/clinicHistory.service";
//import { ClinicHistoriesPatientComponent } from "../../shared/patients/clinicHistoriesPatient.component";
import { ClinicHistoriesPatientComponent } from "../clinicHistoriesPatient.component";
import { CreateOrEditClinicHistoryComponent } from "./createOrEditClinicHistory.component";
//Ric
import { Router } from "@angular/router";
import { ServiceTypeService } from '../../services/service-type.service';
import { BOItem } from "../../models/BOItem.model";
//Ric end
@Component({
    templateUrl: './clinicHistory.component.html',
    styleUrls: ['./clinicHistory.component.css'],

})
export class ClinicHistoryComponent implements OnDestroy{
    @ViewChild('createOrEditClinicHistoryModal', {static:true}) createOrEditClinicHistoryModal: CreateOrEditClinicHistoryComponent;
    @ViewChild('clinicHistoriesPatientModal', { static: true }) clinicHistoriesPatientModal: ClinicHistoriesPatientComponent;
    clinicHistoryList: BOClinicHistory[];
    busy:Subscription;
    clinicHistoryFilter:string = "";
    data=true;


    serviceTypeList: BOItem[] = [];

    constructor(public toastr: ToastrService,
                private _clinicHistoryService:ClinicHistoryService,
                private router:Router,
                private _serviceTypeService:ServiceTypeService) { }

    ngOnInit(): void {
        const filterInput: BOFilter = {
            filter: this.clinicHistoryFilter
        }
       // this.busy = this._clinicHistoryService.getClinicHistories(filterInput).subscribe(data=>{this.clinicHistoryList = data});
       this.busy=forkJoin(
       {
        resultClinicHistory:this._clinicHistoryService.getClinicHistories(filterInput),
        resultServiceTypeService:this._serviceTypeService.getAll(),
       }).subscribe(({resultClinicHistory, resultServiceTypeService})=>{
        this.clinicHistoryList=resultClinicHistory,
        this.serviceTypeList=resultServiceTypeService,
        console.log('clinicHistory es:', this.clinicHistoryList);

        if(this.clinicHistoryList.length>0){

          for(let i=0; i<this.clinicHistoryList.length; i++)
          {
            let serviceType=this.clinicHistoryList[i].serviceType;
            this.clinicHistoryList[i].serviceTypeName= this.serviceTypeList.find(x=>x.code==serviceType)?.name;
          }
        }

       })

      }

    getAllClinicHistories()
    {
        this.clinicHistoryList=[];
        const filterInput: BOFilter = {
            filter: this.clinicHistoryFilter.toUpperCase().trim()
        }
        //console.log(filterInput.filter);
        this.busy = this._clinicHistoryService.getClinicHistories(filterInput)
        .subscribe(data=>{this.clinicHistoryList = data;
             //Ric
            if(!data.length) this.clinicHistoryFilter= "";

            if(this.clinicHistoryList.length>0){

              for(let i=0; i<this.clinicHistoryList.length; i++)
              {
                let serviceType=this.clinicHistoryList[i].serviceType;
                this.clinicHistoryList[i].serviceTypeName= this.serviceTypeList.find(x=>x.code==serviceType)?.name;
              }
            }

        });

    }






    ngOnDestroy(): void {
        this.busy?.unsubscribe();
    }
}
