import { BOGeneralValues } from "./BOGeneralValues.model";

export class BOPayTypeSellDetail {
    id:number;
    sellId?:number;
    payTypeId?:number;
    payTypeName?:string;
    payTypeIdTemp?:number;
    datePayTypeSellDetail?:Date | undefined;
    numberPayTypeSellDetail?:number=0;
    userName?:string;
    userId?:number;
    payTypeSellDetailBank?:string;
    payTypeSellDetailObservations?:string;
    invoiceTypeId?:number;
    invoiceTypeName?:string;
    price:number=0;
}
