import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOCompany } from "../models/BOCompany.model";

@Injectable()
export class CompanyService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/Company/';

    constructor(private http:HttpClient){}

    getCompany():Observable<BOCompany>{
        return this.http.get<BOCompany>(this.myAppUrl + this.myApiUrl + 'getCompany');
    }

    updateCompany(company:BOCompany):Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(company);
          return this.http.post<any>(this.myAppUrl + this.myApiUrl + 'updateCompany',body,httpOptions);
    }
}