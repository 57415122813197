

export class BOSubServiceDetail{
  id?:number;
  subServiceTypeId?: number;
  subServiceTypeIdTemp?: number;

  subServiceName?:string;

  subServiceDetailIdTemp?: number;

  subServiceGroupId?:number;
  subServiceGroupName?:string;
  sellDetailId?: number;
  sellDetailIdTemp?:number;

  serviceTypeId?:number;
  serviceName?:string;
  profesionalId?:number;
  profesionalName?:string;
  date?:Date;
  observations?:string;
  price?:number;
  itemSaved?:boolean;
  //idTemp?:number;
}
