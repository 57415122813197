import { Component, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { BOItem } from "../models/BOItem.model";
import { BOPerson } from "../models/BOPerson.model";
import { IdTypeService } from "../services/idType.service";
import { PersonService } from "../services/person.service";

@Component({
    selector: 'createOrEditPatientModal',
    templateUrl: './createOrEditPatient.component.html'
})

export class CreateOrEditPatientComponent implements OnDestroy {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    busy: Subscription | undefined;
    submitted = false;
    isClient = false;

    createOrEditPatientForm = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
        secondCtrl: '',
        threeCtrl: ['', Validators.required],
        fourCtrl: '',
        fifthCtrl: '',
        sixCtrl: '',
        sevenCtrl: ['', Validators.required],
        eightCtrl: ['', Validators.required],
        nineCtrl: '',
        tenCtrl: '',
        elevenCtrl: ''
    });

    idTypeList: BOItem[] = [];
    patient: BOPerson = new BOPerson();

    constructor(public toastr: ToastrService, private _formBuilder: FormBuilder, private _idTypeService: IdTypeService,
        private _patientService: PersonService) { }

    openModal(employeeId: number, isClient: boolean): void {
        this.modal.config = { backdrop: "static", keyboard: false };
        this.isClient = isClient;
        this.modal.show();
        this.busy = this._idTypeService.getAll().subscribe(data=> {
            this.idTypeList = data;
            if(employeeId > 0){
                this._patientService.getPerson(employeeId).subscribe(persondata => {
                    this.patient = persondata;
                    this.createOrEditPatientForm.patchValue({
                        firstCtrl: this.patient.firstName,
                        secondCtrl: this.patient.secondName,
                        threeCtrl: this.patient.firtsSurName,
                        fourCtrl: this.patient.secondSurName,
                        fifthCtrl: this.idTypeList.find(x => x.code == this.patient.idType)?.code,
                        sixCtrl: this.patient.personId,
                        sevenCtrl: this.patient.email,
                        nineCtrl: this.patient.address,
                        eightCtrl: this.patient.cellPhoneNumber,
                        tenCtrl: this.patient.emergencyContact,
                        elevenCtrl: this.patient.birthDate.toString().split('T')[0]
                    });
                });
            }
            else {
                this.patient.id = 0;
            }
        });
    }
  //  toUpperCase().trim();
    savePatient(): void {
        const employeeInput: BOPerson = {
            id: this.patient.id,
           // firstName: this.createOrEditPatientForm.get('firstCtrl')!.value,
            firstName: this.createOrEditPatientForm.get('firstCtrl')!.value.toUpperCase().trim(),
            secondName: this.createOrEditPatientForm.get('secondCtrl')!.value.toUpperCase().trim(),
            firtsSurName: this.createOrEditPatientForm.get('threeCtrl')!.value.toUpperCase().trim(),
            secondSurName: this.createOrEditPatientForm.get('fourCtrl')!.value.toUpperCase().trim(),
            idType: this.createOrEditPatientForm.get('fifthCtrl')!.value,
            personId: this.createOrEditPatientForm.get('sixCtrl')!.value,
            email: this.createOrEditPatientForm.get('sevenCtrl')!.value,
            address: this.createOrEditPatientForm.get('nineCtrl')!.value,
            cellPhoneNumber: String(this.createOrEditPatientForm.get('eightCtrl')!.value),
            emergencyContact: String(this.createOrEditPatientForm.get('tenCtrl')!.value),
            birthDate: this.isClient ? new Date(this.createOrEditPatientForm.get('elevenCtrl')!.value) : new Date(),
            isClient: this.isClient,
            personType: 3
        };
        this.busy = this._patientService.addOrUpdatePerson(employeeInput).subscribe(() => {
            this.toastr.success('Paciente Guardado', 'Paciente Guardado Exitosamente');
            this.close();
            this.modalSave.emit(null);
        });
    }

    close(): void {
        this.createOrEditPatientForm.reset();
        this.modal.hide();
    }

    ngOnDestroy(): void {
        this.busy?.unsubscribe();
    }
}