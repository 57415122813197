import { BOGeneralValues } from "./BOGeneralValues.model";

export class BOPerson extends BOGeneralValues{
    id:number;
    firstName?:string;
    secondName?:string
    firtsSurName?:string;
    secondSurName?:string;
    idType:string;
    personId:string;
    bloodType?:string | undefined;
    civilState?:string | undefined;
    email?:string;
    cellPhoneNumber?:string;
    address?:string;
    birthDate?:Date;
    emergencyContact?:string;
    emergencyContactName?:string;
    personType?:number;
    patientInformationId?:number | undefined;
    clinicalOfficeId?:number | undefined;
    headQuarterId?:number | undefined;
    userId?:number | undefined;
    isClient:boolean;
    registerNumber?:string | undefined;
    rethus?:string | undefined;
    typeEmployeeName?:string |undefined;
    area?:number | undefined;
}
