import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOCountry } from "../models/BOCountry.model";

@Injectable()
export class CountryService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/Country';

    constructor(private http:HttpClient){}

    getCountries():Observable<BOCountry[]>{
        return this.http.get<BOCountry[]>(this.myAppUrl + this.myApiUrl);
    }
}