import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOFilter } from "../models/BOFilter";
import { BOPerson } from "../models/BOPerson.model";

@Injectable()
export class PersonService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/Person/';

    constructor(private http:HttpClient){}

    getPersons():Observable<BOPerson[]>{
        return this.http.get<BOPerson[]>(this.myAppUrl + this.myApiUrl + 'getAll');
    }

    getPersonActives():Observable<BOPerson[]>{
        return this.http.get<BOPerson[]>(this.myAppUrl + this.myApiUrl + 'getAllActives');
    }

    getEmployees():Observable<BOPerson[]>{
        return this.http.get<BOPerson[]>(this.myAppUrl + this.myApiUrl + 'getAllEmployees');
    }
    //Ric
    getEmployees2(activeType:string):Observable<BOPerson[]>{
        console.log('Entra a getEmployees2 y activeType es:'+activeType);
        return this.http.get<BOPerson[]>(this.myAppUrl + this.myApiUrl + 'getAllEmployeesTest/'+activeType);
    }
    //

    getProfesionals():Observable<BOPerson[]>{
        return this.http.get<BOPerson[]>(this.myAppUrl + this.myApiUrl + 'getAllProfesionals');
    }

    getPatients(filter:BOFilter):Observable<BOPerson[]>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(filter);
        return this.http.post<BOPerson[]>(this.myAppUrl + this.myApiUrl + 'getAllPatients',body,httpOptions);
    }

    getFuturePatients():Observable<BOPerson[]>{
        return this.http.get<BOPerson[]>(this.myAppUrl + this.myApiUrl + 'getAllFuturePatients');
    }

    getPerson(personId:number):Observable<BOPerson>{
        return this.http.get<BOPerson>(this.myAppUrl + this.myApiUrl + 'getPerson/' + personId);
    }

    getPersonIdNumber(filterPerson:BOPerson):Observable<BOPerson>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(filterPerson);
        return this.http.post<BOPerson>(this.myAppUrl + this.myApiUrl + 'getPersonbyIdNumber',body,httpOptions);
    }

    addOrUpdatePerson(person:BOPerson):Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(person);
          return this.http.post<any>(this.myAppUrl + this.myApiUrl + 'addOrUpdatePerson',body,httpOptions);
    }

    deletePerson(personId:number):Observable<any>{
        return this.http.delete<any>(this.myAppUrl + this.myApiUrl + 'delete/' + personId);
    }
}