import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-spinner',
  // templateUrl: './spinner.component.html',
  template:`
  <div class="overlay" *ngIf="isLoading$ | async">

  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
  `,
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent  {
  isLoading$=this._spinnerService.isLoading$;

  constructor(private _spinnerService: SpinnerService) { }

}
//  <div class="lds-hourglass">Cargando data...</div>
