import {Component} from '@angular/core';
import { Router } from '@angular/router';
import { navItems } from '../../_nav';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;

  constructor(private router: Router, private location: Location){ }
  
  public navItems = localStorage.getItem('User') != undefined ? (parseInt(localStorage.getItem('Role')) != 0 ? navItems.filter(x=> x.name != "Administración") : navItems) : [];

  toggleMinimize(e) {
    console.log(localStorage.getItem('User'));
    this.sidebarMinimized = e;
  }

  logOut(){
    localStorage.clear();
    this.location.replaceState('/'); // clears browser history so they can't navigate with back button
    this.router.navigate(['login']);
  }

}
