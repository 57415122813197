import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin, Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { BOCity } from "../../models/BOCity.model";
import { BOCountry } from "../../models/BOCountry.model";
import { BOIps } from "../../models/BOIps.model";
import { BOItem } from "../../models/BOItem.model";
import { BOPersonOcupation } from "../../models/BOPersonOcupation.model";
import { BOState } from "../../models/BOState.model";


import { BiologicalSexService } from "../../services/biologicalSex.service";
import { CityService } from "../../services/city.service";
import { ConsultationCauseService } from "../../services/consultationCause.service";
import { CountryService } from "../../services/country.service";
import { DiagnosticTypeService } from "../../services/diagnosticType.service";
import { DisabilityCategoryService } from "../../services/disabilityCategory.service";
import { EpsService } from "../../services/eps.service";
import { EthnicCommunityService } from "../../services/ethnicCommunity.service";
import { EthnicityService } from "../../services/ethnicity.service";
import { GenderIdentityService } from "../../services/genderIdentity.service";
import { IdTypeService } from "../../services/idType.service";
import { IpsService } from "../../services/ips.service";
import { PersonOcupationService } from "../../services/personOcupation.service";
import { ResidenceZoneService } from "../../services/residenceZone.service";
import { StateService } from "../../services/state.service";

import { StepperOrientation } from "@angular/cdk/stepper";
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, takeUntil } from "rxjs/operators";
import { BOClinicHistory } from "../../models/BOClinicHistory.model";
import { BOPerson } from "../../models/BOPerson.model";
import { PersonService } from "../../services/person.service";
import { ClinicHistoryService } from "../../services/clinicHistory.service";
import { ServiceGroupsService } from "../../services/serviceGroups.service";
import { HealthTecnologyModalityService } from "../../services/healthTecnologyModality.service";
import { PlaceOfAttentionService } from "../../services/placeOfAttention.service";
import { RouteOfEntryService } from "../../services/routeOfEntry.service";
import { TriageTypeService } from "../../services/triageType.service";
import { TOUCH_BUFFER_MS } from "@angular/cdk/a11y";
import { PatientInformationService } from "../../services/patientInformation.service";
import { BOPatientInformation } from "../../models/BOPatientInformation.model";
import { HealthTechnologyFinalityService } from "../../services/healthTechnologyFinality.service";
import { HealthTechnologyTimeUnitService } from "../../services/HealthTechnologyTimeUnit.service";
import { HealthTechnologyService } from "../../services/healthTechnology.service";
import { MatStepper } from "@angular/material/stepper";
import { collapseTextChangeRangesAcrossMultipleVersions, HighlightSpanKind } from "typescript";
import { BOClinicHistoryObservations } from "../../models/BOClinicHistoryObservations.model";
import { PatientConditionService } from "../../services/patientCondition.service";
import { DiseaseService } from "../../services/disease.service";
import { OrphanDiseaseService } from "../../services/orphanDisease.service";
import { BOEps } from "../../models/BOEps.model";
import { BOOrganDonor } from "../../models/BOOrganDonor.model";
import { BOAdvancedDirectiveDocument } from "../../models/BOAdvancedDirectiveDocument.model";
import { BOMedicDiagnostic } from "../../models/BOMedicDiagnostic.model";
import { BOHealthTechnologies } from "../../models/BOHealthTechnologies.model";
import { BOHealthTechnologyResult } from "../../models/BOHealthTechnologyResult.model";
import { BOPrescriptionMedicationDose } from "../../models/BOPrescriptionMedicationDose.model";
import { BOTriage } from "../../models/BOTriage.model";
import { BOHealthTechnologyTerm } from "../../models/BOHealthTechnologyTerm.model";
import { BOHealthTechnologyFrecuency } from "../../models/BOHealthTechnologyFrecuency.model";
import { BOHealthTechnologyMedicamentDoseTaken } from "../../models/BOHealthTechnologyMedicamentDoseTaken.model";
import { BOHealthTechnologyMedicamentDoseDelivered } from "../../models/BOHealthTechnologyMedicamentDoseDelivered.model";
import { BOHealthTecnologyDeliveredUnits } from "../../models/BOHealthTecnologyDeliveredUnits.model";
import { BOHealthTechnologiesResult } from "../../models/BOHealthTechnologiesResult.model";
import { BOPrincipalMedicDiagnostic } from "../../models/BOPrincipalMedicDiagnostic.model";
import { BORelatedMedicDiagnostic } from "../../models/BORelatedMedicDiagnostic.model";
import { BOComplicationMedicDiagnostic } from "../../models/BOComplicationMedicDiagnostic.model";
import { BODeathMedicDiagnostic } from "../../models/BODeathMedicDiagnostic.model";

import { CreateOrEditMedicalFormulaComponent } from "./createOrEditMedicalFormula.component";
import { BOMedicalFormula } from "../../models/BOMedicalFormula.model";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { CompanyService } from "../../services/company.service";
import { BOCompany } from "../../models/BOCompany.model";
import { BOHeadQuarters } from "../../models/BOHeadQuarters.model";
import { HeadQuarterService } from "../../services/headQuarter.service";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';

import { ServiceTypeService } from '../../services/service-type.service';
import { SellComponent } from './../sell/sell.component';
import { BOSellDataSended } from "../../models/BOSellDataSended.model";
import { SellService } from "../../services/sell.service";
import { BOSell } from "../../models/BOSell.model";
import { BOSellDetail } from "../../models/BOSellDtail.model";
import { BOSubServiceType } from "../../models/BOSubServiceType.model";
import { SubServiceTypeService } from "../../services/subServiceType.service";


@Component({
    selector: 'createOrEditClinicHistoryModal',
    templateUrl: './createOrEditClinicHistory.component.html',
    styleUrls: ['./createOrEditClinicHistory.component.css'],
    providers: [DatePipe]
})

export class CreateOrEditClinicHistoryComponent implements OnDestroy {
    @ViewChild('createOrEditModalObservation') public createOrEditModalObservation: ModalDirective;
    @ViewChild('createOrEditMedicalFormulaModal') public createOrEditMedicalFormulaModal: CreateOrEditMedicalFormulaComponent;
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('stepper', { static: true }) stepper: MatStepper;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('clinicHistoryContainer', { static: true }) clinicHistoryContainer: ElementRef;

    @ViewChild('sellModal') public sellModal: SellComponent;



    busy: Subscription | undefined;
    submitted = false;
    emergencies: boolean = false;
    showOnlyObservations: boolean = false;
    stepperIndex = 0;
    panelOpenState = false;



    tempObservationId = 0;
    tempObservation: BOClinicHistoryObservations = new BOClinicHistoryObservations();
    tempMedicalFormulaId = 0;

    clinicHistory: BOClinicHistory = new BOClinicHistory();
    clinicHistoryControl:BOClinicHistory=new BOClinicHistory();
    patient: BOPerson = new BOPerson();
    countries: BOCountry[] = [];
    states: BOState[] = [];
    cities: BOCity[] = [];
    biologicalSexList: BOItem[] = [];
    consultationCauseList: BOItem[] = [];
    diagnosticTypeList: BOItem[] = [];
    disabilityCategoryList: BOItem[] = [];
    epsList: BOEps[] = [];
    ethnicCommunityList: BOItem[] = [];
    ethnicityList: BOItem[] = [];
    genderIdentityList: BOItem[] = [];
    idTypeList: BOItem[] = [];
    ipsList: BOIps[] = [];
    personOcupationList: BOPersonOcupation[] = [];
    residenceZoneList: BOItem[] = [];
    healthTecnologyModalityList: BOItem[] = [];
    serviceGroupsList: BOItem[] = [];
    placeOfAttentionList: BOItem[] = [];
    routeOfEntryList: BOItem[] = [];
    triageTypeList: BOItem[] = [];
    healthTechnologyFinalityList: BOItem[] = [];
    healthTechnologyList: BOItem[] = [];
    healthTechnologyTimeUnitList: BOItem[] = [];
    patientInformation: BOPatientInformation = new BOPatientInformation();
    patientConditionList: BOItem[] = [];
    diseaseList: BOItem[] = [];
    orphanDiseaseList: BOItem[] = [];
    diseaseListRelated: BOItem[] = [];
    diseaseListExit: BOItem[] = [];
    diseaseListComplication: BOItem[] = [];
    diseaseListDeath: BOItem[] = [];
    orphanDiseaseRelatedList: BOItem[] = [];
    orphanDiseaseExitList: BOItem[] = [];
    orphanDiseaseComplicationList: BOItem[] = [];
    orphanDiseaseDeathList: BOItem[] = [];
    company: BOCompany;
    headQuarterList: BOHeadQuarters[] = [];
    profesionals:BOPerson[] = [];
    profesionalSelected:number;

    serviceTypeList: BOItem[] = [];
    categoryService: string="";
    clinicHistorySended:number=null;
    subServiceTypeList:BOSubServiceType[];

    filteredCountries: ReplaySubject<BOCountry[]> = new ReplaySubject<BOCountry[]>(1);
    filteredDiseases: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredDiseasesRelated: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredDiseasesExit: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredDiseasesComplication: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredDiseasesDeath: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredOrphanDiseases: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredOrphanDiseasesRelated: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredOrphanDiseasesExit: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredOrphanDiseasesComplication: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredOrphanDiseasesDeath: ReplaySubject<BOItem[]> = new ReplaySubject<BOItem[]>(1);
    filteredOcupation: ReplaySubject<BOPersonOcupation[]> = new ReplaySubject<BOPersonOcupation[]>(1);
    filteredEps: ReplaySubject<BOEps[]> = new ReplaySubject<BOEps[]>(1);
    countryFilterCtrl: FormControl = new FormControl();
    diseaseFilterCtrl: FormControl = new FormControl();
    diseaseRelatedFilterCtrl: FormControl = new FormControl();
    diseaseExitFilterCtrl: FormControl = new FormControl();
    diseaseComplicationFilterCtrl: FormControl = new FormControl();
    diseaseDeathFilterCtrl: FormControl = new FormControl();
    orphanDiseaseFilterCtrl: FormControl = new FormControl();
    orphanDiseaseRelatedFilterCtrl: FormControl = new FormControl();
    orphanDiseaseExitFilterCtrl: FormControl = new FormControl();
    orphanDiseaseComplicationFilterCtrl: FormControl = new FormControl();
    orphanDiseaseDeathFilterCtrl: FormControl = new FormControl();
    ocupationFilterCtrl: FormControl = new FormControl();
    epsFilterCtrl: FormControl = new FormControl();
    searchingDisease: Boolean = false;
    searchingOrphanDisease: Boolean = false;
    showOtherProcedureControl: Boolean = false;

    stepperOrientation: Observable<StepperOrientation>;
    protected _onDestroy = new Subject<void>();

    isSearchPatient: boolean = false;
//#region create formsGroups and controls
    firstFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
        secondCtrl: '',
        threeCtrl: ['', Validators.required],
        fourCtrl: '',
        fifthCtrl: ['', Validators.required],
        sixCtrl: ['', Validators.required],
        sevenCtrl: ['', Validators.required],
        eightCtrl: ['', Validators.required],
        nineCtrl: ['', Validators.required],
        tenCtrl: ['', Validators.required],
        elevenCtrl: '',
        twelveCtrl: ''
    });

    secondFormGroup = this._formBuilder.group({
        firstCtrl: null,
        secondCtrl: null,
        threeCtrl: null,
        fourCtrl: null,
        fifthCtrl: null,
        sixCtrl: null,
        sevenCtrl: null,
        eightCtrl: null,
        nineCtrl: null,
        tenCtrl: null,
        elevenCtrl: null,
        twelveCtrl: null,
        thirteen: null,
        fourtheen: null,
        fiftheen: null,
        sixteen: null
    });
//Datos de ingreso
    thirdFormGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
        secondCtrl: ['', Validators.required],
        threeCtrl: ['', Validators.required],
        fourCtrl: ['', Validators.required],
        fifthCtrl: ['', Validators.required],
        sixCtrl: ['', Validators.required],
        sevenCtrl: ['', Validators.required],
        eightCtrl: null,
        nineCtrl: null,
        thirteenCtrl: [null, Validators.required],
        fourteenCtrl: [null, Validators.required],
        fifteenCtrl: null,
        sixteenCtrl: [null,], //subServiceType
        seventeenCtrl: [null,], //area Medicina Gral, Odontologia


    });

    fourFormGroup = this._formBuilder.group({
        firstCtrl: null,
        secondCtrl: null,
        threeCtrl: null,
        fourCtrl: null,
        fifthCtrl: "0",
        sixCtrl: null,
        sevenCtrl: null,
        eightCtrl: "0",
        nineCtrl: null,
        tenCtrl: "0",
        elevenCtrl: null,
        twelveCtrl: "0",
        thirteenCtrl: null,
        fourteenCtrl: null,
        fiftheenCtrl: "0",
        sixteenCtrl: null,
        seventeenCtrl: "0",
        eighteenCtrl: null,
        nineteenCtrl: null,
        twentyCtrl: null
    });

    fifthFormGroup = this._formBuilder.group({
        firstCtrl: null,
        secondCtrl: null,
        threeCtrl: null,
        fourCtrl: null,
        fifthCtrl: null,
        sixCtrl: null,
        sevenCtrl: null,
        eightCtrl: null,
        nineCtrl: null,
        tenCtrl: null,
        elevenCtrl: null,
        twelveCtrl: null,
        thirteenCtrl: null,
        fourteenCtrl: "0",
        fiftheenCtrl: "0",
        sixteenCtrl: null,
        seventeenCtrl: null
    });

    sixFormGroup = this._formBuilder.group({
        firstCtrl: null,
        secondCtrl: null,
        threeCtrl: null,
        fourCtrl: null,
        fifthCtrl: null,
        sixCtrl: null,
        sevenCtrl: null,
        eightCtrl: null,
        nineCtrl: null,
        tenCtrl: null,
        elevenCtrl: null,
        twelveCtrl: null,
        thirteenCtrl: null,
        fourteenCtrl: null,
        fiftheenCtrl: null,
        sixteenCtrl: null,
        seventeenCtrl: null,
        eighteenCtrl: null,
        nineteenCtrl: null,
        twentyCtrl: null,
        twentyOneCtrl: null,
        twentyTwoCtrl: null,
        twentyThreeCtrl: null
    });

    //#endregion


    constructor(public toastr: ToastrService,
        private formBuilder: FormBuilder,
        private _biologicalSexService: BiologicalSexService,
        private _cityService: CityService,
        private _consultationCauseService: ConsultationCauseService,
        private _countryService: CountryService,
        private _diagnosticTypeService: DiagnosticTypeService,
        private _disabilityCategoryService: DisabilityCategoryService,
        private _epsService: EpsService,
        private _ethnicCommunityService: EthnicCommunityService,
        private _ethnicityService: EthnicityService,
        private _genderIdentityService: GenderIdentityService,
        private _idTypeService: IdTypeService,
        private _ipsService: IpsService,
        private _personOcupationService: PersonOcupationService,
        private _residenceZoneService: ResidenceZoneService,
        private _stateService: StateService,
        private _formBuilder: FormBuilder, breakpointObserver: BreakpointObserver,
        private _personService: PersonService,
        private _clinicHistoryService: ClinicHistoryService,
        private _serviceGroupsService: ServiceGroupsService,
        private _healthTecnologyModalityService: HealthTecnologyModalityService,
        private _placeOfAttentionService: PlaceOfAttentionService,
        private _routeOfEntryService: RouteOfEntryService,
        private _triageTypeService: TriageTypeService,
        private _patientInformationService: PatientInformationService,
        private _healthTechnologyFinalityService: HealthTechnologyFinalityService,
        private _healthTechnologyTimeUnitService: HealthTechnologyTimeUnitService,
        private _healthTechnologyService: HealthTechnologyService,
        private _patientConditionService: PatientConditionService,
        private _diseaseService: DiseaseService,
        private _orphanDiseaseService: OrphanDiseaseService,
        private _companyService: CompanyService,
        private _headQuarterService: HeadQuarterService,
        private datePipe: DatePipe,
        private _serviceTypeService: ServiceTypeService,
        private _subServiceTypeService:SubServiceTypeService,

        ) {

        this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
            .pipe(map(({ matches }) => matches ? 'horizontal' : 'vertical'));
    }

    openModal(clinicHistoryId: number,showOnlyObservations: boolean, categoryService: string ): void
    {

        this.showOnlyObservations = showOnlyObservations;
        this.submitted = true;
        this.modal.config = { backdrop: "static", keyboard: false };
        this.modal.show();
//Ric
        this.categoryService=categoryService;
        this.clinicHistorySended=clinicHistoryId;
        this.firstFormGroup.patchValue({
           fifthCtrl: "CC"
        });

//end Ric
        this.busy = forkJoin({
            resultCountry: this._countryService.getCountries(),
            resultBiologicalSex: this._biologicalSexService.getAll(),
            resultConsultationCause: this._consultationCauseService.getAll(),
            resultDiagnosticType: this._diagnosticTypeService.getAll(),
            resultDisabilityCategory: this._disabilityCategoryService.getAll(),
            resultEps: this._epsService.getAll(),
            resultEthnicCommunity: this._ethnicCommunityService.getAll(),
            resultEthnicity: this._ethnicityService.getAll(),
            resultGenderIdentity: this._genderIdentityService.getAll(),
            resultIdType: this._idTypeService.getAll(),
            resultIps: this._ipsService.getAll(),
            resultCity: this._cityService.getCitiesbyState("NN"),
            resultPersonOcupation: this._personOcupationService.getAll(),
            resultResidenceZone: this._residenceZoneService.getAll(),
            resultHealthTecnologyModality: this._healthTecnologyModalityService.getAll(),
            resultServiceGroups: this._serviceGroupsService.getAll(),
            resultPlaceOfAttention: this._placeOfAttentionService.getAll(),
            resultRouteOfEntry: this._routeOfEntryService.getAll(),
            resultTriageType: this._triageTypeService.getAll(),
            resultHealthTechnologyFinality: this._healthTechnologyFinalityService.getAll(),
            resultHealthTechnologyTimeUnit: this._healthTechnologyTimeUnitService.getAll(),
            resultHealthTechnology: this._healthTechnologyService.getAll(),
            resultPatientCondition: this._patientConditionService.getAll(),
            resultDisease: this._diseaseService.getAll(".n"),
            resultOrphanDisease: this._orphanDiseaseService.getAll(".n"),
            resultCompany: this._companyService.getCompany(),
            resultHeadquarter: this._headQuarterService.getHeadQuarters(),
            resultProfesionals: this._personService.getProfesionals(),
            resultServiceType: this._serviceTypeService.getAll(),
            resultSubServiceTypeService: this._subServiceTypeService.getAll(),

        }).subscribe(({ resultCountry, resultBiologicalSex, resultConsultationCause, resultDiagnosticType,
            resultDisabilityCategory, resultEps, resultEthnicCommunity, resultEthnicity, resultGenderIdentity,
            resultIdType, resultIps, resultCity, resultPersonOcupation, resultResidenceZone, resultHealthTecnologyModality,
            resultServiceGroups, resultPlaceOfAttention, resultRouteOfEntry, resultTriageType, resultHealthTechnologyFinality,
            resultHealthTechnologyTimeUnit, resultHealthTechnology, resultPatientCondition, resultDisease, resultOrphanDisease,
            resultCompany, resultHeadquarter, resultProfesionals, resultServiceType, resultSubServiceTypeService }) => {

            this.countries = resultCountry;
            this.filteredCountries.next(this.countries.slice());
            // listen for search field value changes
            this.countryFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterCountries();
                });
            this.biologicalSexList = resultBiologicalSex;
            this.consultationCauseList = resultConsultationCause;
            this.diagnosticTypeList = resultDiagnosticType;
            this.disabilityCategoryList = resultDisabilityCategory;
            this.epsList = resultEps;
            this.cities = resultCity;
            this.filteredEps.next(this.epsList.slice());
            this.epsFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterEps();
                });
            this.ethnicCommunityList = resultEthnicCommunity;
            this.ethnicityList = resultEthnicity;
            this.genderIdentityList = resultGenderIdentity;
            this.idTypeList = resultIdType;
            this.ipsList = resultIps;
            this.personOcupationList = resultPersonOcupation;
            this.filteredOcupation.next(this.personOcupationList.slice());
            this.ocupationFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterOcupation();
                });
            this.residenceZoneList = resultResidenceZone;
            this.healthTecnologyModalityList = resultHealthTecnologyModality;
            this.serviceGroupsList = resultServiceGroups;
            this.placeOfAttentionList = resultPlaceOfAttention;
            this.routeOfEntryList = resultRouteOfEntry;
            this.triageTypeList = resultTriageType;
            this.healthTechnologyList = resultHealthTechnology;
            this.healthTechnologyFinalityList = resultHealthTechnologyFinality;
            this.healthTechnologyTimeUnitList = resultHealthTechnologyTimeUnit;
            this.patientConditionList = resultPatientCondition;
            this.orphanDiseaseList = resultOrphanDisease;
            this.filteredOrphanDiseases.next(this.orphanDiseaseList.slice());
            this.orphanDiseaseRelatedList = resultOrphanDisease;
            this.filteredOrphanDiseasesRelated.next(this.orphanDiseaseRelatedList.slice());
            this.orphanDiseaseExitList = resultOrphanDisease;
            this.filteredOrphanDiseasesExit.next(this.orphanDiseaseExitList.slice());
            this.orphanDiseaseComplicationList = resultOrphanDisease;
            this.filteredOrphanDiseasesComplication.next(this.orphanDiseaseComplicationList.slice());
            this.orphanDiseaseDeathList = resultOrphanDisease;
            this.filteredOrphanDiseasesDeath.next(this.orphanDiseaseDeathList.slice());
            // Si es cmg o edi debe eliminar el serviceType 37
            if(this.categoryService=="cmg" || this.categoryService=="edi")
              {
                  for (let i=0; i<resultServiceType.length; i++)
                    {
                        if(resultServiceType[i].code=="37")
                          {
                            resultServiceType.splice(i,1);
                          }
                    }
              }

            this.serviceTypeList = resultServiceType;
            this.subServiceTypeList=resultSubServiceTypeService;

            console.log('En openModal this.ServiceTypeList es:', this.serviceTypeList);
            this.orphanDiseaseFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterOrphanDiseases();
                });
            this.orphanDiseaseRelatedFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterOrphanDiseasesRelated();
                });
            this.orphanDiseaseExitFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterOrphanDiseasesExit();
                });
            this.orphanDiseaseComplicationFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterOrphanDiseasesComplication();
                });
            this.orphanDiseaseDeathFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterOrphanDiseasesDeath();
                });
            this.diseaseList = resultDisease;
            this.filteredDiseases.next(this.diseaseList.slice());
            this.diseaseListRelated = resultDisease;
            this.filteredDiseasesRelated.next(this.diseaseListRelated.slice());
            this.diseaseListExit = resultDisease;
            this.filteredDiseasesExit.next(this.diseaseListExit.slice());
            this.diseaseListComplication = resultDisease;
            this.filteredDiseasesComplication.next(this.diseaseListComplication.slice());
            this.diseaseListDeath = resultDisease;
            this.filteredDiseasesDeath.next(this.diseaseListDeath.slice());
            // listen for search field value changes
            this.diseaseFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterDiseases();
                });
            this.diseaseRelatedFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterDiseasesRelated();
                });
            this.diseaseExitFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterDiseasesExit();
                });
            this.diseaseComplicationFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterDiseasesComplication();
                });
            this.diseaseDeathFilterCtrl.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    this.filterDiseasesDeath();
                });
            this._stateService.getStatesbyCountry(this.countries[0].id).subscribe(data => {
                this.states = data;
            });
            this.company = resultCompany;
            this.headQuarterList = resultHeadquarter;
            this.profesionals = resultProfesionals;
            //Crear casos para cmg, cmc y edi

             if (clinicHistoryId > 0)
             {

                this._clinicHistoryService.getClinicHistory(clinicHistoryId)
                .subscribe(data =>
                {
                  this.clinicHistory = data;
                  let dataTemp= JSON.parse(JSON.stringify(data));
                  //const copiedData= structuredClone(data);
                  // new
                  this.clinicHistoryControl=dataTemp;

                  console.log('En OpenModal clnicHistory es:',this.clinicHistory, 'id es:',
                  this.clinicHistory.id, 'serviceType es:',
                  this.clinicHistory.serviceType);



                  console.log('En OpenModal clnicHistorycontrol es:',this.clinicHistoryControl, 'id es:',
                  this.clinicHistoryControl.id, 'serviceType es:',
                  this.clinicHistoryControl.serviceType);


                  //
                  this.patient = this.clinicHistory.patient;
                  this.profesionalSelected = this.profesionals.find(x => x.id == this.clinicHistory.profesional?.id)?.id;

                  if (this.patient.patientInformationId != null)
                  {
                      this._patientInformationService.getPatientInformation(this.patient.patientInformationId).subscribe(data => {
                      this.patientInformation = data;
                      });
                  }

                  this.firstFormGroup.patchValue({
                    firstCtrl: this.patient.firstName,
                    secondCtrl: this.patient.secondName,
                    threeCtrl: this.patient.firtsSurName,
                    fourCtrl: this.patient.secondSurName,
                    fifthCtrl: this.idTypeList.find(x => x.code == this.patient.idType).code,
                    sixCtrl: this.patient.personId,
                    sevenCtrl: this.patient.email,
                    nineCtrl: this.patient.address,
                    eightCtrl: this.patient.cellPhoneNumber,
                    tenCtrl: this.patient.emergencyContact,
                    twelveCtrl: this.patient.emergencyContactName,
                    elevenCtrl: this.patient.birthDate.toString(),
                        // elevenCtrl: this.patient.birthDate.toString().split('T')[0]
                    });

                      this.isSearchPatient = true;
                });
             }
             else
             {

                this.clinicHistory.id = 0;
                    this.patient.id = 0;

             }

        })

    }
//end open modal

    searchPatient(): void
    {
        const patientInput: BOPerson = {
            id: 0,
            idType: this.firstFormGroup.get('fifthCtrl')!.value,        //Id type
            personId: this.firstFormGroup.get('sixCtrl')!.value.trim(), //num Id
            personType: 3,
            isClient: true
        };
        this.busy = this._personService.getPersonIdNumber(patientInput).subscribe(data => {
            if (data.id > 0)
            {
                this.patient = data;
                this.firstFormGroup.patchValue({
                    firstCtrl: this.patient.firstName,
                    secondCtrl: this.patient.secondName,
                    threeCtrl: this.patient.firtsSurName,
                    fourCtrl: this.patient.secondSurName,
                    fifthCtrl: this.patient.idType,
                    sixCtrl: this.patient.personId, //num Id
                    sevenCtrl: this.patient.email,
                    nineCtrl: this.patient.address,
                    eightCtrl: this.patient.cellPhoneNumber,
                    tenCtrl: this.patient.emergencyContact,
                    twelveCtrl: this.patient.emergencyContactName,
                    elevenCtrl: this.patient.birthDate.toString().split('T')[0]
                });
            }
            //Ric
            else
            {
                //console.log("No se encontraron registros");
            }
            //end Ric
            this.isSearchPatient = true;
        });
    }

    createOrUpdatePerson(next: boolean): void
    {

      if( this.categoryService=='cmg')
      {
              this.clinicHistory.clinicHistoryObservations=[];
              this.clinicHistory.medicalFormula=[];
      }
//Ric 2-05-2024
//
      if(this.categoryService =='edi')
           {

                this.sixFormGroup.patchValue({
                firstCtrl: this.clinicHistory.pathologicalRecord,
                secondCtrl: this.clinicHistory.consultationMotive,
                threeCtrl: this.clinicHistory.anamnesis,
                fourCtrl: this.clinicHistory.arterialPressure,
                fifthCtrl:this.clinicHistory.temperature,
                sixCtrl:this.clinicHistory.pulse,
                sevenCtrl:this.clinicHistory.weight,
                eightCtrl:this.clinicHistory.size,
                nineCtrl:this.clinicHistory.breathing,
                tenCtrl:this.clinicHistory.head,
                elevenCtrl:this.clinicHistory.neck,
                twelveCtrl:this.clinicHistory.chest,
                thirteenCtrl:this.clinicHistory.belly,
                fourteenCtrl:this.clinicHistory.pelvis,
                fiftheenCtrl:this.clinicHistory.extremities,
                sixteenCtrl:this.clinicHistory.skin,
                seventeenCtrl:this.clinicHistory.centralNervousSystem,
                eighteenCtrl:this.clinicHistory.diagnosticImpression,
                nineteenCtrl:this.clinicHistory.laboratoryControl,
                twentyCtrl:this.clinicHistory.treatmentPlan,
                twentyOneCtrl:this.clinicHistory.medicDiagnostic?.diseaseCode,
                twentyTwoCtrl:this.clinicHistory.medicDiagnostic?.orphanDisease,
                twentyThreeCtrl:this.clinicHistory.medicDiagnostic?.diagnosticType,
                });
          }

      if(this.categoryService =='cmc')
           {

                    this.sixFormGroup.patchValue({
                    firstCtrl: this.clinicHistory.pathologicalRecord,
                    secondCtrl: this.clinicHistory.consultationMotive,
                    threeCtrl: this.clinicHistory.anamnesis,
                    fourCtrl: this.clinicHistory.arterialPressure,
                    fifthCtrl:this.clinicHistory.temperature,
                    sixCtrl:this.clinicHistory.pulse,
                    sevenCtrl:this.clinicHistory.weight,
                    eightCtrl:this.clinicHistory.size,
                    nineCtrl:this.clinicHistory.breathing,
                    tenCtrl:this.clinicHistory.head,
                    elevenCtrl:this.clinicHistory.neck,
                    twelveCtrl:this.clinicHistory.chest,
                    thirteenCtrl:this.clinicHistory.belly,
                    fourteenCtrl:this.clinicHistory.pelvis,
                    fiftheenCtrl:this.clinicHistory.extremities,
                    sixteenCtrl:this.clinicHistory.skin,
                    seventeenCtrl:this.clinicHistory.centralNervousSystem,
                    eighteenCtrl:this.clinicHistory.diagnosticImpression,
                    nineteenCtrl:this.clinicHistory.laboratoryControl,
                    twentyCtrl:this.clinicHistory.treatmentPlan,
                    twentyOneCtrl:this.clinicHistory.medicDiagnostic?.diseaseCode,
                    twentyTwoCtrl:this.clinicHistory.medicDiagnostic?.orphanDisease,
                    twentyThreeCtrl:this.clinicHistory.medicDiagnostic?.diagnosticType,
                    });
          }


      let secondNameTested="";
      let secondNameTest=this.firstFormGroup.get('secondCtrl')!.value;
      secondNameTest==undefined?console.log('Es undefined'):console.log('secondName test undefined es:', typeof(secondNameTest));
      secondNameTest==null
        || secondNameTest==""
        || secondNameTest==undefined?secondNameTested="":(secondNameTested=secondNameTest.toUpperCase()).trim();

      //console.log('secondNameTestED después de evaluación es:', secondNameTested);

      let secondSurNameTest=(this.firstFormGroup.get('fourCtrl')!.value);
      secondSurNameTest==undefined?console.log('SecondSurName Es undefined'):console.log('secondSurNametest undefined es:', typeof(secondNameTest));

      let secondSurNameTested="";
      secondSurNameTest ==null
        || secondSurNameTest ==""
        || secondSurNameTest==undefined?secondSurNameTested="":(secondSurNameTested=secondSurNameTest.toUpperCase()).trim();

        this.patient.id = this.patient.id;

        this.patient.firstName = this.firstFormGroup.get('firstCtrl')!.value;
        this.patient.firstName=this.patient.firstName.toUpperCase().trim();
        this.patient.secondName = secondNameTested;

        this.patient.firtsSurName = this.firstFormGroup.get('threeCtrl')!.value;
        this.patient.firtsSurName=this.patient.firtsSurName.toUpperCase().trim();

        this.patient.secondSurName = secondSurNameTested;
        this.patient.idType = this.firstFormGroup.get('fifthCtrl')!.value;
        this.patient.personId = this.firstFormGroup.get('sixCtrl')!.value;
        this.patient.email = (this.firstFormGroup.get('sevenCtrl')!.value).trim().toLowerCase();
        this.patient.address = this.firstFormGroup.get('nineCtrl')!.value;
        this.patient.cellPhoneNumber = String(this.firstFormGroup.get('eightCtrl')!.value);
        this.patient.emergencyContact = String(this.firstFormGroup.get('tenCtrl')!.value);
        this.patient.emergencyContactName = String(this.firstFormGroup.get('twelveCtrl')!.value);
        this.patient.birthDate = new Date(this.firstFormGroup.get('elevenCtrl')!.value);
        this.patient.personType = 3;
        this.patient.isClient = true;


        if(this.categoryService=='cmc')
        {
            this.clinicHistory.id=0;
            this.clinicHistory.clinicHistoryObservations?.forEach(x => { x.id = 0; x.creationUserId = parseInt(localStorage?.getItem('Id')) });
            this.clinicHistory.medicalFormula?.forEach(x => { x.id = 0; x.creationUserId = parseInt(localStorage?.getItem('Id'))  });
        }


        this.busy = this._personService.addOrUpdatePerson(this.patient).subscribe(data =>
        {
          // console.log('En busy de CreateOrUpdatePerson, this.clinicHistory es:', this.clinicHistory);
            this.patient.id = data;

            if (this.clinicHistory.id == 0)
            {
              // console.log('entra a this.clinicHistory.id==0 en CreateOrUpdatePerson');
                this._clinicHistoryService.createClinicHistory(this.patient.id).subscribe(data =>
                {
                    this.clinicHistory.id = data;
                    if (this.patient.patientInformationId != null)
                    {
                        var stateCodeTemp = null;
                        this._patientInformationService.getPatientInformation(this.patient.patientInformationId).subscribe(data => {
                            this.patientInformation = data;

                            if (this.cities.find(ci => ci.id == this.patientInformation.residenceCityId) != undefined)
                            {
                                stateCodeTemp = this.cities.find(ci => ci.id == this.patientInformation.residenceCityId).code.substring(0, 2);
                                this._cityService.getCitiesbyState(stateCodeTemp).subscribe(data => {
                                    this.cities = data;
                                })
                            }
                            else
                            {
                                this.cities = [];
                            }

                            this.secondFormGroup.patchValue({
                                firstCtrl: this.patientInformation.sexType,
                                secondCtrl: this.patientInformation.genderIdentity,
                                threeCtrl: this.patientInformation.disabilityCategory != null ? this.patientInformation.disabilityCategory : "08",
                                fourCtrl: this.patientInformation.ethnicity,
                                fifthCtrl: this.patientInformation.ethnicCommunity,
                                sixCtrl: this.patientInformation.residenceZone,
                                sevenCtrl: this.epsList.find(x => x.id == this.patientInformation.epsId)?.code,
                                nineCtrl: this.personOcupationList.find(p => p.id == this.patientInformation.personOcupationId)?.code,
                                eightCtrl: this.countries.find(c => c.id == this.patientInformation.birthCountryId)?.code,
                                tenCtrl: this.countries.find(co => co.id == this.patientInformation.residenceCountryId)?.code,
                                elevenCtrl: this.cities.find(ci => ci.id == this.patientInformation.residenceCityId)?.code,
                                twelveCtrl: this.cities.find(ci => ci.id == this.patientInformation.residenceCityId)?.code.substring(0, 2),
                                thirteen: this.patientInformation.organDonor?.organDonorAnswer,
                                fourtheen: this.patientInformation.organDonor?.organDonorDocumentDate?.toString().split('T')[0],
                                fiftheen: this.patientInformation.advancedDirectiveDocument?.advancedDirectiveDocumentAnswer,
                                sixteen: this.patientInformation.advancedDirectiveDocument?.advancedDirectiveDocumentDate?.toString().split('T')[0]
                            });

                            //Ric
                        this.thirdFormGroup.patchValue({
                            secondCtrl:"7600108820",
                            threeCtrl: "01",
                            fourCtrl: "05"
                        });

                            if (this.epsList.find(x => x.id == this.patientInformation.epsId)?.code != undefined) {
                                this.epsFilterCtrl.setValue(this.epsList.find(x => x.id == this.patientInformation.epsId).name);
                            }
                            if (this.personOcupationList.find(p => p.id == this.patientInformation.personOcupationId)?.code != undefined) {
                                this.ocupationFilterCtrl.setValue(this.personOcupationList.find(p => p.id == this.patientInformation.personOcupationId).description);
                            }
                            if (this.countries.find(co => co.id == this.patientInformation.residenceCountryId)?.code != undefined) {
                                this.countryFilterCtrl.setValue(this.countries.find(co => co.id == this.patientInformation.residenceCountryId).name);
                            }
                          //  this.toastr.success('Historia Clinica Guardada', 'Historia Clinica Guardada Exitosamente');
                            if (next) this.stepper.next();
                            else this.close();
                        });

                    }
                   // if (this.clinicHistory.id == 0)
                    // if (this.patient.patientInformationId != null)
                    else
                    {
                        this.patientInformation.id = 0;

//Ric
                        this.secondFormGroup.patchValue({
                        firstCtrl:  "02",
                        secondCtrl: "02",
                        threeCtrl:  "08",
                        fourCtrl:   "99",
                        fifthCtrl:  "",
                        sixCtrl: "01",
                        nineCtrl: "9997",
                        eightCtrl: "170",
                        tenCtrl: "170",
                        elevenCtrl: "76001",
                       //twelveCtrl: "76"
                        });
//Ric
                        this.thirdFormGroup.patchValue({
                        secondCtrl:"7600108820",
                        threeCtrl: "01",
                        fourCtrl: "05"
                        });
                       // console.log("En CreateOrUpdatePerson(p2, clinicHistory==0, patientInformation==null) ips es:"+this.thirdFormGroup.get('secondCtrl')!.value);

//end Ric
                        this.cities = [];
                      //  this.toastr.success('Historia Clinica Guardada', 'Historia Clinica Guardada Exitosamente');
                        if (next)
                          {
                            this.stepper.next();

                          }
                        else this.close();
                    }
                });
            }
            //end if (this.clinicHistory.id == 0)

            else
            {
              // console.log("Entra a ch >, 0 tiene id. El id es: ", this.clinicHistory);
                if (this.patient.patientInformationId != null)
                {
                    var stateCodeTemp = null;
                    this._patientInformationService.getPatientInformation(this.patient.patientInformationId).subscribe(data => {
                        this.patientInformation = data;
                        if (this.cities.find(ci => ci.id == this.patientInformation.residenceCityId) != undefined)
                        {
                            stateCodeTemp = this.cities.find(ci => ci.id == this.patientInformation.residenceCityId).code.substring(0, 2);
                            this._cityService.getCitiesbyState(stateCodeTemp).subscribe(data => {
                                this.cities = data;
                            })
                        }
                        else {
                            this.cities = [];
                        }
                        this.secondFormGroup.patchValue({
                            firstCtrl: this.patientInformation.sexType,
                            secondCtrl: this.patientInformation.genderIdentity,
                            threeCtrl: this.patientInformation.disabilityCategory != null ? this.patientInformation.disabilityCategory : "08",
                            fourCtrl: this.patientInformation.ethnicity,
                            fifthCtrl: this.patientInformation.ethnicCommunity,
                            sixCtrl: this.patientInformation.residenceZone,
                            sevenCtrl: this.epsList.find(x => x.id == this.patientInformation.epsId)?.code,
                            nineCtrl: this.personOcupationList.find(p => p.id == this.patientInformation.personOcupationId)?.code,
                            eightCtrl: this.countries.find(c => c.id == this.patientInformation.birthCountryId)?.code,
                            tenCtrl: this.countries.find(co => co.id == this.patientInformation.residenceCountryId)?.code,
                            elevenCtrl: this.cities.find(ci => ci.id == this.patientInformation.residenceCityId)?.code,
                            twelveCtrl: this.cities.find(ci => ci.id == this.patientInformation.residenceCityId)?.code.substring(0, 2),
                            thirteen: this.patientInformation.organDonor?.organDonorAnswer,
                            fourtheen: this.patientInformation.organDonor?.organDonorDocumentDate?.toString().split('T')[0],
                            fiftheen: this.patientInformation.advancedDirectiveDocument?.advancedDirectiveDocumentAnswer,
                            sixteen: this.patientInformation.advancedDirectiveDocument?.advancedDirectiveDocumentDate?.toString().split('T')[0]
                        });
                        if (this.epsList.find(x => x.id == this.patientInformation.epsId)?.code != undefined) {
                            this.epsFilterCtrl.setValue(this.epsList.find(x => x.id == this.patientInformation.epsId).name);
                        }
                        if (this.personOcupationList.find(p => p.id == this.patientInformation.personOcupationId)?.code != undefined) {
                            this.ocupationFilterCtrl.setValue(this.personOcupationList.find(p => p.id == this.patientInformation.personOcupationId).description);
                        }
                        if (this.countries.find(co => co.id == this.patientInformation.residenceCountryId)?.code != undefined) {
                            this.countryFilterCtrl.setValue(this.countries.find(co => co.id == this.patientInformation.residenceCountryId).name);
                        }
                        //this.toastr.success('Informacion del Paciente Actualizada', 'Informacion del Paciente Actualizada Exitosamente');
                        if (next) this.stepper.next();
                        else this.close();
                    });

                }
                // End of if (this.patient.patientInformationId != null)
                else
                {
                  this.patientInformation.id = 0;
                //Ric
                  this.secondFormGroup.patchValue({
                        firstCtrl:  "02",
                        secondCtrl: "02",
                        threeCtrl:  "08",
                        fourCtrl:   "99",
                        fifthCtrl:  "",
                        sixCtrl: "01",
                        nineCtrl: "9997",
                        eightCtrl: "170",
                        tenCtrl: "170",
                        elevenCtrl: "76001",
                    });
                //Ric

                    this.cities = [];
                   // this.toastr.success('Informacion del Paciente Actualizada', 'Informacion del Paciente Actualizada Exitosamente');
                    if (next) this.stepper.next();
                    else this.close();
                }
            }

        });
    }
//#region CreateOrUpdatePatienInformation
    createOrUpdatePatientInformation(next: boolean): void
    {
        const patientInformationInput: BOPatientInformation =
        {
            id: this.patientInformation.id,
            sexType: this.secondFormGroup.get('firstCtrl')!.value,
            genderIdentity: this.secondFormGroup.get('secondCtrl').value,
            disabilityCategory: this.secondFormGroup.get('threeCtrl').value,
            ethnicity: this.secondFormGroup.get('fourCtrl').value,
            ethnicCommunity: this.secondFormGroup.get('fifthCtrl').value,
            residenceZone: this.secondFormGroup.get('sixCtrl').value,
            epsId: this.secondFormGroup.get('sevenCtrl').value != undefined ? this.epsList.find(e => e.code == this.secondFormGroup.get('sevenCtrl').value)?.id : undefined,
            birthCountryId: this.secondFormGroup.get('eightCtrl').value != undefined ? this.countries.find(e => e.code == this.secondFormGroup.get('eightCtrl').value)?.id : undefined,
            personOcupationId: this.secondFormGroup.get('nineCtrl').value != undefined ? this.personOcupationList.find(e => e.code == this.secondFormGroup.get('nineCtrl').value)?.id : undefined,
            residenceCountryId: this.secondFormGroup.get('tenCtrl').value != undefined ? this.countries.find(e => e.code == this.secondFormGroup.get('tenCtrl').value)?.id : undefined,
            residenceCityId: this.secondFormGroup.get('elevenCtrl').value != undefined ? this.cities.find(e => e.code == this.secondFormGroup.get('elevenCtrl').value)?.id : undefined
        };

        if (this.patientInformation.organDonor != null) {
            patientInformationInput.organDonor = this.patientInformation.organDonor;
            patientInformationInput.organDonor.organDonorAnswer = this.secondFormGroup.get('thirteen').value;
            patientInformationInput.organDonor.organDonorDocumentDate = this.secondFormGroup.get('fourtheen').value != undefined ? new Date(this.secondFormGroup.get('fourtheen').value) : null;
        }
        else {
            patientInformationInput.organDonor = new BOOrganDonor();
            patientInformationInput.organDonor.id = 0;
            patientInformationInput.organDonor.organDonorAnswer = this.secondFormGroup.get('thirteen').value;
            patientInformationInput.organDonor.organDonorDocumentDate = this.secondFormGroup.get('fourtheen').value != undefined ? new Date(this.secondFormGroup.get('fourtheen').value) : null;
        }
        if (this.patientInformation.advancedDirectiveDocument != null) {
            patientInformationInput.advancedDirectiveDocument = this.patientInformation.advancedDirectiveDocument;
            patientInformationInput.advancedDirectiveDocument.advancedDirectiveDocumentAnswer = this.secondFormGroup.get('fiftheen').value;
            patientInformationInput.advancedDirectiveDocument.advancedDirectiveDocumentDate = this.secondFormGroup.get('sixteen').value != undefined ? new Date(this.secondFormGroup.get('sixteen').value) : null;
        }
        else
        {
            patientInformationInput.advancedDirectiveDocument = new BOAdvancedDirectiveDocument();
            patientInformationInput.advancedDirectiveDocument.id = 0;
            patientInformationInput.advancedDirectiveDocument.advancedDirectiveDocumentAnswer = this.secondFormGroup.get('fiftheen').value;
            patientInformationInput.advancedDirectiveDocument.advancedDirectiveDocumentDate = this.secondFormGroup.get('sixteen').value != undefined ? new Date(this.secondFormGroup.get('sixteen').value) : null;
        }

        // console.log('befores apply service.addOrUpdatePatientInformation this.clinicHistory is:', this.clinicHistory)
        this.busy = this._patientInformationService.addOrUpdatePatientInformation(patientInformationInput).subscribe(data =>
        {

            if (this.categoryService=="cmg")
            {
              // console.log('En createUpdataPatientInformation, entra a this.categoryservice=cmg')
              this.profesionalSelected=null;

                this.thirdFormGroup.patchValue({
                    firstCtrl: null,
                    secondCtrl: "7600108820",
                    threeCtrl: "01",
                    fourCtrl: "01",
                    fifthCtrl:"05",
                    sixCtrl:"01",
                    sevenCtrl:"38",
                    thirteenCtrl: "01",
                    fourteenCtrl: "",
                });

            }

            if (this.categoryService=="cmc")
            {
                // console.log('En createUpdataPatientInformation, entra a this.categoryservice=cmc')

                this.thirdFormGroup.patchValue({

                    firstCtrl: null,
                    secondCtrl: this.ipsList.find(x => x.id == this.clinicHistory.ipsId)?.code,
                    threeCtrl: "01",
                    fourCtrl: this.clinicHistory.healthTechnologyModality,
                    fifthCtrl: this.clinicHistory.placeOfAttention,
                    sixCtrl: this.clinicHistory.routeOfEntry,
                    sevenCtrl: this.clinicHistory.consultationCause,
                    eightCtrl: this.clinicHistory.triageDate,
                    nineCtrl: this.clinicHistory.triageType,
                    thirteenCtrl: "37",
                    fourteenCtrl: "0",
                    fifteenCtrl: "Consulta de control médico"

                });

            }

            if (this.categoryService=="edi")
            {
                console.log('En createUpdataPatientInformation, entra  edi categoryService')

              this.thirdFormGroup.patchValue({
                firstCtrl: this.clinicHistory.consultationDate?.toString(),
                secondCtrl: this.ipsList.find(x => x.id == this.clinicHistory.ipsId)?.code,
                threeCtrl: this.clinicHistory.serviceGroups,
                fourCtrl: this.clinicHistory.healthTechnologyModality,
                fifthCtrl: this.clinicHistory.placeOfAttention,
                sixCtrl: this.clinicHistory.routeOfEntry,
                sevenCtrl: this.clinicHistory.consultationCause,
                eightCtrl: this.clinicHistory.triageDate,
                nineCtrl: this.clinicHistory.triageType,
                thirteenCtrl: this.clinicHistory.serviceType,
                fourteenCtrl: this.clinicHistory.servicePrice,
                fifteenCtrl: this.clinicHistory.procedureDescription,
                seventeenCtrl:this.clinicHistory.area
            });

            }

            //  this.showOtherProcedureControl = this.clinicHistory.serviceType == "24" ? true : false;

            if (this.clinicHistory.medicDiagnostic?.diseaseCode != null) {
                    this.diseaseFilterCtrl.setValue(this.clinicHistory.medicDiagnostic.diseaseCode);
            }

            if (this.clinicHistory.medicDiagnostic?.orphanDisease != null) {
                    this.orphanDiseaseFilterCtrl.setValue(this.clinicHistory.medicDiagnostic.orphanDisease);
            }

            if (this.patientInformation.id > 0) {
                if (next) this.stepper.next();
                 else this.close();
            }
            else
            {
              this.patientInformation.id = data;
              this.patient.patientInformationId = data;

              this._personService.addOrUpdatePerson(this.patient).subscribe(data => {

                  if (next)
                  {
                    this.stepper.next();
                  }
                  else this.close();

              });

            }
                // console.log('At exit createOrUpdatePatientInformation  clinicHistory is:', this.clinicHistory);
        });
    }

//#endregion

//#region updateClinicHistory
    updateClinicHistory(next: boolean, step: string): void
    {
      // console.log("Al entrar a updateClinicHistoryBody  this.clinicHistory es:", this.clinicHistory);
        if(step=="2")
        {
   //       if(this.profesionalSelected==null || this.profesionalSelected==undefined){
            if(this.profesionalSelected==null || this.profesionalSelected==undefined  )
            {

             this.toastr.error('Profesional no seleccionado', 'Debe elegir un profesional');
            }
            else{
            this.updateClinicHistoryBody(next,step);

            }
        }
        else
        {
          // console.log('En updateClinicHistory else, next es:', next, ' y step es:', step);
          this.updateClinicHistoryBody(next,step);
        }

    }

    updateClinicHistoryBody(next: boolean, step: string):void
    {

       console.log('this.categoryService es:', this.categoryService);


      this.clinicHistory.clinicHistoryObservations?.forEach(x => { if (x.id < 0) { x.id = 0; x.creationUserId = parseInt(localStorage?.getItem('Id')) } });
      this.clinicHistory.medicalFormula?.forEach(x => { if (x.id < 0) { x.id = 0; x.creationUserId = parseInt(localStorage?.getItem('Id')) } });

      if (this.showOnlyObservations)
      {
          this.busy = this._clinicHistoryService.updateClinicHistory(this.clinicHistory, this.stepper.selectedIndex.toString()).subscribe(data => {
              //this.toastr.success('Historia Clinica Actualizada', 'Historia Clinica Actualizada Exitosamente');
              this.close();
          });
      }
      else
      {

        if(this.categoryService=="cmg" || this.categoryService=="cmc")
        {

          const clnicHistoryInput: BOClinicHistory = {
              id: this.clinicHistory.id,
              patientId: this.patient.id,
              profesional:this.profesionals.find(x => x.id == this.profesionalSelected),
              consultationDate: new Date(this.thirdFormGroup.get('firstCtrl')!.value),
              ipsId: this.ipsList.find(x => x.code == this.thirdFormGroup.get('secondCtrl')!.value).id,
              serviceGroups: this.thirdFormGroup.get('threeCtrl')!.value,
              healthTechnologyModality: this.thirdFormGroup.get('fourCtrl')!.value,
              placeOfAttention: this.thirdFormGroup.get('fifthCtrl')!.value,
              routeOfEntry: this.thirdFormGroup.get('sixCtrl')!.value,
              consultationCause: this.thirdFormGroup.get('sevenCtrl')!.value,
              triageDate: new Date(this.thirdFormGroup.get('eightCtrl')!.value),
              triageType: this.thirdFormGroup.get('nineCtrl')!.value,
              clinicHistoryObservations: this.clinicHistory.clinicHistoryObservations,
              medicalFormula: this.clinicHistory.medicalFormula,
              serviceType: this.thirdFormGroup.get('thirteenCtrl')!.value,
              servicePrice: this.thirdFormGroup.get('fourteenCtrl')!.value,
              procedureDescription: this.thirdFormGroup.get('fifteenCtrl')!.value,
              pathologicalRecord: this.sixFormGroup.get('firstCtrl')!.value,
              consultationMotive: this.sixFormGroup.get('secondCtrl')!.value,
              anamnesis: this.sixFormGroup.get('threeCtrl')!.value,
              arterialPressure: this.sixFormGroup.get('fourCtrl')!.value,
              temperature: this.sixFormGroup.get('fifthCtrl')!.value,
              pulse: this.sixFormGroup.get('sixCtrl')!.value,
              weight: this.sixFormGroup.get('sevenCtrl')!.value,
              size: this.sixFormGroup.get('eightCtrl')!.value,
              breathing: this.sixFormGroup.get('nineCtrl')!.value,
              head: this.sixFormGroup.get('tenCtrl')!.value,
              neck: this.sixFormGroup.get('elevenCtrl')!.value,
              chest: this.sixFormGroup.get('twelveCtrl')!.value,
              belly: this.sixFormGroup.get('thirteenCtrl')!.value,
              pelvis: this.sixFormGroup.get('fourteenCtrl')!.value,
              extremities: this.sixFormGroup.get('fiftheenCtrl')!.value,
              skin: this.sixFormGroup.get('sixteenCtrl')!.value,
              centralNervousSystem: this.sixFormGroup.get('seventeenCtrl')!.value,
              diagnosticImpression: this.sixFormGroup.get('eighteenCtrl')!.value,
              laboratoryControl: this.sixFormGroup.get('nineteenCtrl')!.value,
              treatmentPlan: this.sixFormGroup.get('twentyCtrl')!.value,
              //
              controlConsultation: 0,
          }
          console.log('En updateClinicHistoryBody, clinicHistoryInput es:', clnicHistoryInput);

          if (this.clinicHistory.medicDiagnostic != null) {
              clnicHistoryInput.medicDiagnostic = this.clinicHistory.medicDiagnostic;
              clnicHistoryInput.medicDiagnostic.diseaseCode = this.sixFormGroup.get('twentyOneCtrl')!.value;
              clnicHistoryInput.medicDiagnostic.orphanDisease = this.sixFormGroup.get('twentyTwoCtrl')!.value;
              clnicHistoryInput.medicDiagnostic.diagnosticType = this.sixFormGroup.get('twentyThreeCtrl')!.value;
          }
          else
          {
              clnicHistoryInput.medicDiagnostic = new BOMedicDiagnostic();
              clnicHistoryInput.medicDiagnostic.id = 0;
              clnicHistoryInput.medicDiagnostic.diseaseCode = this.sixFormGroup.get('twentyOneCtrl')!.value;
              clnicHistoryInput.medicDiagnostic.orphanDisease = this.sixFormGroup.get('twentyTwoCtrl')!.value;
              clnicHistoryInput.medicDiagnostic.diagnosticType = this.sixFormGroup.get('twentyThreeCtrl')!.value;
          }

          if(this.categoryService=='cmc')
          {
            this.clinicHistoryControl.controlConsultation=1;
            console.log('clnicHistoryInput es:', clnicHistoryInput);
            console.log(`serviceType en clnicHistoryInput es: ${clnicHistoryInput.serviceType}
                        id es  ${clnicHistoryInput.id}`);
            console.log('clnicHistorycontrol es:',this.clinicHistoryControl, 'id es:',
                        this.clinicHistoryControl.id, 'serviceType es:',
                        this.clinicHistoryControl.serviceType);



           this.busy = this._clinicHistoryService.updateClinicHistory(clnicHistoryInput, this.stepper.selectedIndex.toString()).subscribe(data => {
            this._clinicHistoryService.getClinicHistory(this.clinicHistory.id).subscribe(data =>
            {
                this.clinicHistory = data;

                this._clinicHistoryService.updateClinicHistory(this.clinicHistoryControl, this.stepper.selectedIndex.toString()).subscribe(data=>{
                  console.log('Entra al subscribe del clinicHistoryService.updateClinicHistory control');

                  // let allowed=["01","08","09", "37","38"];
                  // let passElement=false;

                  // passElement=this.verifyServiceNumber(allowed);
                  // console.log(`passElement es: ${passElement}`);
                  // if (!passElement)
                  // {
                  //       this.close();
                  // }

                  // else
                  // {
                        if (this.clinicHistory.healthTechnologies != null)
                        {
                            this.fourFormGroup.patchValue({
                                firstCtrl: this.clinicHistory.healthTechnologies.healthTechnology,
                                secondCtrl: this.clinicHistory.healthTechnologies.healthTecnologyFinality,
                                threeCtrl: this.clinicHistory.healthTechnologies.prescriptionDate,
                                fourCtrl: this.clinicHistory.healthTechnologies.healthTecnologyMedicamentDescription,
                                fifthCtrl: this.clinicHistory.healthTechnologies.prescriptionMedicationDose.quantity,
                                sixCtrl: this.clinicHistory.healthTechnologies.prescriptionMedicationDose.measurementMedicationUnits,
                                sevenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyVad,
                                eightCtrl: this.clinicHistory.healthTechnologies.healthTechnologyTerm.quantity,
                                nineCtrl: this.clinicHistory.healthTechnologies.healthTechnologyTerm.timeUnit,
                                tenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.quantity,
                                elevenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.timeUnit,
                                twelveCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.quantity,
                                thirteenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.measurementMedicationUnits,
                                fourteenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredDate,
                                sixteenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.measurementMedicationUnits,
                                fiftheenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.quantity,
                                seventeenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.quantity,
                                eighteenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.measurementMedicationUnits,
                            });
                        }
                        else
                        {
                            this.fourFormGroup.patchValue({
                                fifthCtrl: 0,
                                eightCtrl: 0,
                                tenCtrl: 0,
                                twelveCtrl: 0,
                                fiftheenCtrl: 0,
                                seventeenCtrl: 0
                            });
                        }

                        if (next)
                        {
                            if (this.stepper.selectedIndex != 7)
                              {
                                this.stepper.next();
                              }
                        }
                        else this.close();

                 // }

                })
            })

           });

          }

          if(this.categoryService=='cmg')
          {

            this.busy = this._clinicHistoryService.updateClinicHistory(clnicHistoryInput, this.stepper.selectedIndex.toString()).subscribe(data => {
              this._clinicHistoryService.getClinicHistory(this.clinicHistory.id).subscribe(data =>
              {
                  this.clinicHistory = data;

                // let allowed=["01","08","09", "37","38"];
                // let passElement=false;

                // passElement=this.verifyServiceNumber(allowed);

                // if (!passElement)
                // {
                //       this.close();
                // }

                // else
                // {
                      if (this.clinicHistory.healthTechnologies != null)
                      {
                          this.fourFormGroup.patchValue({
                              firstCtrl: this.clinicHistory.healthTechnologies.healthTechnology,
                              secondCtrl: this.clinicHistory.healthTechnologies.healthTecnologyFinality,
                              threeCtrl: this.clinicHistory.healthTechnologies.prescriptionDate,
                              fourCtrl: this.clinicHistory.healthTechnologies.healthTecnologyMedicamentDescription,
                              fifthCtrl: this.clinicHistory.healthTechnologies.prescriptionMedicationDose.quantity,
                              sixCtrl: this.clinicHistory.healthTechnologies.prescriptionMedicationDose.measurementMedicationUnits,
                              sevenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyVad,
                              eightCtrl: this.clinicHistory.healthTechnologies.healthTechnologyTerm.quantity,
                              nineCtrl: this.clinicHistory.healthTechnologies.healthTechnologyTerm.timeUnit,
                              tenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.quantity,
                              elevenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.timeUnit,
                              twelveCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.quantity,
                              thirteenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.measurementMedicationUnits,
                              fourteenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredDate,
                              sixteenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.measurementMedicationUnits,
                              fiftheenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.quantity,
                              seventeenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.quantity,
                              eighteenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.measurementMedicationUnits,
                          });
                      }
                      else
                      {
                          this.fourFormGroup.patchValue({
                              fifthCtrl: 0,
                              eightCtrl: 0,
                              tenCtrl: 0,
                              twelveCtrl: 0,
                              fiftheenCtrl: 0,
                              seventeenCtrl: 0
                          });
                      }

                      if (next)
                      {
                          if (this.stepper.selectedIndex != 7)
                            {
                              this.stepper.next();
                            }
                      }
                      else this.close();

                //  }

              })

            });
          }

        }

        else
        {
            console.log('UpdateClinicHistoryBody-> entra a clinicHistoryInput edi');
            const clnicHistoryInput: BOClinicHistory = {
                id: this.clinicHistory.id,
                patientId: this.patient.id,
                profesional:this.profesionals.find(x => x.id == this.profesionalSelected),
                consultationDate: new Date(this.thirdFormGroup.get('firstCtrl')!.value),
                ipsId: this.ipsList.find(x => x.code == this.thirdFormGroup.get('secondCtrl')!.value).id,
                serviceGroups: this.thirdFormGroup.get('threeCtrl')!.value,
                healthTechnologyModality: this.thirdFormGroup.get('fourCtrl')!.value,
                placeOfAttention: this.thirdFormGroup.get('fifthCtrl')!.value,
                routeOfEntry: this.thirdFormGroup.get('sixCtrl')!.value,
                consultationCause: this.thirdFormGroup.get('sevenCtrl')!.value,
                triageDate: new Date(this.thirdFormGroup.get('eightCtrl')!.value),
                triageType: this.thirdFormGroup.get('nineCtrl')!.value,
                clinicHistoryObservations: this.clinicHistory.clinicHistoryObservations,
                medicalFormula: this.clinicHistory.medicalFormula,
                serviceType: this.thirdFormGroup.get('thirteenCtrl')!.value,
                servicePrice: this.thirdFormGroup.get('fourteenCtrl')!.value,
                procedureDescription: this.thirdFormGroup.get('fifteenCtrl')!.value,
                pathologicalRecord: this.sixFormGroup.get('firstCtrl')!.value,
                consultationMotive: this.sixFormGroup.get('secondCtrl')!.value,
                anamnesis: this.sixFormGroup.get('threeCtrl')!.value,
                arterialPressure: this.sixFormGroup.get('fourCtrl')!.value,
                temperature: this.sixFormGroup.get('fifthCtrl')!.value,
                pulse: this.sixFormGroup.get('sixCtrl')!.value,
                weight: this.sixFormGroup.get('sevenCtrl')!.value,
                size: this.sixFormGroup.get('eightCtrl')!.value,
                breathing: this.sixFormGroup.get('nineCtrl')!.value,
                head: this.sixFormGroup.get('tenCtrl')!.value,
                neck: this.sixFormGroup.get('elevenCtrl')!.value,
                chest: this.sixFormGroup.get('twelveCtrl')!.value,
                belly: this.sixFormGroup.get('thirteenCtrl')!.value,
                pelvis: this.sixFormGroup.get('fourteenCtrl')!.value,
                extremities: this.sixFormGroup.get('fiftheenCtrl')!.value,
                skin: this.sixFormGroup.get('sixteenCtrl')!.value,
                centralNervousSystem: this.sixFormGroup.get('seventeenCtrl')!.value,
                diagnosticImpression: this.sixFormGroup.get('eighteenCtrl')!.value,
                laboratoryControl: this.sixFormGroup.get('nineteenCtrl')!.value,
                treatmentPlan: this.sixFormGroup.get('twentyCtrl')!.value,
                controlConsultation:this.clinicHistory.controlConsultation
            }
            console.log('UpdateClinicHistoryBody-> clinicHistoryInput en edi es', clnicHistoryInput);


            if (this.clinicHistory.medicDiagnostic != null) {
                clnicHistoryInput.medicDiagnostic = this.clinicHistory.medicDiagnostic;
                clnicHistoryInput.medicDiagnostic.diseaseCode = this.sixFormGroup.get('twentyOneCtrl')!.value;
                clnicHistoryInput.medicDiagnostic.orphanDisease = this.sixFormGroup.get('twentyTwoCtrl')!.value;
                clnicHistoryInput.medicDiagnostic.diagnosticType = this.sixFormGroup.get('twentyThreeCtrl')!.value;
            }
            else {
                clnicHistoryInput.medicDiagnostic = new BOMedicDiagnostic();
                clnicHistoryInput.medicDiagnostic.id = 0;
                clnicHistoryInput.medicDiagnostic.diseaseCode = this.sixFormGroup.get('twentyOneCtrl')!.value;
                clnicHistoryInput.medicDiagnostic.orphanDisease = this.sixFormGroup.get('twentyTwoCtrl')!.value;
                clnicHistoryInput.medicDiagnostic.diagnosticType = this.sixFormGroup.get('twentyThreeCtrl')!.value;
            }


            this.busy = this._clinicHistoryService
            .updateClinicHistory(clnicHistoryInput, this.stepper.selectedIndex.toString())
            .subscribe(data => {
                this._clinicHistoryService.getClinicHistory(this.clinicHistory.id).subscribe(data => {
                    this.clinicHistory = data;
                    console.log('En updateClinicHistoryBody EDI, clinicHistory To clave es:', this.clinicHistory);

                    // let allowed=["01","08","09", "37","38"];
                    // let passElement=false;

                    // passElement=this.verifyServiceNumber(allowed);

                    // if (!passElement)
                    // {
                    //       this.close();
                    // }

                    // else
                    // {
                        if (this.clinicHistory.healthTechnologies != null)
                            {
                              this.fourFormGroup.patchValue({
                                  firstCtrl: this.clinicHistory.healthTechnologies.healthTechnology,
                                  secondCtrl: this.clinicHistory.healthTechnologies.healthTecnologyFinality,
                                  threeCtrl: this.clinicHistory.healthTechnologies.prescriptionDate,
                                  fourCtrl: this.clinicHistory.healthTechnologies.healthTecnologyMedicamentDescription,
                                  fifthCtrl: this.clinicHistory.healthTechnologies.prescriptionMedicationDose.quantity,
                                  sixCtrl: this.clinicHistory.healthTechnologies.prescriptionMedicationDose.measurementMedicationUnits,
                                  sevenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyVad,
                                  eightCtrl: this.clinicHistory.healthTechnologies.healthTechnologyTerm.quantity,
                                  nineCtrl: this.clinicHistory.healthTechnologies.healthTechnologyTerm.timeUnit,
                                  tenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.quantity,
                                  elevenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.timeUnit,
                                  twelveCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.quantity,
                                  thirteenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.measurementMedicationUnits,
                                  fourteenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredDate,
                                  sixteenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.measurementMedicationUnits,
                                  fiftheenCtrl: this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.quantity,
                                  seventeenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.quantity,
                                  eighteenCtrl: this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.measurementMedicationUnits,
                              });
                        }
                        else
                            {
                              this.fourFormGroup.patchValue({
                                  fifthCtrl: 0,
                                  eightCtrl: 0,
                                  tenCtrl: 0,
                                  twelveCtrl: 0,
                                  fiftheenCtrl: 0,
                                  seventeenCtrl: 0
                              });
                        }

                        if (next)
                          {
                              if (this.stepper.selectedIndex != 7) this.stepper.next();

                          }
                          else this.close();
                 //   }
                })

            });

        }

      }

    }


//#endregion


//#region updateHealthTechnologies

    updateHealthTechnologies(next: boolean): void
    {
        if (this.clinicHistory.healthTechnologies == null) {
            this.clinicHistory.healthTechnologies = new BOHealthTechnologies();
            this.clinicHistory.healthTechnologies.id = 0;
        }
        this.clinicHistory.healthTechnologies.healthTechnology = this.fourFormGroup.get('firstCtrl')!.value;
        this.clinicHistory.healthTechnologies.healthTecnologyFinality = this.fourFormGroup.get('secondCtrl')!.value;
        this.clinicHistory.healthTechnologies.prescriptionDate = this.fourFormGroup.get('threeCtrl').value != undefined ? new Date(this.fourFormGroup.get('threeCtrl').value) : null;
        this.clinicHistory.healthTechnologies.healthTecnologyMedicamentDescription = this.fourFormGroup.get('fourCtrl')!.value;
        if (this.clinicHistory.healthTechnologies.prescriptionMedicationDose == null) {
            this.clinicHistory.healthTechnologies.prescriptionMedicationDose = new BOPrescriptionMedicationDose();
            this.clinicHistory.healthTechnologies.prescriptionMedicationDose.id = 0;
        }
        this.clinicHistory.healthTechnologies.prescriptionMedicationDose.quantity = this.fourFormGroup.get('fifthCtrl')!.value;
        this.clinicHistory.healthTechnologies.prescriptionMedicationDose.measurementMedicationUnits = this.fourFormGroup.get('sixCtrl')!.value;
        this.clinicHistory.healthTechnologies.healthTechnologyVad = this.fourFormGroup.get('sevenCtrl')!.value;

        if (this.clinicHistory.healthTechnologies.healthTechnologyTerm == null) {
            this.clinicHistory.healthTechnologies.healthTechnologyTerm = new BOHealthTechnologyTerm();
            this.clinicHistory.healthTechnologies.healthTechnologyTerm.id = 0;
        }
        this.clinicHistory.healthTechnologies.healthTechnologyTerm.quantity = this.fourFormGroup.get('eightCtrl')!.value;
        this.clinicHistory.healthTechnologies.healthTechnologyTerm.timeUnit = this.fourFormGroup.get('nineCtrl')!.value;

        if (this.clinicHistory.healthTechnologies.healthTechnologyFrecuency == null) {
            this.clinicHistory.healthTechnologies.healthTechnologyFrecuency = new BOHealthTechnologyFrecuency();
            this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.id = 0;
        }
        this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.quantity = this.fourFormGroup.get('tenCtrl')!.value;
        this.clinicHistory.healthTechnologies.healthTechnologyFrecuency.timeUnit = this.fourFormGroup.get('elevenCtrl')!.value;

        if (this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken == null) {
            this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken = new BOHealthTechnologyMedicamentDoseTaken();
            this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.id = 0;
        }
        this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.quantity = this.fourFormGroup.get('twelveCtrl')!.value;
        this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseTaken.measurementMedicationUnits = this.fourFormGroup.get('thirteenCtrl')!.value;

        if (this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered == null) {
            this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered = new BOHealthTechnologyMedicamentDoseDelivered();
            this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.id = 0;
        }
        this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.quantity = this.fourFormGroup.get('fiftheenCtrl')!.value;
        this.clinicHistory.healthTechnologies.healthTechnologyMedicamentDoseDelivered.measurementMedicationUnits = this.fourFormGroup.get('sixteenCtrl')!.value;

        if (this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits == null) {
            this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits = new BOHealthTecnologyDeliveredUnits();
            this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.id = 0;
        }
        this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.quantity = this.fourFormGroup.get('seventeenCtrl')!.value;
        this.clinicHistory.healthTechnologies.healthTecnologyDeliveredUnits.measurementMedicationUnits = this.fourFormGroup.get('eighteenCtrl')!.value;

        this.clinicHistory.healthTechnologies.healthTecnologyDeliveredDate = this.fourFormGroup.get('fourteenCtrl').value != undefined ? new Date(this.fourFormGroup.get('fourteenCtrl').value) : null;

        //console.log(this.clinicHistory.healthTechnologies);



     this.busy = this._clinicHistoryService.updateClinicHistory(this.clinicHistory, this.stepper.selectedIndex.toString().trim()).subscribe(data =>
        {
            this._clinicHistoryService.getClinicHistory(this.clinicHistory.id).subscribe(data => {
                this.clinicHistory = data;
                //this.toastr.success('Historia Clinica Actualizada', 'Historia Clinica Actualizada Exitosamente');
                if (this.clinicHistory.healthTechnologiesResult != null)
                {
                    this.fifthFormGroup.patchValue({
                        firstCtrl: this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic?.diseaseCode,
                        secondCtrl: this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic?.orphanDisease,
                        threeCtrl: this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic?.diagnosticType,
                        fourCtrl: this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic?.diseaseCode,
                        fifthCtrl: this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic?.orphanDisease,
                        sixCtrl: this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic?.diseaseCode,
                        sevenCtrl: this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic?.orphanDisease,
                        eightCtrl: this.clinicHistory.healthTechnologiesResult.patientCondition,
                        nineCtrl: this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic?.diseaseCode,
                        tenCtrl: this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic?.orphanDisease,
                        elevenCtrl: this.clinicHistory.healthTechnologiesResult.medicalAttentionFinalizationDate,
                        thirteenCtrl: this.clinicHistory.healthTechnologiesResult.medicIncapacityType,
                        fourteenCtrl: this.clinicHistory.healthTechnologiesResult.medicIncapacityDays,
                        fiftheenCtrl: this.clinicHistory.healthTechnologiesResult.medicMaternityLicense
                    });
                    if (this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic?.diseaseCode != null) {
                        this.diseaseExitFilterCtrl.setValue(this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic.diseaseCode);
                    }
                    if (this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic?.orphanDisease != null) {
                        this.orphanDiseaseExitFilterCtrl.setValue(this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic.orphanDisease);
                    }
                    if (this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic?.diseaseCode != null) {
                        this.diseaseRelatedFilterCtrl.setValue(this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic.diseaseCode);
                    }
                    if (this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic?.orphanDisease != null) {
                        this.orphanDiseaseRelatedFilterCtrl.setValue(this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic.orphanDisease);
                    }
                    if (this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic?.diseaseCode != null) {
                        this.diseaseComplicationFilterCtrl.setValue(this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic.diseaseCode);
                    }
                    if (this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic?.orphanDisease != null) {
                        this.orphanDiseaseComplicationFilterCtrl.setValue(this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic.orphanDisease);
                    }
                    if (this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic?.diseaseCode != null) {
                        this.diseaseDeathFilterCtrl.setValue(this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic.diseaseCode);
                    }
                    if (this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic?.orphanDisease != null) {
                        this.orphanDiseaseDeathFilterCtrl.setValue(this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic.orphanDisease);
                    }
                }
                else {
                    this.fifthFormGroup.patchValue({
                        fourteenCtrl: 0,
                        fiftheenCtrl: 0
                    });
                }

                let allowed=["01","08","09", "37","38"];
                 let passElement=false;

                 passElement=this.verifyServiceNumber(allowed);

                 if (!passElement)
                 {
                       this.close();
                 }



                if (next) this.stepper.next();
                else this.close();
            })

        });
    }
//#endregion

//#region updateHealthTechologiesResult


    updateHealthTechnologiesResult(next: boolean): void {
        if (this.clinicHistory.healthTechnologiesResult == null) {
            this.clinicHistory.healthTechnologiesResult = new BOHealthTechnologiesResult();
            this.clinicHistory.healthTechnologiesResult.id = 0;
        }

        if (this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic == null) {
            this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic = new BOPrincipalMedicDiagnostic();
            this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic.id = 0;
        }
        this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic.diseaseCode = this.fifthFormGroup.get('firstCtrl')!.value;
        this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic.orphanDisease = this.fifthFormGroup.get('secondCtrl')!.value;
        this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic.diagnosticType = this.fifthFormGroup.get('threeCtrl')!.value;

        if (this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic == null) {
            this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic = new BORelatedMedicDiagnostic();
            this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic.id = 0;
        }
        this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic.diseaseCode = this.fifthFormGroup.get('fourCtrl')!.value;
        this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic.orphanDisease = this.fifthFormGroup.get('fifthCtrl')!.value;

        if (this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic == null) {
            this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic = new BOComplicationMedicDiagnostic();
            this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic.id = 0;
        }
        this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic.diseaseCode = this.fifthFormGroup.get('sixCtrl')!.value;
        this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic.orphanDisease = this.fifthFormGroup.get('sevenCtrl')!.value;

        this.clinicHistory.healthTechnologiesResult.patientCondition = this.fifthFormGroup.get('eightCtrl')!.value;

        if (this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic == null) {
            this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic = new BODeathMedicDiagnostic();
            this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic.id = 0;
        }
        this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic.diseaseCode = this.fifthFormGroup.get('nineCtrl')!.value;
        this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic.orphanDisease = this.fifthFormGroup.get('tenCtrl')!.value;

        this.clinicHistory.healthTechnologiesResult.medicalAttentionFinalizationDate = this.fifthFormGroup.get('elevenCtrl').value != undefined ? new Date(this.fifthFormGroup.get('elevenCtrl').value) : null;
        this.clinicHistory.healthTechnologiesResult.medicIncapacityType = this.fifthFormGroup.get('thirteenCtrl')!.value;
        this.clinicHistory.healthTechnologiesResult.medicIncapacityDays = this.fifthFormGroup.get('fourteenCtrl')!.value;
        this.clinicHistory.healthTechnologiesResult.medicMaternityLicense = this.fifthFormGroup.get('fiftheenCtrl')!.value;

        this.busy = this._clinicHistoryService.updateClinicHistory(this.clinicHistory, this.stepper.selectedIndex.toString().trim()).subscribe(data => {
            this._clinicHistoryService.getClinicHistory(this.clinicHistory.id).subscribe(data => {
                this.clinicHistory = data;
                if (next) this.stepper.next();
                else this.close();
            })

        });
    }

    //#endregion


    close(): void {
        this.modalSave.emit(null);
        this.modal.hide();
        this.stepper.reset();
        this.firstFormGroup.reset();
        this.secondFormGroup.reset();
        this.thirdFormGroup.reset();
        this.fourFormGroup.reset();
        this.patient = new BOPerson();
        this.patientInformation = new BOPatientInformation();
        this.clinicHistory = new BOClinicHistory();
        this.isSearchPatient = false;
        this.showOnlyObservations = false;
        this.submitted = false;
        this.stepperIndex = 0;
        this.panelOpenState = false;
    }

    //ObservationModal
    closeObservationModal(): void {
        var saveObservation = this.clinicHistory.clinicHistoryObservations.find(x => x.id == this.tempObservation.id);
        var index = this.clinicHistory.clinicHistoryObservations.indexOf(saveObservation);
        this.clinicHistory.clinicHistoryObservations[index] = this.tempObservation;
        this.createOrEditModalObservation.hide();
    }

    openObservationModal(): void {
        this.tempObservationId--;
        this.clinicHistory.clinicHistoryObservations.push({ id: this.tempObservationId, observation: null });
        this.tempObservation = this.clinicHistory.clinicHistoryObservations.find(x => x.id == this.tempObservationId);
        this.createOrEditModalObservation.config = { backdrop: "static", keyboard: false };
        this.createOrEditModalObservation.show();
    }

    openMedicalFormulaModal(): void {
        this.tempMedicalFormulaId--;
        var medicalFormula = new BOMedicalFormula();
        medicalFormula.id = this.tempMedicalFormulaId;

        this.createOrEditMedicalFormulaModal.openModal(this.healthTechnologyTimeUnitList, medicalFormula, true);
    }

    viewObservationModal(observationId: number): void {
        this.tempObservation = this.clinicHistory.clinicHistoryObservations.find(x => x.id == observationId);
        this.createOrEditModalObservation.config = { backdrop: "static", keyboard: false };
        this.createOrEditModalObservation.show();
    }

    deleteObservation(observationId: number): void {
        var deleteField = this.clinicHistory.clinicHistoryObservations.find(x => x.id == observationId);
        var index = this.clinicHistory.clinicHistoryObservations.indexOf(deleteField);
        if (index > -1) {
            this.clinicHistory.clinicHistoryObservations.splice(index, 1);
        }
    }

    deleteMedicalFormula(formulaId: number): void {
        var deleteField = this.clinicHistory.medicalFormula.find(x => x.id == formulaId);
        var index = this.clinicHistory.medicalFormula.indexOf(deleteField);
        if (index > -1) {
            this.clinicHistory.medicalFormula.splice(index, 1);
        }
    }
    //-------------------------------

    protected filterCountries() {
        if (!this.countries) {
            return;
        }
        // get the search keyword
        let search = this.countryFilterCtrl.value;
        if (!search) {
            this.filteredCountries.next(this.countries.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredCountries.next(
            this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1)
        );
    }

    protected filterOrphanDiseases() {
        if (!this.orphanDiseaseList) {
            return;
        }
        // get the search keyword
        let search = this.orphanDiseaseFilterCtrl.value;
        if (!search) {
            this.filteredOrphanDiseases.next(this.orphanDiseaseList.slice());
            return;
        } else {
            this.searchingOrphanDisease = true;
            this._orphanDiseaseService.getAll(search).subscribe(data => {
                this.orphanDiseaseList = data;
                this.filteredOrphanDiseases.next(data);
                this.searchingOrphanDisease = false;
            }, error => {
                this.filteredOrphanDiseases.next(this.orphanDiseaseList.slice());
                this.searchingOrphanDisease = false;
            });
        }
    }

    protected filterOrphanDiseasesRelated() {
        if (!this.orphanDiseaseRelatedList) {
            return;
        }
        // get the search keyword
        let search = this.orphanDiseaseRelatedFilterCtrl.value;
        if (!search) {
            this.filteredOrphanDiseasesRelated.next(this.orphanDiseaseRelatedList.slice());
            return;
        } else {
            this.searchingOrphanDisease = true;
            this._orphanDiseaseService.getAll(search).subscribe(data => {
                this.orphanDiseaseRelatedList = data;
                this.filteredOrphanDiseasesRelated.next(data);
                this.searchingOrphanDisease = false;
            }, error => {
                this.filteredOrphanDiseasesRelated.next(this.orphanDiseaseRelatedList.slice());
                this.searchingOrphanDisease = false;
            });
        }
    }

    protected filterOrphanDiseasesExit() {
        if (!this.orphanDiseaseExitList) {
            return;
        }
        // get the search keyword
        let search = this.orphanDiseaseExitFilterCtrl.value;
        if (!search) {
            this.filteredOrphanDiseasesExit.next(this.orphanDiseaseExitList.slice());
            return;
        } else {
            this.searchingOrphanDisease = true;
            this._orphanDiseaseService.getAll(search).subscribe(data => {
                this.orphanDiseaseExitList = data;
                this.filteredOrphanDiseasesExit.next(data);
                this.searchingOrphanDisease = false;
            }, error => {
                this.filteredOrphanDiseasesExit.next(this.orphanDiseaseExitList.slice());
                this.searchingOrphanDisease = false;
            });
        }
    }

    protected filterOrphanDiseasesComplication() {
        if (!this.orphanDiseaseComplicationList) {
            return;
        }
        // get the search keyword
        let search = this.orphanDiseaseComplicationFilterCtrl.value;
        if (!search) {
            this.filteredOrphanDiseasesComplication.next(this.orphanDiseaseComplicationList.slice());
            return;
        } else {
            this.searchingOrphanDisease = true;
            this._orphanDiseaseService.getAll(search).subscribe(data => {
                this.orphanDiseaseComplicationList = data;
                this.filteredOrphanDiseasesComplication.next(data);
                this.searchingOrphanDisease = false;
            }, error => {
                this.filteredOrphanDiseasesComplication.next(this.orphanDiseaseComplicationList.slice());
                this.searchingOrphanDisease = false;
            });
        }
    }

    protected filterOrphanDiseasesDeath() {
        if (!this.orphanDiseaseDeathList) {
            return;
        }
        // get the search keyword
        let search = this.orphanDiseaseDeathFilterCtrl.value;
        if (!search) {
            this.filteredOrphanDiseasesDeath.next(this.orphanDiseaseDeathList.slice());
            return;
        } else {
            this.searchingOrphanDisease = true;
            this._orphanDiseaseService.getAll(search).subscribe(data => {
                this.orphanDiseaseDeathList = data;
                this.filteredOrphanDiseasesDeath.next(data);
                this.searchingOrphanDisease = false;
            }, error => {
                this.filteredOrphanDiseasesDeath.next(this.orphanDiseaseDeathList.slice());
                this.searchingOrphanDisease = false;
            });
        }
    }

    protected filterDiseases() {
        if (!this.diseaseList) {
            return;
        }
        // get the search keyword
        let search = this.diseaseFilterCtrl.value;
        if (!search) {
            this.filteredDiseases.next(this.diseaseList.slice());
            return;
        } else {
            this.searchingDisease = true;
            this._diseaseService.getAll(search).subscribe(data => {
                this.diseaseList = data;
                this.filteredDiseases.next(data);
                this.searchingDisease = false;
            }, error => {
                this.filteredDiseases.next(this.diseaseList.slice());
                this.searchingDisease = false;
            });
        }
    }

    protected filterDiseasesRelated() {
        if (!this.diseaseListRelated) {
            return;
        }
        // get the search keyword
        let search = this.diseaseRelatedFilterCtrl.value;
        if (!search) {
            this.filteredDiseasesRelated.next(this.diseaseListRelated.slice());
            return;
        } else {
            this.searchingDisease = true;
            this._diseaseService.getAll(search).subscribe(data => {
                this.diseaseListRelated = data;
                this.filteredDiseasesRelated.next(data);
                this.searchingDisease = false;
            }, error => {
                this.filteredDiseasesRelated.next(this.diseaseListRelated.slice());
                this.searchingDisease = false;
            });
        }
    }

    protected filterDiseasesExit() {
        if (!this.diseaseListExit) {
            return;
        }
        // get the search keyword
        let search = this.diseaseExitFilterCtrl.value;
        if (!search) {
            this.filteredDiseasesExit.next(this.diseaseListExit.slice());
            return;
        } else {
            this.searchingDisease = true;
            this._diseaseService.getAll(search).subscribe(data => {
                this.diseaseListExit = data;
                this.filteredDiseasesExit.next(data);
                this.searchingDisease = false;
            }, error => {
                this.filteredDiseasesExit.next(this.diseaseListExit.slice());
                this.searchingDisease = false;
            });
        }
    }

    protected filterDiseasesComplication() {
        if (!this.diseaseListComplication) {
            return;
        }
        // get the search keyword
        let search = this.diseaseComplicationFilterCtrl.value;
        if (!search) {
            this.filteredDiseasesComplication.next(this.diseaseListComplication.slice());
            return;
        } else {
            this.searchingDisease = true;
            this._diseaseService.getAll(search).subscribe(data => {
                this.diseaseListComplication = data;
                this.filteredDiseasesComplication.next(data);
                this.searchingDisease = false;
            }, error => {
                this.filteredDiseasesComplication.next(this.diseaseListComplication.slice());
                this.searchingDisease = false;
            });
        }
    }

    protected filterDiseasesDeath() {
        if (!this.diseaseListDeath) {
            return;
        }
        // get the search keyword
        let search = this.diseaseDeathFilterCtrl.value;
        if (!search) {
            this.filteredDiseasesDeath.next(this.diseaseListDeath.slice());
            return;
        } else {
            this.searchingDisease = true;
            this._diseaseService.getAll(search).subscribe(data => {
                this.diseaseListDeath = data;
                this.filteredDiseasesDeath.next(data);
                this.searchingDisease = false;
            }, error => {
                this.filteredDiseasesDeath.next(this.diseaseListDeath.slice());
                this.searchingDisease = false;
            });
        }
    }

    protected filterEps() {
        if (!this.epsList) {
            return;
        }
        // get the search keyword
        let search = this.epsFilterCtrl.value;
        if (!search) {
            this.filteredEps.next(this.epsList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredEps.next(
            this.epsList.filter(eps => eps.name.toLowerCase().indexOf(search) > -1)
        );
    }

    protected filterOcupation() {
        if (!this.personOcupationList) {
            return;
        }
        // get the search keyword
        let search = this.ocupationFilterCtrl.value;
        if (!search) {
            this.filteredOcupation.next(this.personOcupationList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredOcupation.next(
            this.personOcupationList.filter(ocupation => ocupation.description.toLowerCase().indexOf(search) > -1)
        );
    }

    onOptionStateSelected(state: any) {
        if (state != null) {
            this.busy = this._cityService.getCitiesbyState(state).subscribe(data => { this.cities = data });
        }
    }

    onOptionGroupSelected(service: any)
    {
     if (service != null)
      {
          this.serviceTypeList=[];
          this.busy = this._serviceTypeService.getServicesByGroupService(service)
          .subscribe(data => {
            this.serviceTypeList = data;
          });
      }
    console.log('service enviado es:', service);
     let tempDat:BOItem[]=[];
     for(let i=0;i<this.serviceTypeList.length; i++)
      {
        if (this.serviceTypeList[i].serviceGroup==service)
          {
            tempDat.push(this.serviceTypeList[i]);
          }
      }
      this.serviceTypeList=tempDat;
      console.log('this.serviceTypeList es:', this.serviceTypeList);
  }


  onOptionServiceTypeSelected(service: any)
  {
    console.log('service es:',service, ' y El tipo recibido es: ', typeof service);
    let converted= parseInt(service);
    console.log('converted es:', converted);
   if (service != null)
    {
        this.subServiceTypeList=[];
        this.busy = this._subServiceTypeService.getSubServicesByService(service)
        .subscribe(data => {
          this.subServiceTypeList = data;
        });
    }
  console.log('service enviado es:', service);
   let tempDat:BOSubServiceType[]=[];
   for(let i=0;i<this.subServiceTypeList.length; i++)
    {
      if (this.subServiceTypeList[i].serviceTypeId==service)
        {
          tempDat.push(this.subServiceTypeList[i]);
        }
    }
    this.subServiceTypeList=tempDat;
    console.log('this.serviceTypeList es:', this.subServiceTypeList);


  }

    processMedicalFormula(medicalFormulaInput) {
        if (medicalFormulaInput != null)
        {
            if (medicalFormulaInput.id == 0)
            {
                this.tempMedicalFormulaId--;
                medicalFormulaInput.id = this.tempMedicalFormulaId;
            }
            this.clinicHistory.medicalFormula.push(medicalFormulaInput);
        }
    }

    generatePDF() {
        const documentDefinition = this.getDocumentDefinition();
        pdfMake.createPdf(documentDefinition).open();
    }


    getDocumentDefinition() {
        return {
            pageSize: 'A4',
            pageMargins: [20, 80, 20, 60],
            header: [
                {
                    bold: true,
                    fontSize: 20,
                    columns: [
                        {
                            text: this.company.name,
                            margin: [20, 10, 20, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    fontSize: 14,
                    columns: [
                        {
                            text: 'NIT ' + this.company.nit,
                            margin: [20, 0, 20, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    fontSize: 14,
                    columns: [
                        {
                            text: this.company.email,
                            margin: [20, 0, 20, 5],
                            width: '100%'
                        }
                    ]
                },
                {
                    canvas: [
                        { type: 'line', x1: 10, y1: 0, x2: 595 - 10, y2: 0, lineWidth: 0.5 }
                    ]

                }
            ],
            footer: [
                {
                    canvas: [
                        { type: 'line', x1: 10, y1: 0, x2: 595 - 10, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    fontSize: 12,
                    columns: [
                        {
                            text: this.headQuarterList[0].address,
                            margin: [20, 5, 20, 0],
                            alignment: 'right',
                            width: '100%'
                        }
                    ]
                },
                {
                    fontSize: 12,
                    columns: [
                        {
                            text: this.headQuarterList[0].phoneNumber + ' - ' + this.headQuarterList[0].cellPhoneNumber,
                            margin: [20, 0, 20, 0],
                            alignment: 'right',
                            width: '100%'
                        }
                    ]
                }
            ],
            content: [
                {
                    fontSize: 12,
                    bold: true,
                    text: 'Información del Paciente',
                },
                {
                    canvas: [
                        { type: 'line', x1: 0, y1: 0, x2: 595 - 300, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Nombre: ', bold: true }, this.patient.firstName + " " + this.patient.secondName + " " + this.patient.firtsSurName + " " + this.patient.secondSurName],
                            margin: [0, 10, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Tipo de Documento: ', bold: true }, this.idTypeList.find(x => x.code == this.patient.idType).name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Documento de Identificación: ', bold: true }, this.patient.personId],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Correo Electronico: ', bold: true }, this.patient.email],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Celular: ', bold: true }, this.patient.cellPhoneNumber],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Fecha de Nacimiento: ', bold: true }, this.datePipe.transform(this.patient.birthDate, 'dd /MM/YYYY')],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Dirección: ', bold: true }, this.patient.address],
                            margin: [0, 5, 0, 10],
                            width: '100%'
                        }
                    ]
                },
                {
                    fontSize: 12,
                    bold: true,
                    text: 'Datos del Paciente',
                },
                {
                    canvas: [
                        { type: 'line', x1: 0, y1: 0, x2: 595 - 300, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Sexo: ', bold: true }, this.biologicalSexList.find(x => x.code == this.patientInformation.sexType)?.name],
                            margin: [0, 10, 0, 0],
                            width: '33,33%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Identidad de Genero: ', bold: true }, this.genderIdentityList.find(x => x.code == this.patientInformation.genderIdentity)?.name],
                            margin: [0, 10, 0, 0],
                            width: '33,33%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Categoria Discapacidad: ', bold: true }, this.disabilityCategoryList.find(x => x.code == this.patientInformation.disabilityCategory)?.name],
                            margin: [0, 10, 0, 0],
                            width: '33,33%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Etnia: ', bold: true }, this.ethnicityList.find(x => x.code == this.patientInformation.ethnicity)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Comunidad Etnica: ', bold: true }, this.ethnicCommunityList.find(x => x.code == this.patientInformation.ethnicCommunity)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Zona Residencial: ', bold: true }, this.residenceZoneList.find(x => x.code == this.patientInformation.residenceZone)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Pais de Nacionalidad: ', bold: true }, this.countries.find(x => x.id == this.patientInformation.birthCountryId)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'EPS: ', bold: true }, this.epsList.find(x => x.id == this.patientInformation.epsId)?.name],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Ocupación: ', bold: true }, this.personOcupationList.find(x => x.id == this.patientInformation.personOcupationId)?.description],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Pais: ', bold: true }, this.countries.find(x => x.id == this.patientInformation.residenceCountryId)?.name],
                            margin: [0, 5, 0, 10],
                            width: '33,33%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Departamento: ', bold: true }, this.states.find(x => x.code == this.cities.find(ci => ci.id == this.patientInformation.residenceCityId)?.code.substring(0, 2))?.name],
                            margin: [0, 5, 0, 10],
                            width: '33,33%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Municipio: ', bold: true }, this.cities.find(x => x.id == this.patientInformation.residenceCityId)?.name],
                            margin: [0, 5, 0, 10],
                            width: '33,33%'
                        }
                    ]
                },
                {
                    fontSize: 12,
                    bold: true,
                    text: 'Datos de Ingreso',
                },
                {
                    canvas: [
                        { type: 'line', x1: 0, y1: 0, x2: 595 - 300, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    columns: [
                        {
                            fontSize: 10,
 //  Ric                         text: [{ text: 'Fecha de la Atención: ', bold: true }, this.datePipe.transform(this.clinicHistory.consultationDate, 'dd /MM/YYYY HH:mm')],
                            text: [{ text: 'Fecha de la Atención: ', bold: true }, this.datePipe.transform(this.clinicHistory.consultationDate, 'dd /MM/YYYY HH:mm')],

                            margin: [0, 10, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Prestador de Servicios: ', bold: true }, this.ipsList.find(x => x.id == this.clinicHistory.ipsId)?.name],
                            margin: [0, 10, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Grupo de Servicios: ', bold: true }, this.serviceGroupsList.find(x => x.code == this.clinicHistory.serviceGroups)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Modalidad de la Tecnologia de Salud: ', bold: true }, this.healthTecnologyModalityList.find(x => x.code == this.clinicHistory.healthTechnologyModality)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Lugar de Atención: ', bold: true }, this.placeOfAttentionList.find(x => x.code == this.clinicHistory.placeOfAttention)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Ruta de Entrada: ', bold: true }, this.routeOfEntryList.find(x => x.code == this.clinicHistory.routeOfEntry)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Causa de la Atención: ', bold: true }, this.consultationCauseList.find(x => x.code == this.clinicHistory.consultationCause)?.name],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Diagnostico Principal: ', bold: true }, this.diseaseList.find(x => x.code == this.clinicHistory.medicDiagnostic?.diseaseCode)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Enfermedad Huerfana: ', bold: true }, this.orphanDiseaseList.find(x => x.code == this.clinicHistory.medicDiagnostic?.orphanDisease)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                  columns: [
                      {
                          fontSize: 10,
                          //text: [{ text: 'Otro procedimiento: ', bold: true }, this.diseaseList.find(x => x.code == this.clinicHistory.medicDiagnostic?.diseaseCode)?.name],
                          text: [{ text: 'Otro Procedimiento: ', bold: true }, this.clinicHistory.procedureDescription],
                          margin: [0, 5, 0, 0],
                          width: '50%'
                      },
                  ]
              },
                this.getHealthTecnologiesForReport(),
                this.getHealthTecnologiesResultForReport(),
                this.getGeneralDiagnosticReport(),
                this.getMedicalFormulaForReport(),
                this.getObservationsForReport()
            ],
            styles: {
                tableHeader: {
                    bold: true,
                }
            }
        }
    }

    getGeneralDiagnosticReport() {
        if (this.clinicHistory.healthTechnologies != null) {
            return [
                {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 10],
                    alignment: 'center',
                    pageBreak: "before",
                    text: 'Diagnostico General',
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Antecedentes Patologicos: ', bold: true }, this.clinicHistory.pathologicalRecord],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Motivo de Consulta: ', bold: true }, this.clinicHistory.consultationMotive],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Anamnesis: ', bold: true }, this.clinicHistory.anamnesis],
                            margin: [0, 10, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Impresión Diagnostica: ', bold: true }, this.clinicHistory.diagnosticImpression],
                            margin: [0, 10, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Contol de Laboratorio: ', bold: true }, this.clinicHistory.laboratoryControl],
                            margin: [0, 10, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Plan de Tratamiento: ', bold: true }, this.clinicHistory.treatmentPlan],
                            margin: [0, 10, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 0],
                    text: 'Examen Fisico',
                },
                {
                    canvas: [
                        { type: 'line', x1: 0, y1: 0, x2: 595 - 300, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Presion Arterial: ', bold: true }, this.clinicHistory.arterialPressure],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Temperatura: ', bold: true }, this.clinicHistory.temperature],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Pulso: ', bold: true }, this.clinicHistory.pulse],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Peso: ', bold: true }, this.clinicHistory.weight],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Talla: ', bold: true }, this.clinicHistory.size],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Respiración: ', bold: true }, this.clinicHistory.breathing],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 0],
                    text: 'Organos',
                },
                {
                    canvas: [
                        { type: 'line', x1: 0, y1: 0, x2: 595 - 300, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Cabeza: ', bold: true }, this.clinicHistory.head],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Cuello: ', bold: true }, this.clinicHistory.neck],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Torax: ', bold: true }, this.clinicHistory.chest],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Abdomen: ', bold: true }, this.clinicHistory.belly],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Pelvis: ', bold: true }, this.clinicHistory.pelvis],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Extremidades: ', bold: true }, this.clinicHistory.extremities],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Piel: ', bold: true }, this.clinicHistory.skin],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Sistema Nervioso Central: ', bold: true }, this.clinicHistory.centralNervousSystem],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
            ]
        }
        else return "";
    }

    getHealthTecnologiesForReport() {
        if (this.clinicHistory.healthTechnologies != null) {
            return [
                {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 0],
                    text: 'Tecnologias de la Salud',
                },
                {
                    canvas: [
                        { type: 'line', x1: 0, y1: 0, x2: 595 - 300, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Tecnologia de la Salud: ', bold: true }, this.healthTechnologyList.find(x => x.code == this.clinicHistory.healthTechnologies.healthTechnology)?.name],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Finalidad de la Tecnologia de la Salud: ', bold: true }, this.healthTechnologyFinalityList.find(x => x.code == this.clinicHistory.healthTechnologies.healthTecnologyFinality)?.name],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Fecha de Prescripción: ', bold: true }, this.datePipe.transform(this.clinicHistory.healthTechnologies.prescriptionDate, 'dd /MM/YYYY HH:mm')],
                            margin: [0, 10, 0, 0],
                            width: '100%'
                        }
                    ]
                },
            ]
        }
        else return "";
    }

    getHealthTecnologiesResultForReport() {
        if (this.clinicHistory.healthTechnologiesResult != null) {
            return [
                {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 0],
                    text: 'Resultado Tecnologias de la Salud',
                },
                {
                    canvas: [
                        { type: 'line', x1: 0, y1: 0, x2: 595 - 300, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Diagnostico Egreso: ', bold: true }, this.diseaseListExit.find(x => x.code == this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic?.diseaseCode)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Enfermedad Huerfana: ', bold: true }, this.orphanDiseaseExitList.find(x => x.code == this.clinicHistory.healthTechnologiesResult.principalMedicDiagnostic?.orphanDisease)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Diagnostico Relacionado: ', bold: true }, this.diseaseListRelated.find(x => x.code == this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic?.diseaseCode)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Enfermedad Huerfana: ', bold: true }, this.orphanDiseaseRelatedList.find(x => x.code == this.clinicHistory.healthTechnologiesResult.relatedMedicDiagnostic?.orphanDisease)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Diagnostico Complicación: ', bold: true }, this.diseaseListComplication.find(x => x.code == this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic?.diseaseCode)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Enfermedad Huerfana: ', bold: true }, this.orphanDiseaseComplicationList.find(x => x.code == this.clinicHistory.healthTechnologiesResult.complicationMedicDiagnostic?.orphanDisease)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Condicion de Egreso: ', bold: true }, this.patientConditionList.find(x => x.code == this.clinicHistory.healthTechnologiesResult.patientCondition)?.name],
                            margin: [0, 5, 0, 0],
                            width: '100%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Diagnostico Muerte: ', bold: true }, this.diseaseListDeath.find(x => x.code == this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic?.diseaseCode)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Enfermedad Huerfana: ', bold: true }, this.orphanDiseaseDeathList.find(x => x.code == this.clinicHistory.healthTechnologiesResult.deathMedicDiagnostic?.orphanDisease)?.name],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Fecha de Egreso: ', bold: true }, this.datePipe.transform(this.clinicHistory.healthTechnologiesResult.medicalAttentionFinalizationDate, 'dd /MM/YYYY HH:mm')],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Tipo de Incapacidad: ', bold: true }, ""],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Dias de Incapacidad: ', bold: true }, this.clinicHistory.healthTechnologiesResult.medicIncapacityDays.toString()],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Dias de LMA: ', bold: true }, this.clinicHistory.healthTechnologiesResult.medicMaternityLicense.toString()],
                            margin: [0, 5, 0, 0],
                            width: '50%'
                        }
                    ]
                },
            ]
        }
        else return "";
    }

    getMedicalFormulaForReport() {
        if (this.clinicHistory.medicalFormula.length > 0) {
            /*this.clinicHistory.medicalFormula.forEach(x => {
                x.doseDescription = x.dose.toString() + " " + x.doseType;
                x.doseFrecuencyQuantityDescription = x.doseFrecuencyQuantity.toString() + " " + this.healthTechnologyTimeUnitList.find(h => h.code == x.doseFrecuency).name;
                x.doseDurationDescription = x.doseDurationQuantity.toString() + " " + this.healthTechnologyTimeUnitList.find(h => h.code == x.doseDuration).name;
            });*/
            return [
                {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 4],
                    alignment: 'center',
                    pageBreak: "before",
                    text: 'FÓRMULA MÉDICA',
                },
                //ric
                {
                    columns: [
                        {
                            fontSize: 12,
                            text: [{ text: 'Fecha: ', bold: true }, this.datePipe.transform(this.clinicHistory.consultationDate, 'dd /MM/YYYY')],
                            margin: [0, 4, 0, 10],
                            alignment: 'center',
                            width: '100%'
                        },
                    ]
                },
                //
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Información del Paciente', bold: true }, ""],
                            margin: [0, 5, 0, 0],
                            width: '55%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Profesional de la Salud', bold: true }, ""],
                            margin: [0, 5, 0, 0],
                            width: '45%'
                        }
                    ]
                },
                {
                    canvas: [
                        { type: 'line', x1: 10, y1: 0, x2: 595 - 10, y2: 0, lineWidth: 0.5 }
                    ]

                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Nombre: ', bold: true }, this.patient.firstName + " " + this.patient.secondName + " " + this.patient.firtsSurName + " " + this.patient.secondSurName],
                            margin: [0, 5, 0, 0],
                            width: '55%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Nombre: ', bold: true }, this.profesionals.find(x=> x.id == this.profesionalSelected)?.firstName + " " + this.profesionals.find(x=> x.id == this.profesionalSelected)?.secondName + " " + this.profesionals.find(x=> x.id == this.profesionalSelected)?.firtsSurName + " " + this.profesionals.find(x=> x.id == this.profesionalSelected)?.secondSurName],
                            margin: [0, 5, 0, 0],
                            width: '45%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Tipo de Documento: ', bold: true }, this.idTypeList.find(x => x.code == this.patient.idType).name],
                            margin: [0, 5, 0, 0],
                            width: '55%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Numero de Registro: ', bold: true }, this.profesionals.find(x=> x.id == this.profesionalSelected)?.registerNumber],
                            margin: [0, 5, 0, 0],
                            width: '45%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'No.: ', bold: true }, this.patient.personId],
                            margin: [0, 5, 0, 0],
                            width: '55%'
                        },
                        {
                            fontSize: 10,
                            text: [{ text: 'Rethus: ', bold: true }, this.profesionals.find(x=> x.id == this.profesionalSelected)?.rethus],
                            margin: [0, 5, 0, 0],
                            width: '45%'
                        }
                    ]
                },
                {
                    columns: [
                        {
                            fontSize: 10,
                            text: [{ text: 'Fecha de Nacimiento: ', bold: true }, this.datePipe.transform(this.patient.birthDate, 'dd /MM/YYYY')],
                            margin: [0, 5, 0, 10],
                            width: '70%'
                        }
                    ]
                },
                {
                    table: {
                        widths: ['20%', '15%', '20%', '45%'],
                        body: [
                            [{
                                text: 'Medicamento',
                                style: 'tableHeader'
                            },
                            {
                                text: 'Presentación',
                                style: 'tableHeader'
                            },
                            {
                                text: 'Concentración',
                                style: 'tableHeader'
                            },
                            {
                                text: 'Descripción',
                                style: 'tableHeader'
                            }
                            ],
                            ...this.clinicHistory.medicalFormula.map(ed => {
                            return [ed.medicationName, ed.presentation, ed.concentration, ed.doseDescription + " " + ed.doseFrecuency  + ", " + ed.indications+". Total:" + ed.doseDuration];

                              //  return [ed.medicationName, ed.presentation, ed.concentration, ed.doseDescription + " " + ed.doseFrecuency + " " + ed.doseDuration + ", " + ed.indications];
                            })
                        ]
                    }
                }
            ]
        }
        else return "";
    }

    getObservationsForReport() {
        if (this.clinicHistory.clinicHistoryObservations.length > 0) {
            return [
                {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 10],
                    alignment: 'center',
                    pageBreak: "before",
                    text: 'Observaciones',
                },
                {
                    table: {
                        widths: ['75%', '25%'],
                        body: [
                            [{
                                text: 'Observación',
                                style: 'tableHeader'
                            },
                            {
                                text: 'Fecha',
                                style: 'tableHeader'
                            },
                            ],
                            ...this.clinicHistory.clinicHistoryObservations.map(ed => {
                                return [ed.observation, this.datePipe.transform(ed.creationDate, 'dd/MM/YYYY HH:mm')];
                            })
                        ]
                    }
                }
            ]
        }
        else return "";
    }

    ngOnDestroy(): void {
        this.busy?.unsubscribe();
    }

    formValid(): boolean {
        switch (this.stepper.selectedIndex) {
            case 0:
                return this.firstFormGroup.valid;
            case 1:
                return this.secondFormGroup.valid;
            case 2:
                return this.thirdFormGroup.valid;
            case 3:
                return this.fourFormGroup.valid;
            case 4:
                return this.fifthFormGroup.valid;
            case 5:
                return this.sixFormGroup.valid;
            case 6:
                return true;
            case 7:
                return true;
        }

    }

    next(next: boolean): void {
        if (this.showOnlyObservations) {
                  //Ric
                  this.updateClinicHistory(next,"7");
                  //end Ric
          // this.updateClinicHistory(next, this.stepper.selectedIndex.toString().trim());
        }
        else {
            switch (this.stepper.selectedIndex) {
                case 0:
                    this.createOrUpdatePerson(next);
                    break;
                case 1:
                    this.createOrUpdatePatientInformation(next);
                    break;
                case 2:
                    //Ric
                    this.updateClinicHistory(next,"2");
                    //end Ric
                   // this.updateClinicHistory(next,this.stepper.selectedIndex.toString().trim());
                    break;
                case 3:
                    this.updateHealthTechnologies(next);
                    break;
                case 4:
                    this.updateHealthTechnologiesResult(next);
                    break;
                case 5:
                                        //Ric
                    this.updateClinicHistory(next,"5");
                    //end Ric

                    //this.updateClinicHistory(next,this.stepper.selectedIndex.toString().trim());
                    break;
                case 6:
                                        //Ric
                    this.updateClinicHistory(next,"6");
                    //end Ric

                  //  this.updateClinicHistory(next,this.stepper.selectedIndex.toString().trim());
                    break;
                case 7:
                  //Ric
                     this.updateClinicHistory(next,"7");
                   //end Ric
                   // this.updateClinicHistory(next,this.stepper.selectedIndex.toString().trim());
                    break;
            }
        }
        this.panelOpenState = false;
    }

    previous(): void {
        this.stepper.previous();
        this.panelOpenState = false;
    }

    convertServiceType (service:string):number{

      let result=0;
      service= service.trim();
      let first=service[0];
      if(first=='0')
      {
        result=parseInt(service[1]);
      }
      else
      {
        result=parseInt(service);
      }

      return result;
    }

    verifyServiceNumber(data:string[]):boolean
    {
       console.log(`this.clinicHistory.serviceType es ${this.clinicHistory.serviceType}`)
        let result:boolean=false;
          if (data.includes(this.clinicHistory.serviceType)) result=true;

        return result;
    }

    processSell(){
      console.log('entra a (modalSave) del html ProcessSell');
    }

     openSellModalFromClinicHistory():void
     {

      console.log('En openModalFromCh, this.clinicHistory es:', this.clinicHistory);
      let ch= this.clinicHistory;
      let bsell:BOSell= new BOSell();
      bsell.sellDetails=[];

      let nameTemp=this.getNameFromClinichistory();
      bsell.userId=parseInt(ch.patient.personId);
      bsell.userName=nameTemp;
      bsell.userEmail=ch.patient.email;
      bsell.dateSell=ch.consultationDate;
      bsell.clinicHistoryId=ch.id;
      bsell.total=ch.servicePrice;

      let bsellDetail:BOSellDetail=new BOSellDetail();
      let profesionalTemp=this.profesionals.find(x=>x.id==ch.profesionalId);
      bsellDetail.profesionalId=ch.profesionalId;
      bsellDetail.serviceTypeId=parseInt(ch.serviceType);
      bsellDetail.price= ch.servicePrice;
      bsellDetail.sellDetailIdTemp=0;

      console.log('bsellDetail es:', bsellDetail);
      bsell.sellDetails.push(bsellDetail)

      console.log('bsell.sellDetails es:', bsell.sellDetails)
      console.log('sellDetails[0] tiene es:', bsell.sellDetails[0]);
      console.log('bsell.sellDetails es:', bsell.sellDetails);
      console.log('bsell es:', bsell);
      this.sellModal.openSellModal(bsell, 1);

    }

    getNameFromClinichistory():string
    {
     let result='';
      let sname=this.clinicHistory.patient.secondName;
      let sn='';
      sname.length>0?sn=sname:sn='';
      let name=this.clinicHistory.patient.firstName +' '+sn;

      let ssname= this.clinicHistory.patient.secondSurName;
      let secondsn='';
      ssname.length>0?secondsn=ssname: secondsn='';
      let lastname=this.clinicHistory.patient.firtsSurName +' '+secondsn;
      result= name+' '+lastname;


      return result;
    }

    openSellTrigger():void{
      let profesional= this.clinicHistory.profesionalId;

    }



}
