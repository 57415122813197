import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOState } from "../models/BOState.model";

@Injectable()
export class StateService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/State';

    constructor(private http:HttpClient){}

    getStatesbyCountry(countryId:number):Observable<BOState[]>{
        return this.http.get<BOState[]>(this.myAppUrl + this.myApiUrl + '/' + countryId);
    }
}