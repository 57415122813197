import { Component, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { BOItem } from "../../models/BOItem.model";
import { BOMedicalFormula } from "../../models/BOMedicalFormula.model";

@Component({
    selector: 'createOrEditMedicalFormulaModal',
    templateUrl: './createOrEditMedicalFormula.component.html'
})

export class CreateOrEditMedicalFormulaComponent implements OnDestroy {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    busy: Subscription | undefined;
    submitted = false;
    newMedicalFormula = false;

    createOrEditMedicalFormulaForm = this._formBuilder.group({
        name: ['', Validators.required],
        presentation:['', Validators.required],
        concentration:['', Validators.required],
        doseDescription:[null, Validators.required],
        doseFrecuency: [null, Validators.required],
        doseDuration: [null, Validators.required],
        indications: [null, Validators.required]
    });

    healthTechnologyTimeUnitList: BOItem[] = [];
    medicalFormula: BOMedicalFormula = new BOMedicalFormula();

    constructor(public toastr: ToastrService, private _formBuilder: FormBuilder) { }

    openModal(healthTechnologyTimeUnitList:BOItem[], medicalFormula:BOMedicalFormula, isNew:boolean): void {
        this.healthTechnologyTimeUnitList = healthTechnologyTimeUnitList;
        this.medicalFormula = medicalFormula;
        this.newMedicalFormula = isNew;
        if(!this.newMedicalFormula)
        {
            this.createOrEditMedicalFormulaForm.patchValue({
                name: this.medicalFormula.medicationName,
                presentation: this.medicalFormula.presentation,
                concentration: this.medicalFormula.concentration,
                dose: this.medicalFormula.dose,
                doseType: this.medicalFormula.doseType,
                doseFrecuencyQuantity: this.medicalFormula.doseFrecuencyQuantity,
                doseFrecuency: this.medicalFormula.doseFrecuency,
                doseDurationQuantity: this.medicalFormula.doseDurationQuantity,
                doseDuration: this.medicalFormula.doseDuration,
                indications: this.medicalFormula.indications
            });
        }
        this.modal.config = { backdrop: "static", keyboard: false };
        this.modal.show();
    }

    saveMedicalFormula(createNew:boolean): void {
        const medicalFormulaInput: BOMedicalFormula = new BOMedicalFormula();
        medicalFormulaInput.id = this.medicalFormula.id;
        medicalFormulaInput.medicationName = this.createOrEditMedicalFormulaForm.get('name')!.value;
        medicalFormulaInput.presentation = this.createOrEditMedicalFormulaForm.get('presentation')!.value;
        medicalFormulaInput.concentration = this.createOrEditMedicalFormulaForm.get('concentration')!.value;
        medicalFormulaInput.dose = 0;
        medicalFormulaInput.doseType = "";
        medicalFormulaInput.doseFrecuencyQuantity = 0;
        medicalFormulaInput.doseFrecuency = this.createOrEditMedicalFormulaForm.get('doseFrecuency')!.value;
        medicalFormulaInput.doseDurationQuantity = 0;
        medicalFormulaInput.doseDuration = this.createOrEditMedicalFormulaForm.get('doseDuration')!.value;
        medicalFormulaInput.doseDescription = this.createOrEditMedicalFormulaForm.get('doseDescription')!.value;
        medicalFormulaInput.indications = this.createOrEditMedicalFormulaForm.get('indications')!.value;
        this.medicalFormula = medicalFormulaInput;
        if (this.newMedicalFormula) {
            this.modalSave.emit(this.medicalFormula);
            if(createNew) {this.createOrEditMedicalFormulaForm.reset();this.medicalFormula = new BOMedicalFormula();this.medicalFormula.id = 0}
            else this.close();
        }
        else {
            this.modalSave.emit(null);
            if(createNew) {this.createOrEditMedicalFormulaForm.reset();this.medicalFormula = new BOMedicalFormula();this.medicalFormula.id = 0}
            else this.close();
        }
    }

    close(): void {
        this.createOrEditMedicalFormulaForm.reset();
        this.modal.hide();
    }

    ngOnDestroy(): void {
        this.busy?.unsubscribe();
    }
}
