import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOItem } from "../models/BOItem.model";


@Injectable({
  providedIn: 'root'
})
export class ServiceTypeService {

  myAppUrl = GlobalConstants.apiURL;
  myApiUrl = 'api/ServiceType';

  constructor(private http:HttpClient){}

  getAll():Observable<BOItem[]>{
      return this.http.get<BOItem[]>(this.myAppUrl + this.myApiUrl);
  }

  getServicesByGroupService(service:string ):Observable<BOItem[]>{
    return this.http.get<BOItem[]>(this.myAppUrl + this.myApiUrl + '/'+service);
}


}
