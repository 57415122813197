import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { GlobalConstants } from "../common/global-constants";
import { BOClinicHistory } from "../models/BOClinicHistory.model";
import { BOFilter } from "../models/BOFilter";
import { BODateRangeFilter } from "../models/BODateRangeFilter.model";
import { IDataClinicHistoryAgrouped } from "../interfaces/Igeneral.interface";

@Injectable()
export class ClinicHistoryService{
    myAppUrl = GlobalConstants.apiURL;
    myApiUrl = 'api/ClinicHistory/';

    constructor(private http:HttpClient){}

    createClinicHistory(patientId:number):Observable<number>{
          return this.http.get<number>(this.myAppUrl + this.myApiUrl + 'createClinicHistory/' + patientId);
    }

    updateClinicHistory(clinicHistory:BOClinicHistory, step:string):Observable<number>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(clinicHistory);
        //Ric
        return this.http.post<number>(this.myAppUrl + this.myApiUrl + 'updateClinicHistory/'+step,body,httpOptions);
        //end Ric
        //  return this.http.post<number>(this.myAppUrl + this.myApiUrl + 'updateClinicHistory',body,httpOptions);

    }

    //Ric

    updateClinicHistoryHealthTechnologies(clinicHistory:BOClinicHistory):Observable<number>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(clinicHistory);
        return this.http.post<number>(this.myAppUrl + this.myApiUrl + 'updateClinicHistoryHealthTechnologies',body,httpOptions);
    }

    updateClinicHistoryHealthTechnologiesResult(clinicHistory:BOClinicHistory):Observable<number>{
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
          }
          const body=JSON.stringify(clinicHistory);
        return this.http.post<number>(this.myAppUrl + this.myApiUrl + 'updateClinicHistoryHealthTechnologiesResult',body,httpOptions);
    }

    getClinicHistories(filter:BOFilter):Observable<BOClinicHistory[]>{
      const httpOptions = {
          headers: new HttpHeaders({'Content-Type': 'application/json'})
        }
        const body=JSON.stringify(filter);
      return this.http.post<BOClinicHistory[]>(this.myAppUrl + this.myApiUrl + 'getAll',body,httpOptions);
  }
    //Ric end

    getClinicHistoriesByPatient(patientId:number):Observable<BOClinicHistory[]>{
        return this.http.get<BOClinicHistory[]>(this.myAppUrl + this.myApiUrl + 'getAllByPatient/' + patientId);
    }

    getClinicHistory(clinicHistoryId:number):Observable<BOClinicHistory>{
        return this.http.get<BOClinicHistory>(this.myAppUrl + this.myApiUrl + 'getClinicHistory/' + clinicHistoryId);
    }

    //reports

  getClinicHistoriesAllToday():Observable<Array<Array<BOClinicHistory>>>{

    return this.http.get<Array<Array<BOClinicHistory>>>(this.myAppUrl + this.myApiUrl + 'getClinicHistoriesAllToday/', {responseType: 'json'});

  }

  getClinicHistoriesByProfesionaToday(profesionalId:number):Observable<Array<Array<IDataClinicHistoryAgrouped>>>{

    return this.http.get<Array<Array<IDataClinicHistoryAgrouped>>>(this.myAppUrl + this.myApiUrl + 'getClinicHistoriesByProfesionalToday/' + profesionalId, {responseType: 'json'});
  }


  getClinicalHistoriesByAllAndDates(datesFilter:BODateRangeFilter):Observable<Array<Array<BOClinicHistory>>>{
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
    const body= JSON.stringify(datesFilter);
    console.log('En el service  entra al post, body es:', body);

    return this.http.post<Array<Array<BOClinicHistory>>>(this.myAppUrl + this.myApiUrl + 'getClinicHistoriesByAllAndDates',body,httpOptions);
  }


  getClinicHistoriesByProfessionalAndDates(datesFilter:BODateRangeFilter, professionalId:number):Observable<Array<Array<IDataClinicHistoryAgrouped>>>{
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
    const body= JSON.stringify(datesFilter);
    console.log('En el service  entra al post, body es:', body);

    return this.http.post<Array<Array<IDataClinicHistoryAgrouped>>>(this.myAppUrl + this.myApiUrl + 'getClinicHistoriesByProfessionalAndDates/'+professionalId,body,httpOptions);
  }

  getClinicHistoriesByServicesAndDates(datesFilter:BODateRangeFilter):Observable<Array<Array<BOClinicHistory>>>{
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
    const body= JSON.stringify(datesFilter);
    console.log('En el service  entra al post, body es:', body);

    return this.http.post<Array<Array<BOClinicHistory>>>(this.myAppUrl + this.myApiUrl + 'getClinicHistoriesByServicesAndDates',body,httpOptions);
  }

  getClinicHistoriesByServicesToday():Observable<Array<Array<BOClinicHistory>>>{

    return this.http.post<Array<Array<BOClinicHistory>>>(this.myAppUrl + this.myApiUrl + 'getClinicHistoriesByServicesToday',{responseType: 'json'});
  }

}
