import { BOGeneralValues } from "./BOGeneralValues.model";
import { BOPayTypeSellDetail } from "./BOPayTypeSellDetail.model";
import { BOSubServiceDetail } from "./BOSubServiceDetail.model";

export class BOSellDetail extends BOGeneralValues{
  id?:number;
  sellId?:number;
  profesionalId?:number;
  serviceTypeId?:number;
  amount?:number;
  price?:number;
  sellDetailIdTemp?:number;
  subServicesDetails?:BOSubServiceDetail[]=[];
  //subServicesDetails?:BOSubServiceDetail[]=[];

}
